import React, { Fragment, useState, useContext, useEffect } from 'react'
import Context from '../../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/loginSelectorStyle.js'
import { Link } from 'react-router-dom'
import { Box, Button, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import RenderModalLogIn from './Login'
import RenderModalSignUp from './Register'

import { useMediaQuery } from 'react-responsive'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'

import AuthCheck from 'utils/authCheck'

import { postRegister, postLogin, setLogout } from 'provider/accessProvider/provider'
import { getUserInfo } from 'provider/privateProvider/provider'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import moment from "moment"

const MySwal = withReactContent(Swal)

const useStyles = makeStyles(styles)

export default function LoginSelector(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [, /* failed */ setFailed] = useState(false)
  const [logIn, setLogIn] = useState(false)
  const [signUp, setSignUp] = useState(false)
  const [, /* correctSignUp */ setCorrectSignUp] = useState(false)
  const [, /* person */ setPerson] = useState(false)

  const contextType = useContext(Context)
  const { t, setLogStatus, setUserData, getUserData, getLogStatus, getCountry } = contextType

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [signupErrors, setSignupErrors] = useState({})
  const [loginIsSubmitting, setLoginIsSubmitting] = useState(false)
  const [signupIsSubmitting, setSignupIsSubmitting] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [isLogged, setIsLogged] = useState(false)
  const [userInformation, setUserInformation] = useState({})

  // Handle Modals
  const handleLogIn = () => (event) => {
    setLogIn(!logIn)
  }

  const handleSignUp = () => (event) => {
    setSignUp(!signUp)
  }

  const handleMenu = () => (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => (event) => {
    setAnchorEl(null)
  }

  const handleLogout = () => (event) => {
    setLogout().then((res) => {
      setLogStatus(false)
      setAnchorEl(null)
      setLogIn(false)
    })
  }

  const action = (key) => {
    return (
      <Fragment>
        <Button
          className={classes.dismissNotification}
          onClick={() => {
            closeSnackbar(key)
          }}
        >
          <CloseIcon fontSize='small' />
        </Button>
      </Fragment>
    )
  }

  const handleLoginSuccess = (message, variant) => {
    enqueueSnackbar(message, {
      action,
      autoHideDuration: 5000,
      variant: variant,
    })
  }

  const handleRegisterSuccess = (message, variant) => {
    enqueueSnackbar(message, {
      action,
      autoHideDuration: 30000,
      variant: variant,
    })
  }

  useEffect(() => {
    const login = () => {
      let data = {
        email: values.email,
        password: values.password,
      }
      postLogin(data)
        .then((res) => {
          localStorage.setItem('token', res.token)
          setLogStatus(true)
          handleLoginSuccess(t('login_success'), 'success')
        })
        .catch((error) => {
          setFailed(true)
          handleLoginSuccess(t('login_error'), 'error')
        })
    }
    const signUpAction = () => {
      let data = {
        email: values.email,
        plainPassword: values.password,
        firstName: values.name,
        lastName: values.lastName,
        phoneNumber: values.phone,
        countryCode: getCountry(),
        birthday: moment(values.birthday).format("YYYY-MM-DD")
      }
      postRegister(data)
        .then((res) => {
          setLogStatus(true)
          setCorrectSignUp(true)
          setSignUp(false)
          // handleRegisterSuccess('Registro Exitoso! Te hemos enviado un email con la información para completar tu registro. Por favor revisa tu buzón de entrada.', 'info')
          MySwal.fire(
            'Registro Exitoso!',
            'Te hemos enviado un email con la información para completar tu registro. Por favor revisa tu buzón de entrada.',
            'success'
          )
        })
        .catch((e) => {
          handleLoginSuccess('El correo electrónico ingresado ya se encuentra registrado.')
        })
    }
    if (Object.keys(errors).length === 0 && loginIsSubmitting) {
      login()
    }
    if (Object.keys(signupErrors).length === 0 && signupIsSubmitting) {
      signUpAction()
    }
    const getUser = () => {
      if (!(Object.keys(getUserData()).length === 0)) {
        setPerson(true)
      } else {
        AuthCheck()
          .then((response) => {
            getUserInfo().then((res) => {
              setUserData(res)
              setPerson(true)
            })
          })
          .catch((error) => {})
      }
    }
    getUser()
  }, [/* person,  */ errors, signupErrors])

  const checkUserInformation = () => {
    AuthCheck()
      .then((response) => {
        getUserInfo().then((res) => {
          setUserData(res)
          setPerson(true)
          setUserInformation(res)
        })
      })
      .catch((error) => {})
  }

  const checkLoginStatus = () => {
    AuthCheck()
      .then((res) => {
        setIsLogged(true)
        setLogStatus(true)
      })
      .catch((e) => {
        setIsLogged(false)
        setLogStatus(false)
      })
  }

  const handleButtonStyleMobile = () => {
    return !props.isEvent
      ? `${classes.buttonLoginMobile} ${classes.mr10p} ${classes.mb5p}`
      : `${classes.buttonLoginEventMobile} ${classes.mr10p} ${classes.mb15p}`
  }

  const handleButtonStyle = () => {
    return !props.isEvent ? `${classes.buttonLogin}` : `${classes.buttonLoginEventMobile} ${classes.mr10p} ${classes.mb15p}`
  }

  useEffect(() => {
    checkLoginStatus()
    checkUserInformation()
  }, [getLogStatus()])

  return isLogged ? (
    <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
      <Button className={isMobile ? `${classes.buttonLoginMobile} ${classes.mb5p}` : classes.buttonLogin} onClick={handleMenu()}>
        {Object.keys(userInformation).length === 0 ? (
          <CircularProgress classes={{ root: classes.customProgress }} />
        ) : (
          <Fragment>
            {t('welcome')}, {userInformation.firstName + ' ' + userInformation.lastName}
          </Fragment>
        )}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose()}
        classes={{ paper: classes.customMenu, list: classes.customMenuList }}
      >
        <MenuItem onClick={handleMenuClose()} classes={{ root: classes.customMenuItem }}>
          <Link to='/my-account/profile' className={classes.customLink}>
            <ListItemIcon classes={{ root: classes.customMenuIcon }}>
              <AccountCircleIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.customLisItemText }} primary='Mi Cuenta' />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogout()} classes={{ root: classes.customMenuItem }}>
          <ListItemIcon classes={{ root: classes.customMenuIcon }}>
            <ExitToAppIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.customLisItemText }} primary='Salir' />
        </MenuItem>
      </Menu>
    </Box>
  ) : props.onlyLogin ? (
    <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
      <Button classes={{ root: classes.buttonOnlyLogin }} className={isMobile ? classes.fs16 : classes.fs18} onClick={handleLogIn()}>
        {t('log_in')}
      </Button>
      <RenderModalLogIn
        logIn={logIn}
        setLogIn={setLogIn}
        setSignUp={setSignUp}
        setErrors={setErrors}
        setLoginIsSubmitting={setLoginIsSubmitting}
        setValues={setValues}
        values={values}
        signUp={signUp}
        handleLogIn={handleLogIn}
        errors={errors}
      />
    </Box>
  ) : (
    <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
      <Button className={isMobile ? handleButtonStyleMobile() : handleButtonStyle()} onClick={handleLogIn()}>
        {t('log_in')}
      </Button>
      <RenderModalLogIn
        logIn={logIn}
        setLogIn={setLogIn}
        setSignUp={setSignUp}
        setErrors={setErrors}
        setLoginIsSubmitting={setLoginIsSubmitting}
        setValues={setValues}
        values={values}
        signUp={signUp}
        handleLogIn={handleLogIn}
        errors={errors}
      />
      <Button className={isMobile ? handleButtonStyleMobile() : handleButtonStyle()} onClick={handleSignUp()}>
        {t('sign_up')}
      </Button>
      <RenderModalSignUp
        setSignupErrors={setSignupErrors}
        signUp={signUp}
        values={values}
        setSignupIsSubmitting={setSignupIsSubmitting}
        handleSignUp={handleSignUp}
        signupErrors={signupErrors}
        setValues={setValues}
        logIn={logIn}
        setLogIn={setLogIn}
        setSignUp={setSignUp}
      />
    </Box>
  )
}
