import React, { useContext } from 'react'
import styles from 'assets/jss/views/orderDetailPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const ErrorOrderState = (props) => {
  const classes = useStyles()

  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Grid container spacing={2} className={classes.fullHeight}>
      <Grid item xs={12} sm={12} className={classes.mb25}>
        <Typography align={'center'} variant='h3' className={classes.title}>
          {t('oops')}
        </Typography>
        <Typography align={'center'} className={classes.subTitle}>
          {t('error_payment_subitle')}
        </Typography>
        <Typography align={'center'} className={classes.body}>
          {t('error_payment_body')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ErrorOrderState
