import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Context from '../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, Grid } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import styles from 'assets/jss/components/eventStyle.js'
import Moment from 'react-moment'
import 'moment-timezone'
import '../../assets/scss/components/eventStyle.scss'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function Event(props) {
  const contextType = useContext(Context)
  const { getLanguage, t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })
  const { featuredEvent, normalEvent, event } = props
  const { id, slug, translations, calendars, startDate } = props.event

  const eventDate = calendars[0] ? calendars[0].startDate.replace(/-/g, "/") : startDate.replace(/-/g, "/")
  const minTicketPrice = translations[getLanguage()].minTicketPrice

  const formattedDate = (eventDate) => {
    const newFormatDate = eventDate.replace(/-/g, ' ')

    return newFormatDate
  }

  const dateMomentFormat = (eventDate, format) => {
    const momentFormat = <Moment format={format}>{formattedDate(eventDate)}</Moment>

    return momentFormat
  }

  const dateLocaleString = (eventDate, type) => {
    const date = new Date(Date.parse(formattedDate(eventDate)))
    const localeDate = date.toLocaleString(getLanguage().replace('_', '-'), {
      [type]: 'long',
    })

    return localeDate.charAt(0).toUpperCase() + localeDate.slice(1)
  }

  if (featuredEvent) {
    return (
      <Box className={isMobile ? 'itemEvent featuredEventMobile m-0' : 'itemEvent featuredEvent'}>
        <img alt='Event' src={translations[getLanguage()].imagePath} className={classes.normalEventImage} />
        <Box className='boxWrapper'>
        {/* <Link to={{pathname: `/event/${eventName.replace(/ /g, '-')}`, state:{slug: slug, id: id}}}> */}
        <Link to={{pathname: `/event/${slug}`, state:{slug: slug, id: id}}}>
            <Box className='boxInfo boxFeaturedInfo' />
            <Box className='boxOverlay'>
              <Button className={classes.buttonOverlay + ' ' + classes.fs12}>{t('reserve_your_transfer')}</Button>
            </Box>
            <Box className='boxInfoText'>
            { event.periodicityType === 1 
              ?
              <>
                <p className={classes.fs20 + ' ' + classes.m_0}>
                  {translations[getLanguage()] !== undefined
                          ? translations[getLanguage()].name
                          : null}
                </p>
                <p className={classes.fs16 + ' ' + classes.m_0}>
                  {dateMomentFormat(eventDate, 'DD')} {dateLocaleString(eventDate, 'month')} / {dateMomentFormat(eventDate, 'HH:mm')} {t('hours')}
                </p>
              </>
              :
              <>
                <p className={classes.fs20 + ' ' + classes.m_0}>
                  {translations[getLanguage()] !== undefined
                          ? translations[getLanguage()].name
                          : null}
                </p>
                <p className={classes.fs16 + ' ' + classes.m_0}>
                  {'Elige tu fecha'}
                </p>
              </>
              }
              <Box display='flex' justifyContent='center' className='boxButtonBuy'>
                <Button className={classes.buttonBuy + ' ' + classes.fs12}>
                  {t('buy_your_transfer')}
                  <span className={classes.spanPrice + ' ' + classes.fs16}>
                    <NumberFormat
                      value={translations[getLanguage()].minTicketPrice}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={process.env.REACT_APP_MONEY_SIGN}
                      renderText={(value) => (
                        <Box display='flex' justifyContent='center' alignItems='center' value={translations[getLanguage()].minTicketPrice} className={classes.fs14}>
                          {value}
                        </Box>
                      )}
                    />
                  </span>
                </Button>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    )
  } else if (normalEvent) {
    return (
      <Grid item xs={isMobile && 12}>
        <Box
          className={
            isMobile ? "w-100 itemEvent normalEvent" : "itemEvent normalEvent"
          }
          // style={{ backgroundImage: `url(${imagePath})` }}
        >
          <img
            alt="Normal Event"
            src={translations[getLanguage()].imagePath}
            className={classes.normalEventImage}
          />
          <Box className="boxWrapper">
            {/* <Link to={{pathname: `/event/${eventName.replace(/ /g, '-')}`, state:{slug: slug, id: id}}}> */}
            <Link
              to={{ pathname: `/event/${slug}`, state: { slug: slug, id: id } }}
            >
              <Box className="boxInfo boxNormalInfo" />
              <Box className="boxOverlay">
                <Button className={classes.buttonOverlay + " " + classes.fs12}>
                  {t("reserve_your_transfer")}
                </Button>
              </Box>
              <Box className="boxInfoText">
                {event.periodicityType === 1 ? (
                  <>
                    <p className={classes.fs14 + " " + classes.m_0}>
                      {dateLocaleString(eventDate, "weekday")}
                    </p>
                    <p className={classes.fs30 + " " + classes.m_0}>
                      {dateMomentFormat(eventDate, "DD")}{" "}
                      {dateLocaleString(eventDate, "month")}
                    </p>
                    <p className={classes.fs16 + " " + classes.m_0}>
                      {translations[getLanguage()].firstPlaceName} /{" "}
                      {dateMomentFormat(eventDate, "HH:mm")} {t("hours")}
                    </p>
                  </>
                ) : (
                  <p className={classes.fs30 + " " + classes.m_0}>
                    {"Elige tu fecha"}
                  </p>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  className="boxButtonBuy"
                >
                  <Button className={classes.buttonBuy + " " + classes.fs12}>
                    {t("transfer_from")}
                    <span className={classes.spanPrice + " " + classes.fs16}>
                      <NumberFormat
                        value={translations[getLanguage()].minTicketPrice}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}                        
                        prefix={process.env.REACT_APP_MONEY_SIGN}
                        renderText={(value) => (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.fs14}
                          >
                            {value}
                          </Box>
                        )}
                      />
                    </span>
                  </Button>
                </Box>
              </Box>
            </Link>
          </Box>
        </Box>
      </Grid>
    );
  }
}

Event.defaultProps = {
  normalEvent: false,
  featuredEvent: false,
  allianceEvent: false,
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  normalEvent: PropTypes.bool,
  featuredEvent: PropTypes.bool,
  allianceEvent: PropTypes.bool,
}
