import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/components/customCircularProgressStyle.js";
import { Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CustomCircularProgress(props) {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.boxProgress }}>
      <CircularProgress
        classes={{ root: classes.customProgress }}
        size={props.size}
      />
    </Box>
  );
}

CustomCircularProgress.defaultProps = {
  size: 40
};

CustomCircularProgress.propTypes = {
  size: PropTypes.number
};
