import {
  orangeColor,
  orangeColorRgba,
} from "assets/jss/style.js";

const contentBarStyle = {
  contentBar: {
    alignItems: "center",
    background: orangeColor,
    border: "0",
    borderRadius: "0",
    color: "white",
    display: "flex",
    flexFlow: "row nowrap",
    height: "80px",
    justifyContent: "flex-start",
    padding: "0",
    position: "relative",
    width: "100%",
    zIndex: "unset"
  },
  container: {
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  customToolbar: {
    padding: "0"
  },
  flex: {
    flex: 1
  },
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  appResponsive: {
    margin: "20px 10px"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF"
  },
  orange: {
    backgroundColor: orangeColor,
    color: "#FFFFFF",
  },
  orangeRgba: {
    backgroundColor: orangeColorRgba,
    color: "#FFFFFF",
  },
  brandComponent: {
    maxWidth: "266px",
    position: "relative",
    width: "100%"
  }
};

export default contentBarStyle;
