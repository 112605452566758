import { whiteColor, darkBlueColor } from "assets/jss/style.js";
import { latoBold } from "assets/jss/tools.js";

const userStyle = {
  boxUsers: {
    background: whiteColor,
    height: "406px",
    padding: "40px 0",
  },
  usersTitle: {
    color: darkBlueColor,
    fontFamily: latoBold,
    fontSize: "30px",
    textTransform: "uppercase",
  },
  boxContentSlider: {
    padding: "55px 0",
  },
  boxUserSlider: {
    alignItems: "center",
    display: "flex",
    height: "130px",
    justifyContent: "center",
    top: "15%",
    width: "100%",
  },
  boxSlider: {
    alignItems: "center",
    display: "flex !important",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  userImageSlider: {
    maxWidth: "100%",
    height: "55px",
  },
  customCardCarousel: {
    boxShadow: "none",
    display: "flex",
    height: "250px",
    justifyContent: "center",
    margin: "0",
  },
};

export default userStyle;
