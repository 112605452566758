import { orangeColor } from 'assets/jss/style.js'
import { latoRegular } from 'assets/jss/tools.js'

const counterInputStyle = {
  containerCounterInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityValue: {
    color: '#727C8E',
    fontSize: '15px',
    fontFamiliy: latoRegular,
  },
  buttonOperator: {
    background: '#CDCDCD',
    borderRadius: '50%',
    color: 'gray',
    padding: '0',
    height: '20px',
    minWidth: '0',
    width: '20px',
    '&:disabled': {
      color: 'white',
    },
  },
  iconOperator: {
    height: '17px',
    flexShrink: 'initial',
    width: '1em',
  },
  inputOperator: {
    height: '100%',
    width: '50.9531px',
  },
  customInput: {
    color: orangeColor,
    cursor: 'not-allowed',
    height: '34px',
    padding: '0',
    textAlign: 'center',
  },
  notchedOutline: {
    borderColor: orangeColor + '!important',
  },
}

export default counterInputStyle
