import React, { Fragment } from 'react'
import styles from 'assets/jss/views/faqPage.js'
import { makeStyles } from '@material-ui/core/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function MassiveEventsTab(props) {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid container className={classes.pt50}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Typography classes={{ root: classes.customLinkFaq }} className={classes.pb10} style={{marginLeft:-14}}>
                <ArrowRightIcon classes={{ root: classes.customArrow }} fontSize='large' />
                ¿Qué es VMove?
              </Typography>
              <Typography>
                Somos la primera ticketera de traslados entre tu evento y tu casa. Contamos con una plataforma web, donde encontrarás
                Eventos Privados, Eventos Masivos y traslados personalizados, ofreciéndote la mejor experiencia en cada viaje. Cuidamos cada
                detalle y pensamos en ti como nuestra prioridad, para que te sientas a gusto y seguro antes de tu evento y también después,
                una vez que hayas llegado a casa.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography classes={{ root: classes.customLinkFaq }} className={classes.pb10} style={{marginLeft:-14}}>
                <ArrowRightIcon classes={{ root: classes.customArrow }} fontSize='large' />
                ¿En qué consisten los traslados?
              </Typography>
              <Typography>
                Con Vmove Ticketera podrás contar con un transporte de traslado masivo y personal para tus eventos, conciertos, recitales,
                matrimonios, cumpleaños y mucho más. Nos ajustamos a tus necesidades en cada traslado de tu casa al evento y del evento a tu
                casa, siempre ofreciéndote el mejor servicio y experiencia. Siguiendo siempre estrictos protocolos de seguridad y
                seguimiento en línea con las rutas.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} className={classes.pt20} style={{padding: '30px 0px'}}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Typography classes={{ root: classes.customLinkFaq }} className={classes.pb10} style={{marginLeft:-14}}>
                <ArrowRightIcon classes={{ root: classes.customArrow }} fontSize='large' />
                ¿Por qué escoger VMove?
              </Typography>
              <Typography>
                Contamos con direfentes traslados, estos consisten en traslados específicos diseñados para categorías puntuales y
                personalizadas como Mega- Eventos que son traslados desde tu casa, luego al evento y del evento te llevamos nuevamente a
                casa. Eventos Privados- Personas: Como matrimonios, despedida de solteros, cumpleaños, comuniones, entre otros. Eventos
                privados Corporativos: El evento no está publicado en el home de la ticketera. Es un evento privado y sólo pueden ingresar
                quienes tienen acceso al link. Son eventos especialmente de personas - empresas.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography classes={{ root: classes.customLinkFaq }} className={classes.pb10} style={{marginLeft:-14}}>
                <ArrowRightIcon classes={{ root: classes.customArrow }} fontSize='large' />
                ¿Cómo creo un evento privado?
              </Typography>
              <Typography>
                Para crear un evento en nuestra página, sólo debes ingresar y registrarte con tus datos. Luego selecciona tu evento y escoge
                la categoría que sea más cómoda para ti. El día del evento, no olvides mostrar al conductor de la VAN el código QR, justo
                antes de que llegue la VAN a tu casa te llegará un mensaje al celular. Con Vmove siempre conocerás el valor de nuestros
                traslados. Somos el único servicio de transporte que te asegura un vehículo a la salida de tu evento.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
