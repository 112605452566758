import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Divider,
  createMuiTheme,
  CircularProgress,
  Container,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";
import Context from "../../../context/Context";
import orange from "@material-ui/core/colors/orange";
import { ThemeProvider } from "@material-ui/styles";

import styles from "../../../assets/jss/views/frequentFriendsPage";
import { useFormik, Field, FormikProvider, ErrorMessage } from "formik";

import {
  getFrequentFriends,
  postFrequentFriends,
} from "../../../provider/privateProvider/provider";
import { frequentFriendSchema } from "../../../utils/validations";

const useStyles = makeStyles(styles);

const FrequentFriends = (props) => {
  const contextType = useContext(Context);
  const { t } = contextType;

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getFrequentFriendsApi();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      phone: "",
      email: "",
    },
    validationSchema: frequentFriendSchema,
    onSubmit: (values) => {
      postFrequentFriends(values).then(() => {
        getFrequentFriendsApi();
      });
    },
  });

  const getFrequentFriendsApi = () => {
    getFrequentFriends()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("error_frequent_friends_get", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.contentWrapper} spacing={2}>
        <Grid item md={12} xs={12} className={classes.tar}>
          <span className={classes.title}>{t("frequent_friends")}</span>
        </Grid>
        <Grid container xs={12} md={12} className={classes.friendListBox}>
          {data.length > 0 ? (
            data.map(({ fullname, phone, email }) => (
              <>
                <Grid item xs={2} md={1}>
                  <GroupIcon className={classes.customIcon} />
                </Grid>
                <Grid item xs={10} md={4} className={classes.friendName}>
                  {fullname}
                </Grid>
                <Grid item xs={12} md={3}>
                  {phone}
                </Grid>
                <Grid item xs={12} md={4}>
                  {email}
                </Grid>
                <Grid item xs={12} md={12} className={classes.divider}>
                  <Divider variant="middle" />
                </Grid>
              </>
            ))
          ) : (
            <>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.unregisteredFriends}
              >
                <span>{t("you_dont_have_frequent_friends")}</span>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider variant="middle" />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid item md={12} xs={12} className={classes.friendFormBox}>
                <span className={classes.formTitle}>
                  {t("add_frequent_friend")}
                </span>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={5} xs={12}>
                  <Grid item md={12} xs={12}>
                    {t("name_and_surname")}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name="fullname"
                      placeholder="María ..."
                      onChange={formik.handleChange}
                      value={formik.values.fullname}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      helperText={<ErrorMessage name="fullname" />}
                    />
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Grid item md={12} xs={12}>
                    {t("phone")}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name="phone"
                      placeholder="+569..."
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      helperText={<ErrorMessage name="phone" />}
                    />
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Grid item md={12} xs={12}>
                    {t("email")}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      as={TextField}
                      name="email"
                      placeholder="correo@..."
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      variant="outlined"
                      fullWidth
                      className={classes.field}
                      helperText={<ErrorMessage name="email" />}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                className={classes.addFriendButtonGrid}
              >
                <Button className={classes.addFriendButton} type="submit">
                  {t("add_friend")}
                </Button>
              </Grid>
            </form>
          </FormikProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default FrequentFriends;
