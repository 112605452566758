import React from 'react'
import { createBrowserHistory } from 'history'
import { Redirect, Router, Switch } from 'react-router-dom'
import CustomProvider from '../src/context/State'
import { SnackbarProvider } from 'notistack'

import 'assets/scss/material-kit-react.scss?v=1.8.0'
import 'assets/scss/style.scss'

// Pages
import AboutUsPage from 'views/AboutUsPage/AboutUsPage.js'
import AccountPage from 'views/AccountPage/AccountPage.js'
import BoyfriendsPage from 'views/BoyfriendsPage/BoyfriendsPage.js'
import ContactPage from 'views/ContactPage/ContactPage.js'
import Detail from 'views/EventPage/Detail.js'
import FaqPage from 'views/FaqPage/FaqPage.js'
import LandingPage from 'views/LandingPage/LandingPage.js'
import MassiveEventsPage from 'views/MassiveEventsPage/MassiveEventsPage.js'
import PrivateEventsPage from 'views/PrivateEventsPage/PrivateEventsPage.js'
import VmoveCompaniesPage from 'views/VmoveCompaniesPage/VmoveCompaniesPage.js'
import AccountVerifyPage from 'views/AccountVerifyPage/AccountVerifyPage.js'
import ReservationPage from 'views/ReservationPage/ReservationPage.js'
import AlliancesPage from 'views/AlliancesPage/AlliancesPage.js'
import ResponsePaymentPage from 'views/ResponsePaymentPage/ResponsePaymentPage.js'
import SearcherPage from 'views/SearcherPage/SearcherPage.js'
import ErrorPage from 'views/ErrorPage/ErrorPage'
import RouteLayout from 'views/Layout/RouteLayout'
import OrderDetailPage from 'views/OrderDetailPage/OrderDetailPage'

//Header - Footer
import Header from './components/Header/Header.js'
import Footer from './components/Footer/Footer.js'
import ResetPassword from 'components/Header/InfoBar/ResetPassword'
import ReaderQR from 'views/ReaderQR/ReaderQR'

var hist = createBrowserHistory()

const IndexPage = () => {
  return (
    <CustomProvider>
      <SnackbarProvider>
        <Router history={hist}>
          <Header />
          <Switch>
            <RouteLayout exact path='/' component={LandingPage} />
            <RouteLayout exact path='/about-us' component={AboutUsPage} />
            <RouteLayout exact path='/wedding' component={BoyfriendsPage} />
            <RouteLayout exact path='/contact' component={ContactPage} />
            <RouteLayout exact path='/event/:name' component={Detail} />
            <RouteLayout exact path='/faq' component={FaqPage} />
            <RouteLayout exact path='/response-payment' component={ResponsePaymentPage} />
            <RouteLayout exact path='/massive-events' component={MassiveEventsPage} />
            <RouteLayout exact path='/my-account/:tab' component={AccountPage} />
            <RouteLayout exact path='/private-events' component={PrivateEventsPage} />
            <RouteLayout exact path='/verify-account/:token' component={AccountVerifyPage} />
            <RouteLayout exact path='/recovery-account/:token' component={ResetPassword} />
            <RouteLayout exact path='/vmove-companies' component={VmoveCompaniesPage} />
            <RouteLayout exact path='/how-book' component={ReservationPage} />
            <RouteLayout exact path='/alliances' component={AlliancesPage} />
            <RouteLayout exact path='/search' component={SearcherPage} />
            <RouteLayout exact path='/order-detail/:id' component={OrderDetailPage} />
            <RouteLayout exact path='/order-rejected' component={OrderDetailPage} />
            <RouteLayout exact path='/error404' component={ErrorPage} />
            <RouteLayout exact path='/qr-verification' component={ReaderQR} />
            <RouteLayout>
              <Redirect to='/error404' />
            </RouteLayout>
          </Switch>
          <Footer />
        </Router>
      </SnackbarProvider>
    </CustomProvider>
  )
}

export default IndexPage
