import React, { useContext, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/components/activePrivateEventsStyles.js";
import ActivePivateEventsList from "./ActivePrivateEventsList";
import PrivateEventsHistoryList from "./PrivateEventsHistoryList";
import UnderReviewPrivateEventsList from "./UnderReviewPrivateEventsList";
import InvitedPrivateEventList from "./InvitedPrivateEventList";
import Context from "../../context/Context";

const useStyles = makeStyles(styles);

const ActivePrivateEvents = (props) => {
  const [showHistoryList, setShowHistoryList] = useState(0);
  const { setActiveTab, setEventId, linkToEvent } = props;
  const classes = useStyles();
  const contextType = useContext(Context);
  const { t } = contextType;
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })


  const renderTab = () => {
    switch (showHistoryList) {
      case 0:
        return <ActivePivateEventsList />;
      case 1:
        return (
          <UnderReviewPrivateEventsList
            setEventId={setEventId}
            setActiveTab={setActiveTab}
            setShowHistoryList={setShowHistoryList}
          />
        );
      case 2:
        return <PrivateEventsHistoryList />;
      case 3:
        return <InvitedPrivateEventList linkToEvent={linkToEvent}/>;
      default:
        return <ActivePivateEventsList />;
    }
  };

  return (
    <div className={classes.contentWrapper}>
      <Grid
        container
        className={classes.contentTab}
        justify="center"
        style={{ textAlign: "center" }}
      >
        <Grid container md={12} xs={12}>
          <Grid item md={6} xs={12} className={isMobile ? classes.titlePositionButtonMobile : classes.titlePositionButton}>
            <Button
              className={isMobile ? classes.createEventButtonMobile : classes.createEventButton}
              onClick={() => setActiveTab(1)}
            >
              + {t('create_event')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12} className={isMobile ? classes.titlePositionMobile : classes.titlePosition} >
            <span className={classes.title}>{t('your_events')}</span>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12} className={isMobile ? classes.titleGridMobile : classes.titleGrid}>
          <span
            className={showHistoryList=== 3 ? classes.titleTabActive : classes.titleTab}
            onClick={() => setShowHistoryList(3)}
          >
            {t('invited_events')}
          </span>
        </Grid>
        <Grid item md={3} xs={12} className={isMobile ? classes.titleGridMobile : classes.titleGrid}>
          <span
            className={showHistoryList=== 0 ? classes.titleTabActive : classes.titleTab}
            onClick={() => setShowHistoryList(0)}
          >
            {t('active_events')}
          </span>
        </Grid>
        <Grid item md={3} xs={12} className={isMobile ? classes.titleGridMobile : classes.titleGrid}>
          <span
            className={showHistoryList=== 1 ? classes.titleTabActive : classes.titleTab}
            onClick={() => setShowHistoryList(1)}
          >
            {t('under_review_events')}
          </span>
        </Grid>
        <Grid item md={3} xs={12} className={isMobile ? classes.titleGridMobile : classes.titleGrid}>
          <span
            className={showHistoryList=== 2 ? classes.titleTabActive : classes.titleTab}
            onClick={() => setShowHistoryList(2)}
          >
            {t('finished_events')}
          </span>
        </Grid>
        {renderTab()}
      </Grid>
    </div>
  );
};

export default ActivePrivateEvents;
