import React, { useContext } from 'react'
import Context from '../../context/Context'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js'
import Button from 'components/CustomButtons/Button.js'
import styles from 'assets/jss/components/headerLinksStyle.js'

const useStyles = makeStyles(styles)

export default function HeaderLinks(props) {
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to='/about-us' className={classes.customLink}>
          <Button color='transparent' className={`${classes.navLink} ff-LatoBold`}>
            {t('about_us')}
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={t('events')}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          dropdownList={[
            <Link to='/massive-events' className={classes.dropdownLink}>
              {t('massive_events')}
            </Link>,
            <Link to='/private-events' className={classes.dropdownLink}>
              {t('private_events')}
            </Link>,
            <Link to='/wedding' className={classes.dropdownLink}>
              {t('boyfriends')}
            </Link>,
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link to="/routes" className={classes.customLink}>
          <Button color="transparent" className={classes.navLink}>
            {t("routes")}
          </Button>
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link to='/alliances' className={classes.customLink}>
          <Button color='transparent' className={`${classes.navLink} ff-LatoBold`}>
            {t('alliances')}
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to='/how-book' className={classes.customLink}>
          <Button color='transparent' className={`${classes.navLink} ff-LatoBold`}>
            ¿{t('how_to_book')}?
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to='/vmove-companies' className={classes.customLink}>
          <Button color='transparent' className={classes.navLink}>
            {t('vmove_b2b')}
          </Button>
        </Link>
      </ListItem>
    </List>
  )
}
