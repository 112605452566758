import React, { useState, useEffect } from 'react'
import styles from 'assets/jss/views/accountVerifyPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useParams } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import LoginSelector from '../../components/Header/InfoBar/LoginSelector'
import CustomCircularProgress from '../../components/CircularProgress/CustomCircularProgress'
import { postVerify } from 'provider/accessProvider/provider'

const useStyles = makeStyles(styles)

export default function AccountVerifyPage(props) {
  const [boolVerify, setBoolVerify] = useState(false)
  const [boolCorrectVerify, setBoolCorrectVerify] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const customJustify = isMobile ? 'center' : 'initial'
  const { token } = useParams()

  useEffect(() => {
    const verifyAccount = async () => {
      postVerify(token)
        .then((res) => {
          setBoolVerify(true)
          setBoolCorrectVerify(true)
        })
        .catch((e) => {
          setBoolVerify(true)
        })
    }
    verifyAccount()
  }, [])

  return (
    <Box className={isMobile ? classes.contentWrapperMobile : classes.contentWrapper}>
      <Container>
        <Box display='flex' alignItems='center' justifyContent={customJustify} classes={{ root: classes.boxHeaderEvents }}>
          <AccountCircleIcon fontSize={isMobile ? 'small' : 'large'} />
          <Typography classes={{ root: classes.titleEvent }} className={isMobile ? classes.fs16 : classes.fs30}>
            Verifica tu Cuenta
          </Typography>
        </Box>
        <Grid alignContent='center' alignItems='center' container className={classes.gridContainerList} justify='center' spacing={4}>
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            {boolVerify ? (
              boolCorrectVerify ? (
                <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center'>
                  <Box display='flex' classes={{ root: classes.boxSuccess }}>
                    <CheckIcon classes={{ root: classes.iconResponse }} fontSize={isMobile ? 'small' : 'large'} />
                    <Typography classes={{ root: classes.labelSuccess }} className={isMobile ? classes.fs16 : classes.fs25}>
                      Cuenta verificada correctamente
                    </Typography>
                  </Box>
                  <LoginSelector onlyLogin={true} />
                </Box>
              ) : (
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <CloseIcon classes={{ root: classes.iconResponse }} fontSize={isMobile ? 'small' : 'large'} />
                  <Typography classes={{ root: classes.labelError }} className={isMobile ? classes.fs16 : classes.fs25}>
                    Ocurrio un error al verificar la cuenta
                  </Typography>
                </Box>
              )
            ) : (
              <CustomCircularProgress />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
