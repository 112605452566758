import {
  SET_ADDRESS_INPUTS,
  SET_BUTTON_STEP_TICKET,
  SET_COMMUNE,
  SET_COUNTRIES,
  SET_COUNTRY,
  SET_LANGUAGE_CHANGED,
  SET_LOCALES,
  SET_LOG_STATUS,
  SET_PASSENGER_INPUTS,
  SET_PLACES,
  SET_QUANTITY,
  SET_ROUTES_PASSENGER_INPUTS,
  SET_SEARCH,
  SET_TRANSLATE,
  SET_USER_DATA,
  SET_CLEAR_CONTEXT,
  SET_DISCOUNT,
} from './Constants'

export default (state, action) => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      }
    case SET_TRANSLATE:
      return {
        ...state,
        translate: action.payload,
      }
    case SET_LANGUAGE_CHANGED:
      return {
        ...state,
        isChanged: action.payload,
      }
    case SET_PLACES:
      return {
        ...state,
        currentEvent: action.payload,
      }
    case SET_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      }
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      }
    case SET_COMMUNE:
      return {
        ...state,
        communeCode: action.payload,
      }
    case SET_BUTTON_STEP_TICKET:
      return {
        ...state,
        buttonStepTicket: action.payload,
      }
    case SET_ADDRESS_INPUTS:
      return {
        ...state,
        addressInputs: action.payload,
      }
    case SET_PASSENGER_INPUTS:
      return {
        ...state,
        passengerInputs: action.payload,
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }
    case SET_LOCALES:
      return {
        ...state,
        locales: action.payload,
      }
    case SET_ROUTES_PASSENGER_INPUTS:
      return {
        ...state,
        routesPassengerInputs: action.payload,
      }
    case SET_CLEAR_CONTEXT:
      return {
        ...state,
        addressInputs: {},
        communeCode: '',
        passengerInputs: {},
        routesPassengerInputs: {},
        quantity: {},
        buttonStepTicket: false,
      }
    case SET_LOG_STATUS:
      return {
        ...state,
        logStatus: action.payload,
      }
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
      case SET_DISCOUNT:
        return {
          ...state,
          discount: action.payload,
        }
    default:
      return state
  }
}
