import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Grid } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import styles from "assets/jss/components/counterInputStyle.js";

import Context from "context/Context";

const useStyles = makeStyles(styles);

const CounterButtons = (props) => {
  const [counter, setCounter] = useState(0);
  const {
    name,
    price,
    capacity,
    stops,
    productVariantId,
    tickets,
    updateTicket,
    subcategoryName,
    placeId,
    placeAddress,
    citySelected,
    oldCitySelected,
    approaches,
    stock,
  } = props;

  const contextType = useContext(Context);
  const { getDiscount } = contextType;

  const classes = useStyles();

  const initialTickets = {
    placeId,
    placeAddress,
    capacity,
    stops,
    productVariantId,
    price,
    name,
    subcategoryName,
    discountPrice: 0,
    approaches,
    passengers: [
      {
        name: "",
        email: "",
        phone: "",
        passengerAddress: "",
      },
    ],
    addresses: [
      {
        address: "",
        commune_id: "",
      },
    ],
  };

  const handleIncrement = () => {
    setCounter(counter + 1);
    tickets.push(initialTickets);
    updateTicket(tickets);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
    tickets.pop();
    updateTicket(tickets);
  };

  useEffect(() => {
    const calculateCounter = () => {
      const res = tickets.reduce((accumulator, currentValue) => {
        return currentValue.productVariantId === productVariantId
          ? accumulator + 1
          : accumulator;
      }, 0);
      setCounter(res);
    };
    calculateCounter();
  }, []);

  const resetValues = () => {
    tickets.splice(0, tickets.length);
    updateTicket(tickets);
  };

  useEffect(() => {
    const oldCity = Object.values(oldCitySelected);
    const newCity = Object.values(citySelected);
    if (oldCity.length !== 0 && oldCity !== newCity) {
      setCounter(0);
      resetValues();
    }
  }, [citySelected]);

  return (
    <Grid container spacing={2} className={classes.containerCounterInput}>
      <Grid item>
        <Button
          disabled={counter === 0 ? 1 : 0}
          onClick={() => handleDecrement()}
          classes={{ root: classes.buttonOperator }}
        >
          <RemoveIcon className={classes.iconOperator} />
        </Button>
      </Grid>
      <Grid item>
        <span className={classes.quantityValue}>{counter}</span>
      </Grid>
      <Grid item>
        <Button
          disabled={counter >= stock}
          onClick={() => handleIncrement()}
          classes={{ root: classes.buttonOperator }}
        >
          <AddIcon className={classes.iconOperator} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CounterButtons;
