import React from 'react'
import styles from 'assets/jss/views/eventPageSections/finishTabStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import CustomCircularProgress from 'components/CircularProgress/CustomCircularProgress'

const useStyles = makeStyles(styles)

const LoadingOrderState = (props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.fullHeight}>
      <CustomCircularProgress size={60} />
    </Grid>
  )
}

export default LoadingOrderState
