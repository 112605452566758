import React, { Fragment } from "react";
import Event from "./Event.js";
import Carousel from "react-slick";
import Card from "components/Card/Card.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/landingPageSections/eventsSectionStyle.js";
import "assets/scss/components/eventListStyle.scss";
import { useMediaQuery } from "react-responsive";
import CustomCircularProgress from "../../components/CircularProgress/CustomCircularProgress";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function EventList(props) {
  const classes = useStyles();
  const { eventList, isRecommended } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const carouselType = isMobile
    ? "recommended-events-caorusel-mobile"
    : "recommended-events-caorusel";

  const settingsRecommended = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1460,
        settings: {
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsMassiveEvent = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1460,
        settings: {
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderList = () => {
    return eventList.map((event, index) => {
      return (
        <div key={index}>
          <Event key={event.id} event={event} featuredEvent />
        </div>
      );
    });
  };

  if (eventList && eventList.length > 0) {
    return (
      <Fragment>
        <Card className={classes.customCardEvent} id={carouselType}>
          {isRecommended ? (
            <Carousel {...settingsRecommended}>{renderList()}</Carousel>
          ) : (
            <Carousel {...settingsMassiveEvent}>{renderList()}</Carousel>
          )}
        </Card>
      </Fragment>
    );
  } else {
    return (
      <Grid
        item
        xs={12}
        md={12}
        classes={{ root: classes.itemFullCenter }}
        className={classes.boxCircularProgress}
      >
        <Typography
          classes={{ root: classes.titleEvent }}
          className={isMobile ? classes.fs16 : classes.fs20}
        >
          {"Sin eventos para mostrar"}
        </Typography>
      </Grid>
    );
  }
}
