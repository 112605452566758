import { IconButton, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { purchaseStyles } from '../../assets/jss/components/purchaseHistoryList'
import { TicketState } from './PurchaseState'
import { useMediaQuery } from 'react-responsive'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import React, { useEffect, useState, useContext } from 'react'
import Context from 'context/Context'
import moment from 'moment';

const useStyles = makeStyles(purchaseStyles)

export const Purchase = (props) => {
  const [color, setColor] = useState({})
  const classes = useStyles(color)
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const contextType = useContext(Context)
  const { getLanguage } = contextType

  // const calendarStrings = {
  //   lastDay: '[Yesterday]',
  //   sameDay: 'hh:mm a',
  //   lastWeek: 'MMM DD',
  //   sameElse: 'DD/MM/YYYY',
  // }

  useEffect(() => {
    const getTicketColor = () => {
      switch (props.state) {
        case 'cancelled':
          setColor({ color: '#CDCDCD' })
          break
        default:
          setColor({ color: '#FF5E05' })
          break
      }
    }
    getTicketColor()
  }, [])

  const handleClick = () => {
    if (props.state !== 'cancelled') {
      props.openDetail(props.id)
    }
  }

  return (
    <ListItem classes={{ root: isMobile ? classes.listItemRootMobile : classes.listItemRoot }} target='_blank'>
      <ListItemText classes={{ primary: classes.textItemFormat }} primary={moment.utc(props.date).format('DD-MM-YYYY HH:mm')} />
      <ListItemText classes={{ primary: classes.textItemFormat }} primary={props.regularEvent[getLanguage()].name} />
      <ListItemText>
        <TicketState state={props.state} />
      </ListItemText>
      <ListItemText onClick={() => handleClick()}>
        <IconButton className={classes.iconButtonColor}>
          <ConfirmationNumberIcon />
        </IconButton>
      </ListItemText>
    </ListItem>
  )
}
