import React, { Fragment, useState, useEffect, useContext } from "react";
import Context from "../../context/Context";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Container,
  MenuItem,
  createMuiTheme,
} from "@material-ui/core";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import orange from "@material-ui/core/colors/orange";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/components/activePrivateEventsStyles.js";
import { createPrivateEventFormSchema } from "../../utils/validations.js";

import DateFnsUtils from "@date-io/date-fns";
import { Field, ErrorMessage, useFormik, FormikProvider } from "formik";
import { useSnackbar } from "notistack";

import es from "date-fns/locale/es";
import moment from "moment";
import { getCounties } from "provider/publicProvider/provider.js";

import {
  getPrivateEventById,
  putCreatePrivateEvent,
  postCreatePrivateEventImage,
  postCreatePrivateEventExcel,
} from "provider/privateProvider/provider";

const useStyles = makeStyles(styles);

const UpdatePrivateEventForm = (props) => {
  const contextType = useContext(Context);
  const { getCountry } = contextType;
  const { setActiveTab, eventId, setShowHistoryList } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [paradeArray, setParadeArray] = useState([]);
  const [inputArrayId, setInputArrayId] = useState(0);
  const [inputArrayValues, setInputArrayValues] = useState([]);
  const [counties, setCounties] = useState([{ name: "", id: "" }]);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState();
  const [eventImgName, setEventImgName] = useState("");
  const [eventGuestsName, setEventGuestsName] = useState("");
  const [inputArrayValuesError, setInputArrayValuesError] = useState(false);

  let [image, setImage] = useState();
  let [excel, setExcel] = useState();

  let eventImg = new FormData();
  let eventGuests = new FormData();
  const [eventOldData, setEventOldData] = useState({
    name: "",
    location: "",
    address: "",
    startDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    invitation: "",
    guest: false,
  });

  const handleImg = (event) => {
    setImage(event.target.files[0]);
    setEventImgName(event.target.files[0].name);
  };

  const handleExcel = (event) => {
    setExcel(event.target.files[0]);
    setEventGuestsName(event.target.files[0].name);
  };

  useEffect(() => {
    getCounties().then((response) => {
      setCounties(response);
      setCountry(getCountry());
    });
  }, []);

  useEffect(() => {
    getPrivateEventById(eventId)
      .then((data) => {
        setEventOldData({
          name: data.name,
          location: data.location,
          address: data.address,
          startDate: moment(data.startDate, "MM-DD-YYYY HH:mm").format("L"),
          startHour: moment(data.startDate, "MM-DD-YYYY HH:mm"),
          endDate: moment(data.endDate, "MM-DD-YYYY HH:mm"),
          invitation: data.invitation,
          imagePath: data.imagePath,
          guest: data.hasGuests,
          countyId: data.county.id,
        });
        addOldParade(data.additionalStops);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleNotification = (message, variant) => {
    enqueueSnackbar(message, {
      action,
      autoHideDuration: 5000,
      variant: variant,
    });
  };

  const formik = useFormik({
    initialValues: {
      eventName: eventOldData.name,
      placeName: eventOldData.location,
      placeAddress: eventOldData.address,
      startDate: eventOldData.startDate,
      startHour: eventOldData.startHour,
      countyId: eventOldData.countyId,
      endDate: "",
      endHour: eventOldData.endDate,
      invitationMessage: eventOldData.invitation,
      inputArrayValues,
    },
    validationSchema: createPrivateEventFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      verificatorParadeArray(inputArrayValues[0])
        .then(() => {
          formatterValuesToDispatch({
            url: country,
            event: {
              name: values.eventName,
              startDate: `${moment(values.startDate).format(
                "YYYY/MM/DD"
              )} ${moment(values.startHour).format("HH:mm")}`,
              endDate: `${moment(values.startDate).format(
                "YYYY/MM/DD"
              )} ${moment(values.endHour).format("HH:mm")}`,
              location: values.placeName,
              address: values.placeAddress,
              additionalStops: values.inputArrayValues[0],
              countyId: values.countyId,
              image: values.imgEvent,
              invitation: values.invitationMessage,
            },
          });
          //alert(JSON.stringify(values.inputArrayValues[0], null, 2))
        })
        .catch(() => {
          handleNotification(
            "Asegurate de llenar todos los campos de Paradas Adicionales",
            "error"
          );
        });
    },
  });

  const verificatorParadeArray = (array) => {
    return new Promise((resolve, reject) => {
      array.map((parade) => {
        if (parade.address === "" || parade.countyId === "") {
          reject();
        }
      });
      resolve();
    });
  };

  const formatterValuesToDispatch = (values) => {
    let isOk = false;
    putCreatePrivateEvent(eventId, values)
      .then(({ id }) => {
        let verificadorImagen = false;
        let verificadorXlsx = false;
        if (!image && eventOldData.imagePath) {
          verificadorImagen = false;
        } else if (image) {
          eventImg.append("image", image);
          postCreatePrivateEventImage(id, eventImg)
            .then(() => {
              verificadorImagen = false;
            })
            .catch((error) => {
              verificadorImagen = true;
              handleNotification("Error en subir la imagen", "error");
            });
        }
        if (!excel && eventOldData.guest) {
          verificadorXlsx = false;
        } else if (excel) {
          eventGuests.append("guests", excel);
          postCreatePrivateEventExcel(id, eventGuests)
            .then(() => {
              verificadorXlsx = false;
              console.log('approbe')
            })
            .catch((error) => {
              console.log('decline')
              verificadorXlsx = true;
              // handleNotification(
              //   "Error en subir la lista de invitados",
              //   "error"
              // );
            });
        }
        if (verificadorImagen === false && verificadorXlsx === false) {
          handleNotification("Solicitud creada Exitosamente", "success");
          setActiveTab(0);
          isOk = true;
          setShowHistoryList(1);
        }
      })
      .catch((error) => {
        if (!isOk) {
          console.error("error create_private_form", error);
          handleNotification("Error en la creación de la solicitud", "error");
        }
      });
  };

  const addOldParade = async (aditionalStopsArray) => {
    const newInputArray = [...paradeArray];
    setParadeArray(
      aditionalStopsArray.map((parade, index) => {
        newInputArray.push({
          _id: index + 1,
          address: parade.address,
          countyId: parade.countyId,
          time: moment(parade.time, "HH:mm:ss"),
        });
      })
    );
    setParadeArray(newInputArray);
    let arrayLength = aditionalStopsArray.length;
    setInputArrayId(arrayLength + 1);
  };

  const addParade = () => {
    setInputArrayId(inputArrayId + 1);
    const newInputArray = [...paradeArray];
    newInputArray.push({
      _id: inputArrayId,
      address: "",
      countyId: "",
      time: moment(new Date(), "HH:mm:ss"),
    });
    setParadeArray(newInputArray);
  };

  const deleteParade = (id) => {
    setParadeArray(paradeArray.filter((item) => item._id !== id));
  };

  const changeValuesParade = (id, inputValue, field) => {
    const newparadeArray = paradeArray.map((i) => {
      if (id === i._id) {
        if (field === 1) {
          i.address = inputValue;
        } else if (field === 2) {
          i.countyId = inputValue;
        } else if (field === 3) {
          i.time = inputValue;
        }
      }
      return i;
    });

    setParadeArray(newparadeArray);
  };

  const extractArrayValues = () => {
    inputArrayValues[0] = paradeArray
      .map(
        (item) =>
          item.value !== "" && {
            address: item.address,
            countyId: item.countyId,
            time: moment(item.time).format("HH:mm"),
          }
      )
      .filter((item) => item !== false);
    formik.values.inputArrayValues = inputArrayValues[0];
  };

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  const getDateSelected = (_id) => {
    return paradeArray.map((item) => {
      if (item._id === _id) {
        return item.time;
      }
    });
  };

  const action = (key) => {
    return (
      <Fragment>
        <Button
          className={classes.dismissNotification}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </Fragment>
    );
  };

  if (!eventId) {
    return (
      <div className={classes.divContainer}>
        <Grid
          container
          xs={12}
          md={12}
          justify="center"
          className={classes.msgError}
        >
          {"No es posible cargar su solicitud"}
        </Grid>
        <Grid container md={12} style={{ textAlign: "center" }}>
          <Grid xs={12} md={12} style={{ padding: 10 }}>
            <Button
              className={classes.cancelEventButton}
              onClick={() => setActiveTab(1)}
            >
              Volver
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (loading) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <div>
      <span className={classes.title}>Tus Eventos</span>
      <Grid container xs={12} className={!isMobile && classes.formContainer}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container xs={12}>
              <span className={classes.titleForm}>Crea tu evento privado</span>
            </Grid>
            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <Grid item xs={12}>
                  Nombre del evento
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="eventName"
                    placeholder="Cumpleaños de María"
                    onChange={formik.handleChange}
                    value={formik.values.eventName}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="eventName" />}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} md={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  Fecha y hora de inicio y término
                </span>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Fecha de inicio"
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullwidth
                        value={formik.values.startDate}
                        selected={formik.values.startDate}
                        className={classes.timeAndDate}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "startDate",
                            moment(event).format("YYYY/MM/DD")
                          );
                        }}
                        helperText={<ErrorMessage name="startDate" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <TimePicker
                        format="HH:mm"
                        name="startHour"
                        label="Hora de inicio"
                        inputVariant="outlined"
                        fullwidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={formik.values.startHour}
                        selected={formik.values.startHour}
                        className={classes.field}
                        onChange={(event) =>
                          formik.setFieldValue("startHour", event)
                        }
                        helperText={<ErrorMessage name="startHour" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                      <TimePicker
                        format="HH:mm"
                        label="Hora de término"
                        inputVariant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={formik.values.endHour}
                        selected={formik.values.endHour}
                        className={classes.field}
                        onChange={(event) =>
                          formik.setFieldValue("endHour", event)
                        }
                        helperText={<ErrorMessage name="endHour" />}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  Localización del Evento
                </span>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  Nombre del recinto del evento
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="placeName"
                    placeholder="Jardín de..."
                    onChange={formik.handleChange}
                    value={formik.values.placeName}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="placeName" />}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12}>
                  Dirección del recinto
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="placeAddress"
                    placeholder="Av ..."
                    onChange={formik.handleChange}
                    value={formik.values.placeAddress}
                    variant="outlined"
                    fullWidth
                    className={classes.field}
                    helperText={<ErrorMessage name="placeAddress" />}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} md={4}>
                <Grid item xs={12} md={12}>
                  {process.env.REACT_APP_TRANSLATE_DISTRICT}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field
                    as={TextField}
                    name={"countyId"}
                    placeholder={process.env.REACT_APP_TRANSLATE_DISTRICT}
                    onChange={formik.handleChange}
                    value={formik.values.countyId}
                    variant="outlined"
                    fullWidth
                    select
                    className={classes.field}
                    helperText={<ErrorMessage name="countyId" />}
                  >
                    {counties.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                {paradeArray.length > 0 && (
                  <Grid item xs={12} className={classes.titleForm}>
                    Agregar parada
                  </Grid>
                )}
                {paradeArray.length > 0 &&
                  paradeArray.map((item) => (
                    <Grid container xs={12} style={{ alignItems: "center" }}>
                      <Grid container xs={12} md={4}>
                        <Grid item xs={12} md={12}>
                          Dirección
                        </Grid>
                        <Grid container xs={12} md={12}>
                          <Field
                            as={TextField}
                            name={"address" + item._id}
                            placeholder="Av..."
                            onChange={(event) =>
                              changeValuesParade(
                                item._id,
                                event.target.value,
                                1
                              )
                            }
                            value={item.address}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                            className={classes.field}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={4}>
                        <Grid item xs={12} md={12}>
                          {process.env.REACT_APP_TRANSLATE_DISTRICT}
                        </Grid>
                        <Grid container xs={12} md={12}>
                          <Field
                            as={TextField}
                            name={"parade" + item._id}
                            placeholder={process.env.REACT_APP_TRANSLATE_DISTRICT}
                            onChange={(event) =>
                              changeValuesParade(
                                item._id,
                                event.target.value,
                                2
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={item.countyId}
                            variant="outlined"
                            fullWidth
                            select
                            className={classes.field}
                          >
                            {counties.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={3}>
                        <Grid item xs={12} md={12}>
                          Hora
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={es}
                            >
                              <TimePicker
                                name={"time" + item._id}
                                format="HH:mm"
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={getDateSelected(item._id)}
                                selected={getDateSelected(item._id)}
                                onChange={(event) =>
                                  changeValuesParade(item._id, event, 3)
                                }
                                helperText={<ErrorMessage name="paradeDate" />}
                              />
                            </MuiPickersUtilsProvider>
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} md={1} className={classes.AI}>
                        <Grid
                          container
                          xs={12}
                          md={12}
                          style={{ paddingTop: 30, paddingLeft: 10 }}
                        >
                          <p
                            className={classes.deleteParadeButton}
                            onClick={() => deleteParade(item._id)}
                          >
                            -
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <p
                    className={classes.addParadeText}
                    onClick={() => addParade()}
                  >
                    + Agregar parada
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  Imagen principal del evento
                </span>
              </Grid>
              <Grid item xs={3} style={{ alignItems: "bottom" }}>
                <label htmlFor="imgEvent" style={{ alignItems: "bottom" }}>
                  <input
                    id="imgEvent"
                    name="imgEvent"
                    style={{ display: "none" }}
                    type="file"
                    onChange={handleImg}
                    accept="image/*"
                  />
                  <Button
                    color="secondary"
                    component="span"
                    className={classes.uploadFileButton}
                  >
                    Buscar archivo
                  </Button>
                </label>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <p>
                  {eventImgName === "" && eventOldData.imagePath
                    ? "Existe una imagen cargada"
                    : // eventOldData.imagePath.substring(
                      //     eventOldData.imagePath.lastIndexOf("/") + 1
                      //   )
                      eventImgName}
                </p>
              </Grid>
            </Grid>

            <Grid container xs={12}>
              <Grid container xs={12} md={12}>
                <span className={classes.titleForm}>
                  Texto de invitación al evento
                </span>
              </Grid>
              <Grid item xs={12}>
                Escribe un mensaje de invitación
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="invitationMessage"
                  placeholder="Los invito a..."
                  variant="outlined"
                  className={classes.field}
                  onChange={formik.handleChange}
                  value={formik.values.invitationMessage}
                  fullWidth
                  multiline
                  rows={7}
                  rowsMax={8}
                  helperText={<ErrorMessage name="invitationMessage" />}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <span
                  className={classes.titleForm}
                  style={{ padding: "10px 0px" }}
                >
                  Lista de invitados
                </span>
              </Grid>

              <Grid
                container
                xs={12}
                md={6}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <Grid item xs={12} md={5}>
                  <Button
                    href="https://vmove.devaid.cl/uploads/events/requests/guests/invitados.xlsx"
                    download
                    className={classes.downloadXlsxButton}
                  >
                    <GetAppIcon /> Archivo xlsx
                  </Button>
                </Grid>

                <Grid item xs={12} md={7} style={{ paddingTop: 10 }}>
                  <p> Descargar Archivo</p>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                md={6}
                style={{ alignItems: "center", paddingTop: 10 }}
              >
                <Grid item xs={12} md={5}>
                  <label htmlFor="guestList">
                    <input
                      id="guestList"
                      name="guestList"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleExcel}
                      accept=".xlsx,.xls"
                    />
                    <Button
                      color="secondary"
                      component="span"
                      className={classes.uploadFileButton}
                    >
                      Subir Archivo
                    </Button>
                  </label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{ alignItems: "center", paddingTop: 10 }}
                >
                  <p>
                    {eventGuestsName === ""
                      ? eventOldData.guest === true
                        ? "Hay un excel de invitados cargado"
                        : "Ningún archivo seleccionado"
                      : eventGuestsName}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={12} style={{ textAlign: "center" }}>
              <Grid item xs={12} md={6} style={{ padding: 10 }}>
                <Button
                  className={classes.createEventButton}
                  onClick={() => extractArrayValues()}
                  type="submit"
                >
                  Guardar Información
                </Button>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: 10 }}>
                <Button
                  className={classes.cancelEventButton}
                  onClick={() => setActiveTab(0)}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Grid>
    </div>
  );
};

export default UpdatePrivateEventForm;
