import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  ListItem,
  TextField,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import styles from "assets/jss/views/eventPageSections/generalEventsStyles.js";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Input from "components/Tools/Input";
import InputControl from "components/Tools/InputControl";
import Context from "context/Context";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/material.css";

const filter = createFilterOptions();
const useStyles = makeStyles(styles);

const PassengersByTicket = (props) => {
  const {
    passengers,
    updatePassengers,
    capacity,
    addresses,
    currentTicket,
    validateForm,
  } = props;
  const phoneStyle = {
    width: "100%",
    fontSize: "14px",
    height: "47px",
    borderColor: "#8e98a7",
    opacity: "1",    
    "&:focus": {
      borderColor: "#8e98a7",
      boxShadow: "none"
    },
  };
  const [counter, setCounter] = useState(passengers.length);
  const contextType = useContext(Context);
  const { getUserData, t } = contextType;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const frequentFriends = getUserData().frequentFriends;
  const formattedFrequentFriends = frequentFriends.map((friend) => {
    return {
      id: friend.id,
      name: friend.fullname,
      email: friend.email,
      phone: friend.phone,
    };
  });

  const initialTickets = {
    name: "",
    email: "",
    phone: "",
    passengerAddress: "",
  };

  if(counter !== passengers.length){
    setCounter(passengers.length)
  }

  const handleChangeFriendField = (value, index) => {
    passengers[index] = value;
    updatePassengers(passengers);
    handleIncrement();
    handleDecrement();
  };

  const handleIncrement = () => {
    setCounter(counter + 1);
    passengers.push(initialTickets);
    updatePassengers(passengers);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
    passengers.pop();
    updatePassengers(passengers);
    validateForm();
  };

  const handleInputChangePhone = (value, index) => {
    console.log(value);
    passengers[index].phone = value;
  };

  return (
    <>
      {passengers.map((passenger, index) => (
        <div key={`${index}`}>
          <>
            <ListItem
              className={
                isMobile ? classes.customItemMobile : classes.customItem
              }
              id={`ticket_${index + 1}`}
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                spacing={2}
                justify="flex-end"
              >
                <Grid item xs={11} md={3} className={classes.itemFullCenter}>
                  <div className={classes.frequentFriendTextfield}>
                    <Autocomplete
                      value={passengers[index] ? passengers[index].name : ""}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          handleChangeFriendField(
                            {
                              name: newValue,
                            },
                            index
                          );
                        } else if (newValue && newValue.inputValue) {
                          handleChangeFriendField(
                            {
                              name: newValue.inputValue,
                            },
                            index
                          );
                        } else {
                          handleChangeFriendField(newValue, index);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Agregar "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      noOptionsText={'Ingresar Nombre y Apellido'}
                      onBlur={(event) => {
                        if (!passengers[index]?.email) {
                          handleChangeFriendField(
                            { name: event.target.value, phone: '', email: '' },
                            index
                          );
                        }

                      }}
                      id={`friends-add-${index}`}
                      options={formattedFrequentFriends}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      fullWidth
                      renderOption={(option) =>
                        option.name ? option.name : passenger[index].name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("name_and_surname")}
                          name={`tickets[${currentTicket}].passengers[${index}].name`}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Input
                    label={t("email")}
                    name={`tickets[${currentTicket}].passengers[${index}].email`}
                    value={passengers[index]?.email && passengers[index].email}
                  />
                </Grid>
                <Grid item xs={11} md={3}>
                  <PhoneInput
                    inputProps={{
                      name: 'tickets['+currentTicket+'].passengers['+index+'].phone'
                    }}
                    value={passengers[index]?.phone && passengers[index].phone}
                    country={process.env.REACT_APP_PHONE_COUNTRY}
                    localization={es}
                    inputStyle={phoneStyle}
                    placeholder={t("phone")}
                    specialLabel={""}
                    defaultMask={". .... .... ... ..."}
                    onChange={(phone) =>
                      handleInputChangePhone(phone,index)
                    }
                  />
                </Grid>
                <Grid item xs={11} md={3}>
                  <InputControl
                    label={t("select_your_address")}
                    name={`tickets[${currentTicket}].passengers[${index}].passengerAddress`}
                    data={addresses}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {index + 1 !== capacity ? (
              <Divider classes={{ root: classes.customDividerCollapse }} />
            ) : null}
          </>
        </div>
      ))}
      <Grid
        container
        display="flex"
        alignItems="center"
        justify="center"
        className={classes.gridButtonContainer}
        spacing={3}
      >
        {capacity > 1 ? (
          <>
            <Grid item>
              <Button
                disabled={counter === 1}
                onClick={() => handleDecrement()}
                classes={{ root: classes.buttonRemove }}
                endIcon={<RemoveCircleOutlineIcon />}
              >
                {t("remove")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={counter === capacity}
                onClick={() => handleIncrement()}
                classes={{ root: classes.buttonAdd }}
                endIcon={<AddCircleOutlineIcon />}
              >
                {t("add")}
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default PassengersByTicket;
