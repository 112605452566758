import { customDivider, dullOrangeColor, grayColorSearchText, orangeColor, orangeColorRgba, whiteColor } from 'assets/jss/style.js'
import { mb05r, mt1r, mt2r, latoBold, latoRegular, fs14, fs16, fs18, fs20 } from 'assets/jss/tools.js'

const generalEventsStyles = {
  error: {
    color: 'red',
    fontFamily: 'Lato-Regular',
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  gridButtonContainer: {
    marginTop: 15,
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      cursor: 'default',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
  boxAddressOptionBorder: {
    borderRadius: '12px !important',
  },
  loader: {
    margin: '50px 0',
  },
  addressTitle: {
    color: '#7E7E7E',
    fontFamily: latoBold,
  },
  boxRenderTickets: {
    paddingTop: '25px',
  },
  transferMesssage: {
    margin: '20px 0',
    color: '#3E3E3E',
    fontSize: '16px',
  },
  boxTickets: {
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    margin: '1.5rem 0',
    //minHeight: "63px",
    opacity: '1',
    width: '100%',
  },
  disableHover: {
    margin: 0,
    padding: '20px 0',
    '&:hover': {
      cursor: 'default',
    },
    width: '100%',
  },
  summaryCategory: {
    margin: 0,
    padding: 0,
  },
  info: {
    padding: '0 1rem',
  },
  containerTickets: {
    padding: '0rem 1rem',
  },
  containerTicketsMobile: {
    padding: '0rem 1rem',
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemFullCenterEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addressInput: {
    background: whiteColor,
    border: '1px solid #8E98A7',
    borderRadius: '5px',
    color: grayColorSearchText,
    fontFamily: latoRegular,
    fontSize: '14px',
    height: '45px',
    margin: '5px 0 5px 0',
    opacity: '1',
    padding: '0 15px',
    width: '100%',
  },
  addressCollapse: {
    width: '100%',
  },
  boxCircle: {
    background: '#F29E6F',
    borderRadius: '50%',
    height: '15px',
    width: '15px',
  },
  iconCircle: {
    color: dullOrangeColor,
  },
  labelAddressInput: {
    color: '#7E7E7E',
    fontFamily: latoRegular,
  },
  itemSpacing: {
    padding: '0.5rem 0',
  },
  customList: {
    padding: '2rem 0 1rem 0',
  },
  customItemPayment: {
    display: 'flex',
    padding: '0.5rem 4rem',
    alignItems: 'center',
  },
  customItemPaymentMobile: {
    display: 'flex',
    padding: '1rem 1rem',
    alignItems: 'center',
  },
  customItemPaymentTitle: {
    display: 'flex',
    padding: '1rem 4rem 0rem 4rem',
    alignItems: 'center',
  },
  customItemPaymentList: {
    display: 'flex',
    padding: '1rem 4rem 0rem 4rem',
    marginBottom: '2rem',
    alignItems: 'center',
  },
  customItem: {
    display: 'flex',
    padding: '2rem 4rem',
    alignItems: 'center',
  },
  customItemMobile: {
    display: 'flex',
    padding: '2rem 1rem',
    alignItems: 'center',
  },
  customItemText: {
    margin: '0',
    color: orangeColorRgba,
  },
  customItemIcon: {
    margin: '0',
  },
  customInput: {
    borderWidth: '1px',
    padding: '6px 12px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  boxTotalButton: {
    padding: '3px 3px',
  },
  notchedOutline: {
    borderColor: 'lightgray !important',
    '&:hover': {
      borderColor: 'red',
    },
  },
  customInputTotal: {
    color: '#7E7E7E',
    fontFamily: latoBold,
  },
  customInputOriginalTotal: {
    textDecoration: 'line-through',
  },
  customFormControl: {
    marginBottom: '0.5rem',
  },
  customFirstFormControlMobile: {
    padding: '0px 10px',
  },
  customSecondFormControlMobile: {
    padding: '0px 20px',
    marginLeft: '11px',
  },
  customLabel: {
    color: '#7E7E7E',
    fontFamily: latoRegular,
    marginBottom: '0.2rem',
  },
  customLabelFocused: {
    color: '#7E7E7E !important',
    fontFamily: latoBold,
  },
  customTextField: {
    background: whiteColor,
    border: '1px solid #8E98A7',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    height: '47px',
    padding: '0 15px',
  },
  buttonTicket: {
    background: orangeColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    width: 120,
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
      opacity: '1',
      cursor: 'pointer !important',
    },
  },
  buttonEditTicket: {
    background: orangeColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    width: 'auto',
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
      opacity: '1',
      cursor: 'pointer !important',
    },
  },
  buttonTicketExpanded: {
    background: whiteColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
    width: 120,
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  buttonRemove: {
    background: whiteColor,
    border: '1px solid',
    borderColor: 'red',
    borderRadius: '5px',
    color: 'red',
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
    width: 120,
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  buttonAdd: {
    background: orangeColor,
    border: '1px solid',
    borderColor: 'red',
    borderRadius: '5px',
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    width: 120,
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: 'red',
      opacity: '1',
      cursor: 'pointer !important',
    },
  },
  boxDivider: {
    padding: '1rem',
  },
  customTextFieldOutlined: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 48,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormLabel-filled': {
      top: '0px !important',
    },
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '-4px',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      borderRadius: '5px',
      height: '47px',
      color: '#000000',
      // color: '#8E98A7',
      fontFamily: latoRegular,
      padding: '0 15px',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#8E98A7',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
  },

  customTextFieldMobile: {
    width: '100%',
    marginBottom: '10px',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 48,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiFormLabel-filled': {
      top: '0px !important',
    },
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '-4px',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      borderRadius: '5px',
      height: '47px',
      color: '#8E98A7',
      fontFamily: latoRegular,
      padding: '0 15px',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#8E98A7',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
  },
  frequentFriendTextfield: {
    width: '100%',
    '& .MuiInputBase-input': {
      height: '0.6rem',
    },
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '-4px',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
  },
  customDividerCollapse: {
    background: '#CDCDCD',
    margin: '0 1rem',
  },
  customIconQuestion: {
    color: orangeColor,
    marginLeft: '0.5rem',
  },
  customPaperPopover: {
    background: '#7E7E7E',
    borderRadius: '4px',
    boxShadow: '0px 3px 6px #00000029',
    height: 'auto',
    maxWidth: '282px',
    padding: '8px 15px',
  },
  customLabelPopover: {
    color: whiteColor,
    fontFamily: latoRegular,
  },
  customTextFieldSelect: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 48,
    },
    '& .MuiFormLabel-filled': {
      top: '0px !important',
    },
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '-4px',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      alignItems: 'center',
      background: whiteColor,
      borderRadius: '5px',
      display: 'flex',
      // color: '#7E7E7E',
      color: '#000000',
      fontFamily: latoRegular,
      height: '47px',
      padding: '0 15px',
      width: '100%',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#7E7E7E',
      fontFamily: latoRegular,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#CDCDCD',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#CDCDCD',
      fontFamily: latoRegular,
    },
  },
  customTextFieldSelectMobile: {
    width: '100%',
    marginBottom: '10px',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: 48,
    },
    '& .MuiFormLabel-filled': {
      top: '0px !important',
    },
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '-4px',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      alignItems: 'center',
      background: whiteColor,
      borderRadius: '5px',
      color: '#7E7E7E',
      display: 'flex',
      fontFamily: latoRegular,
      height: '47px',
      padding: '0 15px',
      width: '100%',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#7E7E7E',
      fontFamily: latoRegular,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#CDCDCD',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#CDCDCD',
      fontFamily: latoRegular,
    },
  },
  boxPoint: {
    paddingRight: '1rem',
  },
  boxPointMobile: {
    left: '10px',
    position: 'absolute',
  },
  customMenuItem: {
    fontFamily: latoRegular,
  },
  invitedEventPayment: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    textTransform: 'uppercase',
  },
  boxQuantity: {
    alignItems: 'center',
    background: dullOrangeColor,
    borderRadius: '50%',
    color: whiteColor,
    display: 'flex',
    height: '35px',
    justifyContent: 'center',
    width: '35px',
  },
  labelQuantity: {
    fontFamily: latoRegular,
    fontSize: '14px',
  },
  ml10: {
    marginLeft: '1rem',
  },
  mb05r,
  mt1r,
  mt2r,
  customDivider,
  fs14,
  fs16,
  fs18,
  fs20,
}

export default generalEventsStyles
