import React, { useContext, useState, useRef, useEffect } from "react";
import Context from "../../context/Context";
import styles from "assets/jss/views/boyfriendsPage.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MarriageSchema } from "utils/validations";

//Calendar
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import orange from "@material-ui/core/colors/orange";

import AboutUs from "assets/img/vmove/about-us-page/about-us.png";
import ReactPlayer from "react-player";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { postMarriage } from "provider/publicProvider/provider";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  date: null,
  place: "",
  message: "",
};

const useStyles = makeStyles(styles);

export default function BoyfriendsPage(props) {
  const [boolSendMessage, setBoolSendMessage] = useState(false);
  const divRef = useRef(null);

  const contextType = useContext(Context);
  const { t, getCalendarLanguage, getCountry } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const customSpacing = isMobile ? 0 : 4;

  const handleSubmit = (values) => {
    setBoolSendMessage(true);
    postMarriage(getCountry(), values).then((response) => {
      setBoolSendMessage(true);
    });
    //alert(JSON.stringify(values))
  };

  useEffect(() => {
    const heightToScroll = divRef.current.offsetTop - 115;
    window.scrollTo({
      top: heightToScroll,
      behavior: "smooth",
    });
  }, []);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& fieldset": {
            alignItems: "center",
            borderColor: "#8E98A7",
          },
          "&:hover $notchedOutline": {
            borderColor: "#8E98A7",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#8E98A7",
          },
          color: "#CDCDCD",
          "&$focused $notchedOutline": {
            borderColor: "#8E98A7",
            borderWidth: "2px",
          },
          "& input": {
            padding: "15px 14px",
          },
        },
      },
      MuiInputLabel: {
        root: {
          "&$focused": {
            color: "#8E98A7",
          },
        },
        outlined: {
          top: "-4px !important",
          "&$shrink": {
            top: "0px !important",
          },
        },
      },
    },
  });

  return (
    <Box
      className={isMobile ? classes.bgWhite : null}
      classes={{ root: classes.contentWrapper }}
    >
      {isMobile ? (
        <Box>
          <img src={AboutUs} alt="About Us" className={classes.imageMobile} />
        </Box>
      ) : (
        <Box className={classes.boxContentWrapper}></Box>
      )}
      <Container
        ref={divRef}
        className={
          isMobile
            ? classes.containerDetailInfoMobile
            : classes.containerDetailInfo
        }
      >
        <Box
          classes={{ root: classes.boxTitle }}
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <Typography
            align="center"
            classes={{ root: classes.labelTitle }}
            className={isMobile ? classes.fs25 : classes.fs50}
          >
            {t("marriages")}
          </Typography>
        </Box>
        <Divider classes={{ root: classes.customDivider }} />
        <Grid
          container
          spacing={customSpacing}
          className={
            isMobile
              ? classes.gridContainerBodyMobile
              : classes.gridContainerBody
          }
        >
          <Grid item xs={12} md={12}>
            <Typography
              classes={{ root: classes.labelBody }}
              className={
                (isMobile ? classes.fs16 : classes.fs20) + " " + classes.mt1r
              }
            >
              Haz de tu matrimonio una experiencia única. Llena los datos y nos contactaremos de vuelta con las mejores
              alternativas de traslado, para hacer de tu matrimonio un día
              especial e inolvidable.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              className={classes.root}
              validationSchema={MarriageSchema}
              autoComplete="off"
            >
              {({ dirty, isValid, values, setFieldValue }) => {
                return (
                  <Form>
                    <Grid
                      container
                      className={classes.containerFormContact}
                      spacing={5}
                    >
                      <Grid item xs={12} md={8}>
                        <Grid
                          container
                          className={classes.containerVideo}
                          spacing={5}
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.itemDirectionColumnVideo}
                          >
                            <ReactPlayer
                              playing
                              url={"https://player.vimeo.com/video/517284266"}
                              className="react-player"
                              width="100%"
                              height="50vh"
                              volume={0.2}
                              controls
                              config={{
                                vimeo: {
                                  autoplay: true
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid
                          container
                          className={classes.containerFormContact}
                          spacing={2}
                        >
                          {!boolSendMessage ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <Field
                                  as={TextField}
                                  classes={{
                                    root: classes.customTextFieldOutlined,
                                  }}
                                  fullWidth
                                  label={t("bride_and_groom_name")}
                                  name="name"
                                  placeholder="María José y Pedro"
                                  variant="outlined"
                                  helperText={<ErrorMessage name="name" />}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <Field
                                  as={TextField}
                                  classes={{
                                    root: classes.customTextFieldOutlined,
                                  }}
                                  fullWidth
                                  label={t("email")}
                                  name="email"
                                  placeholder="maria@correo"
                                  variant="outlined"
                                  helperText={<ErrorMessage name="email" />}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <Field
                                  as={TextField}
                                  classes={{
                                    root: classes.customTextFieldOutlined,
                                  }}
                                  fullWidth
                                  label={t("phone")}
                                  name="phone"
                                  placeholder={"+569 58962335"}
                                  variant="outlined"
                                  helperText={<ErrorMessage name="phone" />}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <ThemeProvider theme={defaultMaterialTheme}>
                                  <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    locale={getCalendarLanguage()}
                                  >
                                    <DatePicker
                                      autoOk
                                      format="dd/MM/yyyy"
                                      label={t("date")}
                                      classes={{
                                        root: classes.customTextFieldOutlined,
                                      }}
                                      inputVariant="outlined"
                                      disablePast
                                      value={values.date}
                                      selected={values.date}
                                      onChange={(date) =>
                                        setFieldValue("date", date)
                                      }
                                      helperText={<ErrorMessage name="date" />}
                                    />
                                  </MuiPickersUtilsProvider>
                                </ThemeProvider>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <Field
                                  as={TextField}
                                  classes={{
                                    root: classes.customTextFieldOutlined,
                                  }}
                                  fullWidth
                                  label={t("place")}
                                  name="place"
                                  placeholder={"Calle 123..."}
                                  variant="outlined"
                                  helperText={<ErrorMessage name="place" />}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.itemDirectionColumn}
                              >
                                <Field
                                  as={TextField}
                                  classes={{
                                    root: classes.customTextFieldOutlined,
                                  }}
                                  fullWidth
                                  label={t("tell_us_about_you_marriage")}
                                  name="message"
                                  placeholder={"Somos una pareja..."}
                                  variant="outlined"
                                  multiline
                                  rows={7}
                                  rowsMax={8}
                                  helperText={<ErrorMessage name="message" />}
                                />
                              </Grid>
                              {!boolSendMessage ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  className={classes.itemFullCenter}
                                >
                                  <Button
                                    classes={{ root: classes.buttonSend }}
                                    type="submit"
                                    variant="contained"
                                    disabled={!dirty || !isValid}
                                  >
                                    Enviar Mensaje
                                  </Button>
                                </Grid>
                              ) : null}
                            </>
                          ) : (
                            <Typography
                              classes={{ root: classes.labelBody }}
                              className={
                                isMobile
                                  ? `${classes.fs16} ${classes.mt05r}`
                                  : `${classes.fs20} ${classes.mt05r} ${classes.labelBodySuccessWeb}`
                              }
                            >
                              Muchas gracias! Te contactaremos a la brevedad
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
