import { contentWrapper, contentWrapperMobile, orangeColor, whiteColor } from 'assets/jss/style.js'
import { mt1r, mt2r, mb1r, latoBold, fs16, fs18, fs20, fs24, fs25, fs30, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const resetPasswordStyle = {
  resetTitle: {
    fontSize: '40px',
    fontWeight: '400',
    paddingBottom: '15px',
  },
  error: {
    position: 'absolute',
    color: 'red',
    fontSize: '12px',
  },
  resetInput: {
    margin: '15px 0',
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      borderRadius: '5px',
      height: '47px',
      color: '#8E98A7',
      padding: '0 15px',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#8E98A7',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#8E98A7',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#8E98A7',
    },
  },
  resetMessage: {
    fontSize: '18px',
    fontWeight: '300',
    paddingBottom: '20px',
  },
  gridContainer: {
    maxWidth: '850px',
    margin: '100px auto',
    padding: '50px 15px',
    border: '2px solid',
    borderRadius: 40,
    borderColor: '#F19D6F',
    borderWidth: 5,
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonBack: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    height: '63px',
    marginTop: '1rem',
    opacity: '0.9',
    textTransform: 'initial',
    width: '292px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  contentWrapper,
  contentWrapperMobile,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs30,
  fs32,
  fs50,
  fs60,
}

export default resetPasswordStyle
