import { contentWrapper } from 'assets/jss/style.js'
import { whiteColor } from '../style'
import { latoRegular } from '../tools'

const readerQR = {
  container: {
    margin: '20px 0 50px 0',
  },
  scannerButton: {
    color: whiteColor,
    padding: '10px 40px',
    background: '#F29E6F',
    border: '2.5px solid #FF5E05',
    boxSizing: 'border-box',
    borderRadius: 20,
    marginTop: 50,
  },
  fullItemCenter: {
    textAlign: 'center',
    fontFamily: latoRegular,
  },
  messageFormat: {
    textAlign: 'center',
    fontFamily: latoRegular,
    fontWeight: 600,
    width: '300px',
    marginBottom: 30,
  },
  contentWrapper,
}

export default readerQR
