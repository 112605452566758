import React, { useState, useEffect, useContext } from 'react'
import Carousel from 'react-slick'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/views/landingPageSections/carouselStyle.js'
import Context from '../../../context/Context'
import Card from 'components/Card/Card.js'
import { getBannerSlides } from 'provider/publicProvider/provider'
import CustomCircularProgress from '../../../components/CircularProgress/CustomCircularProgress'
import 'assets/scss/views/landingPageSections/carouselStyle.scss'

const useStyles = makeStyles(styles)

export default function SectionCarousel(props) {
  const [slidersList, setSlidersList] = useState([])
  const contextType = useContext(Context)
  const { getLanguage, getCountry } = contextType
  const classes = useStyles()

  useEffect(() => {
    const getSliders = () => {
      getBannerSlides(getCountry()).then((res) => {
        setSlidersList(res)
      })
    }
    getSliders()
  }, [getCountry(), getLanguage()])

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  }

  return (
    <Card carousel className={classes.carouselWrapper} id='landing-carousel'>
      {slidersList.length > 0 ? (
        <Carousel {...settings}>
          {slidersList.map((slider) => {
            return (
              <Box
                key={slider.id}
                className={classes.cover}
                // style={{ backgroundImage: `url(${slider.imagePath}) !important` }}
              >                
                <a href={slider.url}>
                  <img alt={'Slider'} src={slider.imagePath} className={classes.coverImage} />
                </a>
              </Box>
            )
          })}
        </Carousel>
      ) : (
        <CustomCircularProgress />
      )}
    </Card>
  )
}
