import { response } from './client'

export const postLogin = (data) => {
  const options = {
    method: 'POST',
    url: `/login`,
    data,
  }
  return response(options)
}

export const postRegister = (data) => {
  const options = {
    method: 'POST',
    url: `/register`,
    data,
  }
  return response(options)
}

export const setLogout = () => {
  localStorage.removeItem('token')
  return Promise.resolve()
}

export const postVerify = (token) => {
  const options = {
    url: `/verify-account`,
    params: { token },
  }
  return response(options)
}

export const postResetPassword = (token, data) => {
  const options = {
    url: `/reset-password`,
    method: 'POST',
    params: { token },
    data,
  }
  return response(options)
}

export const postRecoveryPassword = (data) => {
  const options = {
    url: `/recovery`,
    method: 'POST',
    data,
  }
  return response(options)
}
