import React from "react";
import styles from "assets/jss/views/massiveEventsPage.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import {  Box,
  Container,
  Grid,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ResponsePaymentPage() {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <Box
      className={
        isMobile ? classes.contentWrapperMobile : classes.contentWrapper
      }
    >
      <Container>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          className={classes.gridContainerList}
          justify="center"
          spacing={2}
        >
          <Typography>
            {"Gracias por su compra"}
          </Typography>
        </Grid>
      </Container>
    </Box>
  );
}