import React from 'react'
import MediaQuery from 'react-responsive'
import FooterDesktop from './_footerDesktop'
import FooterMobile from './_footerMobile'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/footerStyle.js'

const useStyles = makeStyles(styles)

export default function Footer(props) {
  const classes = useStyles()
  return (
    <Box classes={{ root: classes.footerContent }}>
      <MediaQuery minDeviceWidth={481}>
        <FooterDesktop />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={480}>
        <FooterMobile />
      </MediaQuery>
    </Box>
  )
}
