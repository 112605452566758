import React, { useContext, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Context from '../../context/Context'
import styles from 'assets/jss/views/reservationPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import AboutUs from 'assets/img/vmove/about-us-page/about-us.png'
import ReactPlayer from 'react-player'
import { Box, Container, Divider, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

export default function ReservationPage(props) {
  const divRef = useRef(null)
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  useEffect(() => {
    const heightToScroll = divRef.current.offsetTop - 115
    window.scrollTo({
      top: heightToScroll,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Box className={isMobile ? classes.bgWhite : null} classes={{ root: classes.contentWrapper }}>
      {isMobile ? (
        <Box>
          <img src={AboutUs} alt='About Us' className={classes.imageMobile} />
        </Box>
      ) : (
        <Box className={classes.boxContentWrapper} />
      )}
      <Container ref={divRef} className={isMobile ? classes.containerDetailInfoMobile : classes.containerDetailInfo}>
        <Box classes={{ root: classes.boxTitle }} display='flex' width='100%' justifyContent='center'>
          <Typography align='center' style={{ flex: 1 }} classes={{ root: classes.labelTitle }} className={isMobile ? classes.fs25 : classes.fs50}>
            ¿{t('how_to_book')}?
          </Typography>
          <Link to='/faq' className={classes.linkFaq}>
            {t('frequent_questions')}
          </Link>
        </Box>
        <Box>
        </Box>
        <Divider classes={{ root: classes.customDivider }} />
        <Box display='flex' flexDirection='column'>
          <Box classes={{ root: classes.boxMedia }} className={classes.mt2r}>
            <ReactPlayer
              url={'https://youtu.be/u_FR861eou8'}
              className={classes.boxYoutube}
              playing
              width='100%'
              height='50vh'
              volume={0.2}
              controls
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
