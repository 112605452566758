import {
  contentWrapper,
  orangeColor,
  dullOrangeColor,
  whiteColor
} from "assets/jss/style.js";
import {
  mb1r,
  latoBold,
  latoRegular,
  fs18,
  fs20,
  fs25,
  fs50,
  fs60
} from "assets/jss/tools.js";

const contactPage = {
  boxContentContact: {
    paddingTop: "4rem",
    paddingBottom: "2rem"
  },
  titleContact: {
    color: dullOrangeColor,
    fontFamily: latoBold
  },
  containerFormContact: {
    padding: "1.5rem 0 2.5rem 0"
  },
  itemDirectionColumn: {
    display: "flex",
    flexDirection: "column"
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  customFormControl: {
    marginBottom: "0.5rem"
  },
  customLabelContact: {
    color: "#7E7E7E",
    fontFamily: latoRegular,
    fontSize: "16px",
    marginBottom: "0.2rem"
  },
  customLabelFocused: {
    color: "#7E7E7E !important",
    fontFamily: latoBold
  },
  customTextFieldContact: {
    background: whiteColor,
    border: "2px solid #CDCDCD",
    borderRadius: "5px",
    color: "#CDCDCD",
    fontFamily: latoRegular,
    height: "35px",
    marginBottom: "20px",
    padding: "0 15px"
  },
  customTextAreaFieldContact: {
    background: whiteColor,
    border: "2px solid #CDCDCD",
    borderRadius: "5px",
    color: "#CDCDCD",
    fontFamily: latoRegular,
    paddingLeft: "1rem"
  },
  customTextArea: {
    fontFamily: latoRegular,
    background: "red"
  },
  buttonSend: {
    background: orangeColor,
    borderRadius: "43px",
    color: whiteColor,
    fontFamily: latoBold,
    marginTop: '40px',
    fontSize: "18px",
    height: "46px",
    opacity: "0.9",
    textTransform: "initial",
    width: "292px",
    "&:hover": {
      background: orangeColor,
      color: whiteColor,
      opacity: "1"
    }
  },
  iconPhone: {
    marginRight: "5px"
  },
  iconFlag: {
    height: "40px",    
    alignItems: "right",
    justifyContent: "right",
    display: "flex",
    float: "left",
    paddingLeft: "50px",
  },
  containerCountryContact: {
  },  
  boxCountryContact: {
    marginTop: "30px",
  },
  labelPhone: {
    alignItems: "center",
    justifyContent: "center",
    margin: "7px 0 0 0",
    color: "black",
    display: "flex",
    fontWeight: 'bold',
    fontFamily: latoRegular
  },
  labelAddress: {
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    display: "flex",
    fontSize: "12px",
    fontWeight: 'bold',
    fontFamily: latoRegular
  },
  contentWrapper,
  mb1r,
  fs18,
  fs20,
  fs25,
  fs50,
  fs60
};

export default contactPage;
