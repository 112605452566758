import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/languageSelectorStyle.js'
import Flag from 'react-world-flags'

const useStyles = makeStyles(styles)

export default function CountrySelector(props) {
  const classes = useStyles()

  return (
    // <Fragment>
    //   {props.countries.map(countrie => {
    //     return (
    //       <Grid item key={countrie.id}>
    //         <Button
    //           classes={{ root: classes.buttonFlag }}
    //           href={`http://www.vmove.${countrie.code.toLowerCase()}`}
    //           target="_blank"
    //           value={countrie.code}
    //         >
    //           <Flag code={countrie.code} className={classes.customFlag} />
    //         </Button>
    //       </Grid>
    //     );
    //   })}
    // </Fragment>
    <Grid item key={props.countries[0].id}>
      <Button
        classes={{ root: classes.buttonFlag }}
        href={`http://www.vmove.${props.countries[0].code.toLowerCase()}`}
        target='_blank'
        value={props.countries[0].code}
      >
        <Flag code={props.countries[0].code} className={classes.customFlag} />
      </Button>
    </Grid>
  )
}

CountrySelector.propTypes = {
  countries: PropTypes.array.isRequired,
}
