import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/eventPageSections/reservationTabsStyle.js";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Box,
  Grid,
  ListItem,
  Typography,
  Snackbar,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ResumeData from "./ResumeData";
import Context from "context/Context";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(styles);

const StepperFooter = (props) => {
  const { values, activeStep, isValid, total, paymentType, calculateTotal, calculateOriginalTotal } = props;
  const [openValidation, setOpenValidation] = useState(false);
  const [data, setData] = useState([]);
  const [isEnable, setIsEnable] = useState(false);

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const contextType = useContext(Context);
  const { getLanguage, t, getDiscount } = contextType;
  const [discount, setDiscounts] = useState([]);

  const handleValidation = () => {
    !isValid ? setOpenValidation(true) : setOpenValidation(false);
  };

  const handleErrorMessage = () => {
    return activeStep === 0 ? t("incomplete_tickets") : t("incomplete_form");
  };

  useEffect(() => {
    const enableButton = () => {
      switch (activeStep) {
        case 0:
          return setIsEnable(isValid);
        case 1:
          return setIsEnable(isValid);
        case 2:
          return setIsEnable(isValid);
        case 3:
          return setIsEnable(isValid);
        default:
          return "Unknown stepIndex";
      }
    };
    enableButton();
  }, [isValid]);

  const getAction = () => {
    switch (activeStep) {
      case 3:
        return t("pay");
      case 4:
        return t("finish");
      default:
        return t("continue");
    }
  };

  useEffect(() => {
    const resumeTickets = () => {
      const result = values.tickets
        .map((ticket) => ticket.name)
        .reduce((arr, current) => {
          if (
            arr.some(
              (element) =>
                element.name[getLanguage()].name === current[getLanguage()].name
            )
          ) {
            const index = arr.findIndex(
              (object) =>
                object.name[getLanguage()].name === current[getLanguage()].name
            );
            arr[index].quantity++;
            return arr;
          } else {
            arr.push({ name: current, quantity: 1 });
            return arr;
          }
        }, []);
      setData(result);
    };
    resumeTickets();
  }, [total]);

  useEffect(() => {
    if (getDiscount()?.code) {
      setDiscounts(getDiscount().productVariants);
    }
  }, [getDiscount()]);

  if(discount.length > 0){
    calculateTotal(values, discount)
  }

  return (
    <Box classes={{ root: classes.boxSummary }}>
      <ListItem classes={{ root: classes.customItemSummary }}>
        <Grid container display="flex" justify="center" alignItems="center">
          <Grid
            item
            xs={6}
            md={5}
            className={
              isMobile
                ? classes.itemSummaryCategoriesMobile
                : classes.itemSummaryCategories
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              classes={{ root: classes.boxResumeCategories }}
            >
              {activeStep < 4 && <ResumeData data={data} />}
            </Box>
          </Grid>
          <Grid item xs={2} md={1}>
            <Typography
              align="start"
              classes={{ root: classes.labelTotal }}
              className={isMobile ? classes.fs14 : classes.fs18}
            >
              {t("total")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            //className={isMobile ? classes.itemFlexEnd : null}
          >
            <NumberFormat
              value={getDiscount()?.code !== undefined
                ? calculateOriginalTotal(values, discount)
                : ""}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={process.env.REACT_APP_MONEY_SIGN}
              renderText={(value) => (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  value={total}
                  style={getDiscount()?.code !== undefined ? (isMobile
                      ? { textDecoration: 'line-through', fontSize: 13, paddingTop: 10 } : { paddingBottom: 5, textDecoration: 'line-through' }) : {}}
                >
                  {value}
                </Box>
              )}
            />
            
            <NumberFormat
              value={total}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={process.env.REACT_APP_MONEY_SIGN}
              renderText={(value) => (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  value={total}
                  classes={{ root: classes.customInputTotal }}
                  className={
                    isMobile
                      ? classes.customInputTotalMobile
                      : classes.customInputTotal
                  }
                >
                  {value}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Snackbar
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              open={openValidation}
              onClose={() => setOpenValidation(false)}
              autoHideDuration={4000}
            >
              <Alert severity="error">{handleErrorMessage()}</Alert>
            </Snackbar>
            <span onClick={() => handleValidation()}>
              <Button
                type="submit"
                classes={{
                  disabled: classes.customButtonDisabled,
                }}
                className={
                  isMobile
                    ? classes.buttonContinueMobile
                    : classes.buttonContinue
                }
                color="primary"
                disabled={!isEnable}
                fullWidth
                size="small"
              >
                {getAction() === t("pay") && paymentType === 2
                  ? "Reservar"
                  : getAction()}
                <ChevronRightIcon />
              </Button>
            </span>
          </Grid>
        </Grid>
      </ListItem>
    </Box>
  );
};

export default StepperFooter;
