import ReservationBanner from 'assets/img/vmove/about-us-page/banner_reservar.jpg'
import { contentWrapper, orangeColor, dullOrangeColor, whiteColor } from 'assets/jss/style.js'
import { mt05r, mt1r, mt2r, mb1r, latoBold, latoRegular, fs16, fs18, fs20, fs24, fs25, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const aboutUsPage = {
  boxContentWrapper: {
    backgroundImage: `url(${ReservationBanner})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'calc(100vh - 120px)',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  bgWhite: {
    background: `${whiteColor} !important`,
  },
  imgFluid: {
    width: '100%',
    height: '100%',
  },
  imageMobile: {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: '550px 70px',
  },
  containerDetailInfo: {
    position: 'relative',
    background: whiteColor,
    bottom: '20vh',
  },
  containerDetailInfoMobile: {
    position: 'relative',
    borderTopLeftRadius: '60px 35px',
    background: whiteColor,
    bottom: '4vh',
  },
  boxTitle: {
    padding: '1.2rem 0',
  },
  labelTitle: {
    color: dullOrangeColor,
    fontFamily: latoBold,
    textTransform: 'initial',
  },
  customDivider: {
    height: '3px',
    background: dullOrangeColor,
  },
  gridContainerBody: {
    padding: '3rem 2rem',
  },
  gridContainerBodyMobile: {
    padding: '2rem 1rem',
  },
  labelSubtitle: {
    fontFamily: latoBold,
    color: '#202124',
    textTransform: 'initial',
  },
  labelBody: {
    // marginLeft: "0.5rem",
    // textIndent: "0.5rem",
    color: '#727C8E',
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
  },
  labelHeader: {
    fontFamily: latoBold,
    textTransform: 'initial',
    color: orangeColor,
  },
  boxMedia: {
    height: '50vh',
    width: '100%',
  },
  boxYoutube: {
    width: '100%',
    height: '50vh',
    paddingBottom: '2rem',
  },
  itemFlex: {
    display: 'flex',
  },
  linkFaq: {
    margin: '1.8rem 0',
    textDecoration: 'underline',
    fontFamily: latoRegular,
    color: dullOrangeColor,
    fontSize: '20px',
    '&:hover, &:visited': {
      color: dullOrangeColor,
      textDecoration: 'underline',
    },
  },
  contentWrapper,
  mt05r,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs32,
  fs50,
  fs60,
}

export default aboutUsPage
