import { whiteColor } from 'assets/jss/style.js'
import { latoRegular, latoBold, m_0, fs12, fs14, fs16, fs20, fs30, lh_0 } from 'assets/jss/tools.js'

const eventStyle = {
  buttonBuy: {
    border: '1px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    fontFamily: latoRegular,
    textTransform: 'initial',
    width: '100%',
  },
  spanPrice: {
    fontFamily: latoBold,
    marginLeft: '0.5rem',
  },
  buttonOverlay: {
    border: '2px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    textTransform: 'initial',
    width: '100%',
  },
  normalEventImage: {
    maxWidth: '100%'
  },
  m_0,
  fs12,
  fs14,
  fs16,  
  fs20,
  fs30,
  lh_0,
}

export default eventStyle
