import React, { useEffect } from 'react'

const Webpay = (props) => {
  const { paymentResponse } = props
  const { webpay_token, webpay_url } = paymentResponse

  useEffect(() => {
    if (webpay_token !== '') {
      document.getElementById('formPayment').submit()
    }
  }, [webpay_token])

  return (
    <div>
      <form action={webpay_url} method='post' id='formPayment'>
        <input type='hidden' name='token_ws' value={webpay_token} />
      </form>
    </div>
  )
}

export default Webpay
