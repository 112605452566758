import React, { useState } from "react";
import styles from "assets/jss/views/eventPageSections/paymentTabStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Input from "components/Tools/Input";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import { CouponSchema } from "utils/validations";
import { getCoupon } from "provider/privateProvider/provider";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);

const DiscountCoupon = ({ handleClose, eventId, setDiscount, tickets }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  const handleNotification = (message, variant) => {
    enqueueSnackbar(message, {
      action,
      autoHideDuration: 5000,
      variant: variant,
    });
  };

  const handleSubmit = (values) => {
    setSending(true);
    getCoupon(values.discount, eventId)
      .then((response) => {
        setDiscount([]);
        if (response.message) {
          handleNotification(response.message, "error");
        } else {
          setDiscount(response);
          response.productVariants.map((discount, indexDiscount) => {
            tickets.map((ticket, indexTicket) => {
              if (ticket.productVariantId === discount.product_variant_id) {
                tickets[indexTicket]["discountPrice"] = Number(
                  discount.discount_price
                );
              }
            });
          });
          handleNotification("Cupón agregado con éxito", "success");
          handleClose();
        }
      })
      .catch((error) => handleNotification("Ocurrió un error", "error"))
      .finally((res) => setSending(false));
  };

  const action = (key) => {
    return (
      <>
        <Button
          className={classes.dismissNotification}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </>
    );
  };

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.closeModal}>
              <CloseIcon onClick={() => handleClose()} fontSize="small" />
            </Grid>
          </Grid>
          <Formik
            initialValues={{ discount: "" }}
            onSubmit={handleSubmit}
            validationSchema={CouponSchema}
          >
            <Form>
              <Typography className={classes.titleCoupon}>
                Ingrésa tu código de descuento
              </Typography>
              <Input label="Código de descuento / referido" name="discount" />
              <div className={classes.itemFullCenter}>
                <Button className={classes.buttonCoupon} disabled={sending} type="submit">
                  Usar Código
                  {sending && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default DiscountCoupon;
