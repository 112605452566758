import React, { useContext, useRef, useEffect } from "react";
import Context from "../../context/Context";
import styles from "assets/jss/views/vmoveCompaniesPage.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import AboutUs from "assets/img/vmove/about-us-page/about-us.png";
import Van from "assets/img/vmove/icons/van_b2b.png";
import Leaf from "assets/img/vmove/icons/leaf_b2b.png";
import World from "assets/img/vmove/icons/world.png";
import ReactPlayer from "react-player";
import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function VmoveCompaniesPage(props) {
  const divRef = useRef(null);
  const contextType = useContext(Context);
  const { t } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const customSpacing = isMobile ? 0 : 4;

  useEffect(() => {
    const heightToScroll = divRef.current.offsetTop - 115;
    window.scrollTo({
      top: heightToScroll,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box
      className={isMobile ? classes.bgWhite : null}
      classes={{ root: classes.contentWrapper }}
    >
      {isMobile ? (
        <Box>
          <img src={AboutUs} alt="About Us" className={classes.imageMobile} />
        </Box>
      ) : (
        <Box className={classes.boxContentWrapper}></Box>
      )}
      <Container
        ref={divRef}
        className={
          isMobile
            ? classes.containerDetailInfoMobile
            : classes.containerDetailInfo
        }
      >
        <Box
          classes={{ root: classes.boxTitle }}
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <Typography
            align="center"
            classes={{ root: classes.labelTitle }}
            className={isMobile ? classes.fs25 : classes.fs50}
          >
            {t("vmove_b2b")}
          </Typography>
        </Box>
        <Grid
          container
          spacing={customSpacing}
          className={
            isMobile
              ? classes.gridContainerBodyMobile
              : classes.gridContainerBody
          }
        >
          <Grid item xs={0} md={2}>
          </Grid>
          <Grid item xs={12} md={8} className={isMobile ? classes.mt2r : null} >
            <Typography
              classes={{ root: classes.labelHeader }}
              className={
                (isMobile ? classes.fs20 : classes.fs24) + " " + classes.mt2r
              }
              style={{marginTop: '6px'}}
            >
              {t("vmove_companies")}
            </Typography>
            <Typography
              className={
                classes.labelBody + " " + classes.fs16 + " " + classes.mt1r
              }
              style={{marginTop: '21px'}}
            >
              {t("vmove_companies_right_1")}
            </Typography>
            <Box classes={{ root: classes.boxMedia }} className={classes.mt2r}>
              <ReactPlayer
                url={"https://youtu.be/gOl3SfN4i3Y"}
                className={classes.boxYoutube}
                playing
                width="100%"
                volume={0.2}
                controls
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              spacing={customSpacing}
              className={
                isMobile
                  ? classes.gridContainerBodyMobile
                  : classes.gridContainerBody
              }
            >     
              <Grid item xs={12} md={4} className={isMobile ? classes.gridLowContainerBodyMobile : null}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                >
                  <Grid container justify="center" md={12} xs={12}>
                    <img src={Leaf} alt="Leaf" className={classes.imageLeaf} />
                    <img src={Van} alt="Money" className={classes.imageVan} />
                  </Grid>
                </Box>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  className={classes.mt1r}
                >
                  <Typography
                    classes={{ root: classes.labelBody }}
                    className={isMobile ? classes.labelBodyMobile : null}
                    style={{ textAlign: "center" }}
                  >
                    Optimizamos la ruta de nuestros clientes, generando importantes
                    ahorros para ellos y cuidando el medio ambiente.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} className={isMobile ? classes.gridLowContainerBodyMobile : null}>
                <Typography
                  classes={{ root: classes.labelSubtitle }}
                  className={isMobile ? classes.fs25 : classes.fs25}
                  style={{ textAlign: "center" }}
                >
                  {t("vmove_b2b")}
                </Typography>

                <Typography
                  classes={{ root: classes.labelBody }}
                  className={
                    classes.labelBody + " " + classes.fs16 + " " + classes.mt1r
                  }
                  style={{ textAlign: "center" }}
                >
                  Somos la primera plataforma de traslado personal de empresas,
                  desde tu casa te llevamos al trabajo y del trabajo a la casa.
                </Typography>
                <Typography
                  classes={{ root: classes.labelBody }}
                  className={
                    classes.labelBody + " " + classes.fs16 + " " + classes.mt1r
                  }
                  style={{ textAlign: "center" }}
                >
                  También nos puedes contactar para congresos, fiestas de fin de año
                  y reuniones corporativas.
                </Typography>
              </Grid>       
              <Grid item xs={12} md={4} className={isMobile ? classes.gridLowContainerBodyMobile : null}>

                <Grid container xs={12} md={12} style={{ textAlign: "center", paddingTop: '2%' }}>
                  
                  <Grid container justify="center" md={12} xs={12}>                    
                    <img src={World} alt="Money" className={classes.imageWorld} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.labelSubtitleLink}
                    onClick={() =>
                      (window.location.href = process.env.REACT_APP_TRANSLATE_B2B_PAGE)
                    }
                  >
                    <Typography
                      classes={{ root: classes.labelSubtitle }}
                      className={
                        (isMobile ? classes.fs16 : classes.fs20) +
                        " " +
                        classes.mt1r
                      }
                      style={{ fontSize: "16px", textAlign: "center", textDecorationLine: 'underline'}}
                    >
                      Conoce más aquí
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} md={12} style={{ textAlign: "center" }}>
                  <Grid item xs={12} md={12} classes={{ root: classes.labelBody }} style={{paddingTop:'1%'}}>
                      Para más información contáctanos:
                  </Grid>
                  <Grid item xs={12} md={12} classes={{ root: classes.labelSubtitle }}>
                      {process.env.REACT_APP_TRANSLATE_B2B_EMAIL}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>          
        </Grid>
      </Container>
    </Box>
  );
}
