import { whiteColor } from 'assets/jss/style.js'
import { latoRegular, latoBold, m_0, fs12, fs14, fs16, fs20, fs30, lh_0 } from 'assets/jss/tools.js'

const eventCardsStyle = {
  cardSize: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  cardSizeFive: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: 5,
  },
  eventCard: {
    width: '100%',
    height: '320px',
    maxHeight: '320px',
    '&:hover .boxInfo': {
      opacity: 0,
    },
    '&:hover .boxInfoText': {
      opacity: 0,
    },
    '&:hover .buttonOverlay': {
      opacity: 1,
    },
    '&:hover .boxOverlay': {
      opacity: 1,
    },
  },
  buttonBuy: {
    border: '1px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    fontFamily: latoRegular,
    textTransform: 'initial',
    width: '100%',
  },
  spanPrice: {
    fontFamily: latoBold,
    marginLeft: '0.5rem',
  },
  buttonOverlay: {
    border: '2px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    textTransform: 'initial',
    width: '100%',
  },
  normalEventImage: {
    maxWidth: '100%',
    height: 300,
    width: '100%',
  },
  eventImage: {
    maxWidth: '230px',
    height: 300,
    width: '230px',
  },
  boxSize: {
    width: 500,
  },
  m_0,
  fs12,
  fs14,
  fs16,
  fs20,
  fs30,
  lh_0,
}

export default eventCardsStyle
