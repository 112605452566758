import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styles from 'assets/jss/views/contactPage.js'
import { makeStyles } from '@material-ui/core/styles'
import CustomMap from './CustomMap'
import WhatsApp from 'assets/img/vmove/icons/whatsapp.svg'
import ChileIcon from 'assets/img/vmove/flags/chile_icon.png'
import PeruIcon from 'assets/img/vmove/flags/peru_icon.png'
import SpainIcon from 'assets/img/vmove/flags/spain_icon.png'
import SmallPhone from 'assets/img/vmove/icons/small-phone.svg'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { ContactSchema } from 'utils/validations'
import { postContact } from 'provider/publicProvider/provider'
import { useMediaQuery } from 'react-responsive'
import Context from 'context/Context'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core'

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: '',
}

const map = <CustomMap />
const useStyles = makeStyles(styles)

const ContactPage = () => {
  const [boolSendMessage, setBoolSendMessage] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const contextType = useContext(Context)
  const { getCountry } = contextType
  const handleSubmit = (values) => {
    postContact(getCountry(), values).then((res) => {
      setBoolSendMessage(true)
    })
  }
  return (
    <Box classes={{ root: classes.contentWrapper }}>
      <Container>
        <Box classes={{ root: classes.boxContentContact }}>
          <Typography className={classes.titleContact + ' ' + classes.fs50}>Contáctanos</Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            className={classes.root}
            validationSchema={ContactSchema}
            autoComplete='off'
          >
            {({ dirty, isValid }) => {
              return (
                <Form>
                  <Grid container className={classes.containerFormContact} spacing={5}>
                    <Grid item xs={12} md={6} className={classes.itemDirectionColumn}>
                      <FormControl className={classes.customFormControl}>
                        <FormLabel
                          classes={{
                            root: classes.customLabelContact,
                            focused: classes.customLabelFocused,
                          }}
                        >
                          Nombre
                        </FormLabel>
                        <Field
                          as={TextField}
                          name='name'
                          placeholder='María José Ec...'
                          classes={{ root: classes.customTextFieldContact }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          helperText={<ErrorMessage name='name' />}
                          disabled={boolSendMessage}
                        />
                      </FormControl>
                      <FormControl className={classes.customFormControl}>
                        <FormLabel
                          classes={{
                            root: classes.customLabelContact,
                            focused: classes.customLabelFocused,
                          }}
                        >
                          Correo Electronico
                        </FormLabel>
                        <Field
                          as={TextField}
                          name='email'
                          placeholder='maria@correo'
                          classes={{ root: classes.customTextFieldContact }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          helperText={<ErrorMessage name='email' />}
                          disabled={boolSendMessage}
                        />
                      </FormControl>
                      <FormControl className={classes.customFormControl}>
                        <FormLabel
                          classes={{
                            root: classes.customLabelContact,
                            focused: classes.customLabelFocused,
                          }}
                        >
                          Teléfono
                        </FormLabel>
                        <Field
                          as={TextField}
                          name='phone'
                          placeholder='+569 58962335'
                          classes={{ root: classes.customTextFieldContact }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          helperText={<ErrorMessage name='phone' />}
                          disabled={boolSendMessage}
                        />
                      </FormControl>
                      <FormControl className={classes.customFormControl}>
                        <FormLabel
                          classes={{
                            root: classes.customLabelContact,
                            focused: classes.customLabelFocused,
                          }}
                        >
                          Mensaje
                        </FormLabel>
                        <Field
                          as={TextField}
                          name='message'
                          multiline
                          rows={7}
                          rowsMax={8}
                          classes={{ root: classes.customTextAreaFieldContact }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          helperText={<ErrorMessage name='message' />}
                          disabled={boolSendMessage}
                        />
                      </FormControl>
                      <Grid item xs={12} md={12} className={classes.itemFullCenter}>
                      {!boolSendMessage ? (
                        <Button
                          classes={{ root: classes.buttonSend }}
                          type='submit'
                          variant='contained'
                          disabled={!dirty || !isValid}
                        >
                          Enviar Mensaje
                        </Button>
                      ) : (
                        <Typography
                          classes={{ root: classes.labelBody }}
                          className={
                            isMobile
                              ? `${classes.fs16} ${classes.mt05r}`
                              : `${classes.fs20} ${classes.mt05r}`
                          }
                        >
                          Muchas gracias! Te contactaremos a la brevedad
                        </Typography>
                      )}
                    </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {map}
                      <Box className={classes.boxCountryContact}>
                        <Grid container className={classes.containerCountryContact} spacing={5}>
                          <Grid item xs={11} md={11}>
                            <img alt='Chile' className={classes.iconFlag} src={ChileIcon} />
                            <Typography className={classes.labelPhone + ' ' + classes.fs16}>
                              +56 998581278 &nbsp; +56 229299798
                            </Typography>
                            <Typography className={classes.labelAddress + ' ' + classes.fs16}>
                              Santa Beatriz 100 - Providencia, Oficinas 901,902,903
                            </Typography>
                          </Grid>
                        </Grid>  
                        <Grid container className={classes.containerCountryContact} spacing={5}>
                          <Grid item xs={11} md={11}>
                            <img alt='Chile' className={classes.iconFlag} src={PeruIcon} />
                            <Typography className={classes.labelPhone + ' ' + classes.fs16}>
                              +51 964 084 817
                            </Typography>
                            <Typography className={classes.labelAddress + ' ' + classes.fs16}>
                              Av. Sta. Cruz 875, Miraflores, Perú
                            </Typography>
                          </Grid>
                        </Grid>  
                        <Grid container className={classes.containerCountryContact} spacing={5}>
                          <Grid item xs={11} md={11}>
                            <img alt='Chile' className={classes.iconFlag} src={SpainIcon} />
                            <Typography className={classes.labelPhone + ' ' + classes.fs16}>
                              +34 651960978
                            </Typography>
                            <Typography className={classes.labelAddress + ' ' + classes.fs16}>
                              Calle Cifuentes 5, 28021, Madrid
                            </Typography>
                          </Grid>
                        </Grid>   
                      </Box>
                    </Grid>                    
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Container>
    </Box>
  )
}
ContactPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default ContactPage
