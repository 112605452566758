import React, { Fragment, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import styles from 'assets/jss/views/eventPageSections/reservationTabsStyle.js'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const ResumeData = (props) => {
  const { data } = props
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const contextType = useContext(Context)
  const { getLanguage } = contextType

  return (
    <>
      {data.map((category, index) => {
        return (
          <Fragment key={index}>
            <Grid container className={!isMobile ? classes.containerResumeData : null}>
              <Grid item xs={10} md={10}>
                <Typography
                  classes={{ root: classes.summaryCategorySelected }}
                  className={isMobile ? classes.fs14 : classes.fs16}
                >
                  {category.name[getLanguage()].name}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} classes={{ root: classes.itemFullCenter }}>
                <span className={isMobile ? classes.fs14 : classes.fs16}>x{category.quantity}</span>
              </Grid>
            </Grid>
          </Fragment>
        )
      })}
    </>
  )
}

export default ResumeData
