import React, { useContext } from 'react'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Field, ErrorMessage } from 'formik'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'context/Context'
import { useMediaQuery } from 'react-responsive'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

const InputControl = (props) => {
  const { name, label, data } = props
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <FormControl
      classes={{
        root: isMobile ? classes.customTextFieldSelectMobile : classes.customTextFieldSelect,
      }}
    >
      <Field
        autocomplete='off'
        as={TextField}
        label={label}
        select
        name={`${name}`}
        helperText={<ErrorMessage name={name} render={(msg) => <Typography className={classes.error}>{t(msg)}</Typography>} />}
        variant='outlined'
      >
        {data.map((element) => (
          <MenuItem value={element}>{element}</MenuItem>
        ))}
      </Field>
    </FormControl>
  )
}

export default InputControl
