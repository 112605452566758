import { contentWrapper } from 'assets/jss/style.js'
import { orangeColor } from '../style'
import { latoBold, latoRegular } from '../tools'

const successScanner = {
  container: {
    margin: '50px 0',
  },
  textTitle: {
    textAlign: 'center'
  },
  textData: {
    textAlign: 'left',
    fontFamily: latoBold,
  },
  boxCircularProgress: {
    height: "400px",
  },
  head: {
    textAlign: 'center',
    fontFamily: latoRegular,
    fontWeight: 600,
    width: '400px',
    marginBottom: 30,
    fontSize: 18,
  },
  yarealizado: {
    background: '#f2a3a3',
    padding: '14px',
    borderRadius: '10px',
  },
  validado: {
    background: '#ffcaad',
    padding: '14px',
    borderRadius: '10px',
  },
  body: {
    textAlign: 'center',
    fontFamily: latoRegular,
    fontWeight: 400,
    width: '400px',
    marginBottom: 30,
    fontSize: 16,
  },
  return: {
    textAlign: 'center',
    color: orangeColor,
    fontFamily: latoRegular,
    fontWeight: 400,
    width: '400px',
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentWrapper,
}

export default successScanner
