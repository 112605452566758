import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Context from 'context/Context'
import styles from 'assets/jss/views/orderDetailPage.js'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(styles)

const DetailOrder = (props) => {
  const contextType = useContext(Context)
  const { date, paymentState, total } = props.order
  const { translations } = props.event
  const { getLanguage, t } = contextType
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.pt12}>
          <img alt={'Event'} src={translations[getLanguage()].imagePath} width='100%' height='auto' />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.pt0}>
          <Typography className={classes.subTitleName}>{`${translations[getLanguage()].name}`}</Typography>
          <Typography className={classes.body}>{`${translations[getLanguage()].description}`}</Typography>
          <Typography variant='body2' className={classes.detailStrong}>
            <strong>{`${t('payment_state')}:`}</strong> {t(paymentState)}
          </Typography>
          <Typography variant='body2' className={classes.detailStrong}>
            <strong>{`${t('purchase_date')}:`}</strong> {date}
          </Typography>
          <Typography variant='body2' className={classes.detailStrong}>
            <strong>{`${t('total')}: `}</strong>
            <NumberFormat
              value={total}
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={process.env.REACT_APP_MONEY_SIGN}
              renderText={(value) => value}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default DetailOrder
