import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/readerQR";
import { Box, Button, Grid, Snackbar } from "@material-ui/core";
import QrReader from "react-qr-reader";
import { postCheckQr } from "provider/publicProvider/provider";
import { Alert } from "@material-ui/lab";
import SuccessSCanner from "./SuccessScanner";

const useStyles = makeStyles(styles);

const ReaderQR = () => {
  const [response, setResponse] = useState("No result");
  const [correctQr, setCorrectQr] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const handleScan = (data) => {
    if (data) {
      setResponse(JSON.parse(data));
    }
  };

  const handleSubmit = () => {
    if (response.orderId !== undefined) {
      postCheckQr(response.orderId)
        .then((res) => {
          setResponse(res);
          setCorrectQr(true);
        })
        .catch((err) => setError(true));
    } else {
      setError(true);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleReturn = () => {
    setResponse("No result");
    setCorrectQr(false);
  };

  return (
    <Box className={classes.contentWrapper}>
      <Grid justify="center" alignItems="center" container>
        <Grid className={classes.container}>
          {correctQr ? (
            <SuccessSCanner data={response} handleReturn={handleReturn} />
          ) : (
            <>
              <div className={classes.messageFormat}>
                Escanear código QR que se encuentra en el ticket de viaje:
              </div>
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "300px" }}
              />
              <div className={classes.fullItemCenter}>
                <Button
                  className={classes.scannerButton}
                  onClick={() => handleSubmit()}
                >
                  Escanear Código
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={error}
        onClose={() => setError(false)}
        autoHideDuration={4000}
      >
        <Alert severity="error">EVENTO INVALIDO</Alert>
      </Snackbar>
    </Box>
  );
};

export default ReaderQR;
