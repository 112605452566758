import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { purchaseListStyle } from '../../assets/jss/components/purchaseHistoryList'
import { getTicketDetail } from 'provider/privateProvider/provider'
import Order from 'components/Order/'

const useStyles = makeStyles(purchaseListStyle)

export const TicketDetail = (props) => {
  const [ticketDetail, setTicketDetail] = useState({})
  const [detailState, setDetailState] = useState('')

  const classes = useStyles()
  const { orderId } = props

  useEffect(() => {
    getTicketDetail(orderId)
      .then((response) => {
        setTicketDetail(response)
        setDetailState('Success')
      })
      .catch((error) => {
        setDetailState('Error')
      })
  }, [orderId])

  return <div className={classes.container}>{detailState === 'Success' ? <Order detail={ticketDetail} /> : null}</div>
}
