import React from 'react'
import styles from 'assets/jss/views/eventPageSections/paymentTabStyle.js'
import PaymentPlus from 'assets/img/vmove/payment/mercado-pago.jpeg'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

const MercadopagoButton = (props) => {
  const { paymentMethod, handlePaymentMethod } = props
  const classes = useStyles()

  return (
    <Button
      onClick={handlePaymentMethod('mercadopago')}
      className={paymentMethod === 'mercadopago' ? classes.buttonPaymentActive : classes.buttonPayment}
    >
      <img alt='PaymentPlus' src={PaymentPlus} className={classes.imagePayment} />
    </Button>
  )
}

export default MercadopagoButton
