import { orangeColor, whiteColor } from "../style";
import { latoBold, latoRegular } from "assets/jss/tools.js";

const activePrivateEventsStyles = {
  createEventButton: {
    color: whiteColor,
    padding: "10px 50px",
    backgroundColor: orangeColor,
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: whiteColor,
      backgroundColor: orangeColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  createEventButtonMobile: {
    color: whiteColor,
    padding: "10px 100px",
    backgroundColor: orangeColor,
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: whiteColor,
      backgroundColor: orangeColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  titlePosition: {
    textAlign: "right",
    //marginRight: "10px",
  },
  titlePositionMobile: {
    paddingTop: "4%",
    textAlign: "center"
  },
  titlePositionButton: {
    textAlign: "left",
    //marginLeft: "10px",
  },
  titlePositionButtonMobile: {
    paddingTop: "4%",
    textAlign: "center"
  },
  divContainer: {
    paddingTop: "10px",
    width: "70%",
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxCircularProgress: {
    height: "400px",
  },
  customProgress: {
    color: `${orangeColor} !important`,
  },
  cancelEventButton: {
    color: orangeColor,
    borderColor: orangeColor,
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "8px 40px",
    backgroundColor: whiteColor,
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: whiteColor,
      backgroundColor: orangeColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  saveDataButton: {
    color: whiteColor,
    borderColor: "#7E7E7E",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: "#CDCDCD",
    padding: "8px 20px",
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: orangeColor,
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  deleteParadeButton: {
    color: orangeColor,
    borderColor: orangeColor,
    fontFamily: latoBold,
    borderWidth: "1px",
    borderStyle: "solid",
    //padding: "5px 5px",
    width: "50px",
    heigh: "100px",
    backgroundColor: whiteColor,
    borderRadius: 100,
    transition: "0.3s",
    textAlign: "center",
    "&:hover": {
      color: whiteColor,
      backgroundColor: orangeColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  customTabIcon: {
    paddingTop: "10 !important",
  },
  downloadXlsxButton: {
    color: "#7E7E7E",
    borderColor: "#7E7E7E",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: whiteColor,
    borderRadius: 5,
    transition: "0.3s",
    textAlign: "center",
    alignItems: "center",
    "&:hover": {
      color: orangeColor,
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  uploadFileButton: {
    color: "#7E7E7E",
    borderColor: "#7E7E7E",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: whiteColor,
    borderRadius: 50,
    transition: "0.3s",
    textAlign: "center",
    alignItems: "center",
    "&:hover": {
      color: orangeColor,
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  titleTab: {
    color: "#7E7E7E",
    fontFamily: latoBold,
    "&:hover": {
      cursor: "pointer",
      color: orangeColor,
      textDecoration: `underline ${orangeColor}`,
    },
  },
  titleTabActive: {
    color: orangeColor,
    fontFamily: latoBold,
    textDecoration: `underline ${orangeColor}`,
  },
  title: {
    fontSize: 20,
    color: "#7E7E7E",
    fontFamily: latoBold,
    textTransform: "uppercase",
    paddingLeft: "25px",
    padding: "3rem 1.5rem",
  },
  titleGrid: {
    textAlign: "left",
    paddingLeft: "70px",
    paddingTop: "25px",
  },
  titleGridMobile: {
    textAlign: "center",
    //paddingLeft: "70px",
    paddingTop: "25px",
  },
  titleForm: {
    color: "#7E7E7E",
    fontFamily: latoBold,
    padding: "20px 0px",
  },
  formContainer: {
    padding: "18px 70px",
  },
  contentTab: {
    paddingTop: "30px",    
    paddingBottom: "20px"
  },
  AI: {
    alignItems: "center",
  },
  field: {
    padding: "10px 10px",
  },
  timeAndDate: {
    padding: "10px 10px",
    "&.datepicker-input::-webkit-calendar-picker-indicator": {
      color: orangeColor,
      backgroundColor: whiteColor,
    },
  },
  marginCancelButton: {
    padding: "20px 0px 0px 150px",
  },
  marginCancelButtonMobile: {
    padding: "20px",
  },
  addParadeText: {
    color: orangeColor,
    cursor: "pointer",
    fontFamily: latoBold,
  },
  boxCircularProgress: {
    display: "flex",
    justifyContent: "center",
    height: "600px",
  },
};

export default activePrivateEventsStyles;
