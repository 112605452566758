import React, { useContext } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/views/massiveEventsPage.js'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const ErrorPage = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Box classes={{ root: classes.contentWrapper }}>
      <Grid className={classes.gridContainer} spacing={2}>
        <Grid item xs={12} md={12} className={classes.itemFullCenter}>
          <h1>404</h1>
        </Grid>

        <Grid item xs={12} md={12} className={classes.itemFullCenter}>
          <h2>Página no disponible</h2>
        </Grid>

        <Grid item xs={12} md={12}>
          <p>Lo sentimos, la página no se encuentra disponible temporalmente, podría haber sido eliminada o su nombre ha sido cambiado.</p>
        </Grid>

        <Grid item xs={12} md={12}>
          <p>Por favor, regresar a la Página de Inicio.</p>
        </Grid>

        <Grid item xs={12} md={12} className={classes.itemFullCenter}>
          <Link to='/'>
            <Button classes={{ root: classes.buttonBack }} variant='contained' className={isMobile ? classes.fs16 : classes.fs18}>
              {t('back_to_main_screen')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ErrorPage
