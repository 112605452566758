import { whiteColor, grayColor } from "../style";
import { latoBold } from "assets/jss/tools";

const frequentAddressPage = {
  root: {
    flexGrow: 1,
  },
  contentWrapper: {
    padding: "4% 10%",
    color: grayColor,
    fontFamily: latoBold,
    //container: {
    //padding: 20,
    maxHeight: 550,
    overflow: "auto",
    overflowX: "hidden",
    //},
  },
  contentTab: {
    color: grayColor,
    fontFamily: latoBold,
    //textAlign: 'center',
  },
  tar: {
    textAlign: "right",
  },
  tac: {
    textAlign: "center",
  },
  unregisteredAddresses: {
    paddingBottom: "3%",
    textAlign: "center",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "20px",
  },
  formTitle: {
    color: "#6CA4DF",
    fontSize: "20px",
    fontFamily: latoBold,
  },
  addAddressButtonGrid: {
    paddingTop: "3%",
    textAlign: "center",
  },
  addressListBox: {
    paddingTop: "5%",
  },
  customIcon: {
    color: "#6CA4DF",
  },
  addressFormBox: {
    paddingTop: "5%",
    paddingBottom: "2%",
  },
  addressName: {
    textTransform: "capitalize",
  },
  divider: {
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  addAddressButton: {
    color: whiteColor,
    borderColor: "#848484",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "8px 40px",
    backgroundColor: "#6CA4DF",
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: "#6CA4DF",
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  selectedAddressButton: {
    color: whiteColor,
    borderColor: "#848484",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "6px 20px",
    backgroundColor: "#6CA4DF",
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: "#6CA4DF",
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  selectAddressButton: {
    color: "#6CA4DF",
    borderColor: "#848484",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "6px 20px",
    backgroundColor: whiteColor,
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: whiteColor,
      backgroundColor: "#6CA4DF",
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  boxCircularProgress: {
    display: "flex",
    justifyContent: "center",
    height: "500px",
    alignItems: "center",
    paddingTop: "40%",
    paddingLeft: "50%",
  },
};
export default frequentAddressPage;
