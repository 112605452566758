import React, { useContext, Fragment } from 'react'
import Context from '../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import House from 'assets/img/vmove/icons/house.svg'
import Spotlights from 'assets/img/vmove/icons/spotlights.svg'
import Bus from 'assets/img/vmove/icons/bus-side.svg'
import { useMediaQuery } from 'react-responsive'
import styles from 'assets/jss/views/eventPageSections/detailStyle.js'

const useStyles = makeStyles(styles)

export default function ServiceIncludes(props) {
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  return (
    <Fragment>
      <Typography
        align='center'
        classes={{ root: classes.serviceTitle }}
        className={isMobile ? classes.fs20 : classes.fs25}
      >
        {t('our_service_includes')}
      </Typography>
      <Grid
        container
        display='flex'
        // spacing="5"
        alignItems='center'
        className={classes.containerServicesInclude}
      >
        <Grid
          item
          xs={12}
          md={4}
          classes={{ root: classes.itemFullColumnCenter }}
          className={isMobile ? classes.m1r : null}
        >
          <img src={Bus} alt='Bus' className={classes.vanImage} />
          <Typography
            classes={{ root: classes.textBodyServices }}
            className={isMobile ? classes.fs16 : classes.fs18}
          >
            {t('through_starting_point')}.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          classes={{ root: classes.itemFullColumnCenter }}
          className={isMobile ? classes.m1r : null}
        >
          <img src={Spotlights} alt='Spotlights' />
          <Typography
            classes={{ root: classes.textBodyServices }}
            className={isMobile ? classes.fs16 : classes.fs18}
          >
            {t('take_your_event')}.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          classes={{ root: classes.itemFullColumnCenter }}
          className={isMobile ? classes.m1r : null}
        >
          <img src={House} alt='House' />
          <Typography
            classes={{ root: classes.textBodyServices }}
            className={isMobile ? classes.fs16 : classes.fs18}
          >
            {t('when_done_take_you_back')}.
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}
