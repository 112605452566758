import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

import Context from "../../context/Context";
import styles from "assets/jss/views/massiveEventsPage.js";
import EventCards from "../../components/EventCards/EventCards";

import Star from "assets/img/vmove/icons/star.svg";

import { getEventsByType } from "provider/publicProvider/provider";

const useStyles = makeStyles(styles);

export default function MassiveEventsPage(props) {
  const [eventList, setEventList] = useState([]);
  const contextType = useContext(Context);
  const { t, getCountry, getLanguage } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const customJustify = isMobile ? "center" : "initial";
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getEventList = () => {
      getEventsByType(getCountry(), 1)
        .then((res) => {
          setEventList(res);
        })
        .finally(() => {
          setLoader(false);
        });
    };
    getEventList();
  }, [getCountry(), getLanguage()]);

  return (
    <Box
      className={
        isMobile ? classes.contentWrapperMobile : classes.contentWrapper
      }
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={customJustify}
          classes={{ root: classes.boxHeaderEvents }}
        >
          <img
            alt="Star"
            src={Star}
            className={isMobile ? classes.iconMobile : null}
          />
          <Typography
            classes={{ root: classes.titleEvent }}
            className={isMobile ? classes.fs16 : classes.fs30}
          >
            {t("transfer_to_mass_events")}
          </Typography>
        </Box>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          className={classes.gridContainerList}
          justify="center"
          spacing={2}
        >
          {loader ? (
            <Grid
              item
              xs={12}
              md={12}
              classes={{ root: classes.itemFullCenter }}
              className={classes.boxCircularProgress}
            >
              <CircularProgress classes={{ root: classes.customProgress }} />
            </Grid>
          ) : eventList.length > 0 ? (
            eventList.map((event) => {
              return (
                <EventCards key={event.id} event={event} bgColor="#8e98a7" />
              );
            })
          ) : (
            <Typography
              classes={{ root: classes.titleEvent }}
              className={isMobile ? classes.fs16 : classes.fs20}
            >
              {"Sin eventos para mostrar"}
            </Typography>
          )}
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Link to="/">
              <Button
                classes={{ root: classes.buttonBack }}
                variant="contained"
                className={isMobile ? classes.fs16 : classes.fs18}
              >
                {t("back_to_main_screen")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
