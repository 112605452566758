const language = {
  about_us: "Quem Somos",
  about_us_left_1:
    "Criamos soluções tecnológicas para transferências compartilhadas com presença na Espanha, Chile e Peru.",
  about_us_left_2:
    "Nosso compromisso não é apenas fornecer o melhor serviço aos nossos usuários, mas também ser uma contribuição para o mundo de hoje, ajudando a reduzir o descongestionamento dos veículos e reduzindo as emissões de CO2 que são prejudiciais ao meio ambiente.",
  about_us_right_1:
    "Somos a primeira bilheteira para transferências entre o seu evento e a sua casa. Contamos com uma plataforma web, onde você encontrará Eventos Privados, Mass Events e traslados personalizados, oferecendo a melhor experiência em cada viagem. Cuidamos de cada detalhe e pensamos em você como nossa prioridade, para que se sinta confortável e seguro antes do seu evento e também depois, ao chegar em casa.",
  vmove_companies_right_1:
    "Somos uma plataforma profissional de traslados corporativos, que alia o melhor do atendimento personalizado com nosso software e APP. Ajudando a otimizar rotas, gerando economia de até 35%, reduzindo congestionamentos em nossas cidades e emissões de CO2. portanto, a maior rede de VANs do Chile, com presença na Espanha, Peru e Chile.",
    vmove_b2b: 'vmove b2b',
account_id: "Numero de conta",
  account_type: "Tipo de conta",
  active_events: "Activos",
  add_more: "Adicione Mais",
  add_stop: "Adicionar Parada",
  add_frequent_friend: "Adicionar amigo frequente",
  add_friend: "Adicionar Amigo",
  add: "Adicionar",
  address_input: "Endereço",
  address_is_required: "Endereço é necessário",
  address_single: "Direção",
  address:
    "Endereço: Av. San José Maria Escrivá de Balaguer 13105, escritório 1109, Lo Barnechea.",
  addresses: "Direcções",
  all_reservations_include:
    "Todas as reservas incluem um bilhete de ida e volta",
  alliances: "Alianças",
  already_a_user: "Você já é um usuário",
  assign_to_my_address: "Atribuir como meu endereço",
  available_locations: "Localizações disponíveis",
  awaiting_payment: "Pendente",
  awaiting_transfer_payment: "Pendente",
  back_to_main_screen: "Voltar à tela principal",
  bank: "Banco",
  benefits: "Benefícios",
  birthday: "Aniversário",
  book_our_services:
    "JUNTE-SE À NOSSA COMUNIDADE, ALGUNS DOS CLIENTES QUE ESTÃO MUDANDO HOJE",
  boyfriends: "Namorados",
  bride_and_groom_name: "Nome da noiva e do noivo",
  build_your_route: "Construa sua rota",
  buy_your_transfer: "Compre sua transferência de",
  by: "por",
  cancelled: "Cancelado",
  category: "Categoria",
  change_city: "Mudar de Cidade",
  change_date: "Alterar Data",
  choose_the_activity: "Escolha a atividade",
  clicking_register_termins: "Ao clicar em registrar, você aceita o",
  commune: "Commune",
  confirm_your_purchase: "Confirme sua compra",
  congratulations: "¡Felicitação!",
  contact: "Contact",
  continue: "Continuar",
  copy_right: "Feito por vmove.br Todos os direitos reservados",
  corporate_events: "Eventos Corporativos",
  countie_is_required: "A comuna é necessária",
  create_address: "Criar Endereço",
  create_event: "Crear Evento",
  create_event_success: "Solicitação criada com sucesso",
  create_event_failed: "Solicitação de criação falhada",
  create_private_event_title: "Crie seu evento privado",
  create_private_event_name: "Nome do evento",
  create_private_event_name_form: "Aniversário de Maria",
  create_private_event_date: "Data e hora de início e término",
  create_private_event_date_start: "Data de início",
  create_private_event_date_start_hour: "Hora de início",
  create_private_event_date_end_hour: "Hora de término",
  create_private_event_place: "Localização do evento",
  create_private_event_place_name: "Nome do local do evento",
  create_private_event_place_name_form: "Jardim de ...",
  create_private_event_address: "Endereço do local",
  create_private_event_add_stop_parade: "Adicionar parada",
  create_private_event_add_stop_parade_address: "Endereço",
  create_private_event_add_stop_parade_county: "Commune",
  create_private_event_add_stop_parade_hour: "Tempo",
  create_private_event_image: "Imagem principal do evento",
  create_private_event_image_search: "Pesquisar Arquivo",
  create_private_event_image_no_selected: "Nenhum arquivo selecionado",
  create_private_event_invitation: "Texto do convite do evento",
  create_private_event_invitation_text: "Escreva uma mensagem de convite",
  create_private_event_invitation_text_form: "Convido você a ...",
  create_private_event_guest_list: "Lista de convidados",  
  create_private_event_guest_list_description: 'Debes subir tu lista en el archivo xls disponible para descargar. Puedes hacerlo ahora o después.',
  create_private_event_guest_list_format_file: "arquivo xlsx",
  create_private_event_guest_list_download_file: "Baixar Arquivo",
  create_private_event_guest_list_upload_file: "Carregar Arquivo",
  create_private_event_send_form: "Enviar",
  create_private_event_save_form: "Salvar informações",
  create_private_event_cancel: "Cancelar",
  create_private_event_guest_list_error:
    "Você deve enviar uma lista de convidados.",
  create_private_event_image_error: "Você deve enviar uma imagem do evento",
  current_events: "Eventos Atuais",
  date: "Data",
  detail_of_your_reservation: "Detalhe da sua reserva",
  details: "Detalhes",
  dni: "DNI",
  edit_tickets: "Editar bilhetes",
  edit: "Editar",
  email_is_invalid: "Email invalido",
  email_is_required: "Email é obrigatório",
  email: "Correio eletrônico",
  enter_your_address: "Digite seu endereço",
  error_payment_body: "The payment could not be processed. Please try again",
  error_payment_subitle: "An error occurred in the payment",
  event_approved: "Aprovado",
  event_invited: "Convidado",
  event_under_review: "Em revisão",
  event_saved: "Salvo",
  event_finished: "Finished",
  event_repeat: "Repetir",
  event_history: "Histórico de Eventos",
  event_name: "Nome do evento",
  event_edit: "Editar evento",
  events: "Eventos",
  famous_routes: "Rotas Famosas",
  finish: "Finalizar",
  finished_events: "Acabado",
  forget_password: "Esqueceu sua senha",
  frequent_questions: "Perguntas Freqüentes",
  frequent_friends: "Amigos Freqüentes",
  frequent_addresses: "Endereços Frequentes",
  friends: "Amigos",
  from: "De",
  gender: "Sexo",
  here: "aqui",
  home: "Casa",
  hours: "Horas",
  how_to_book: "Como Reservar",
  in_love: "Apaixonado",
  incorrectly: "Rejeitado",
  incomplete_form:
    "O formulário está incompleto. Por favor, verifique os dados do bilhete para continuar.",
  incomplete_tickets:
    "Pelo menos um bilhete deve ser adicionado para continuar",
  information_to_add_addresses:
    "Você pode adicionar de 1 endereço a um máximo de",
  information_to_add_passenger:
    "Puedes agregar desde 1 passageiro hasta un máximo de",
  invited_events: "Invitado",
  join_our_community: "Participe da nossa comunidade",
  lastname_is_required: "Sobrenome é obrigatório",
  lastname_must_contain_only_letters: "Sobrenome deve conter apenas letras",
  log_in: "Entrar",
  login_error: "O e-mail ou senha que você inseriu está incorreto.",
  login_success: "Login bem sucedido.",
  marriages: "Casamentos",
  massive_and_private_events: "Eventos Maciços e Privados",
  massive_events: "Eventos Maciços",
  maximum: "máximo",
  men_stag_party: "Festa do Veado dos Homens",
  menu: "Menu",
  miscellaneous: "Diversos",
  my_address: "Mi Endereço",
  name_and_surname: "Nome e Sobrenome",
  name_is_required: "Nome é obrigatório",
  name_must_contain_only_letters: " Nome deve conter apenas letras",
  name: "Nome",
  names: "Nomes",
  need_help: "Precisa de ajuda",
  no_avariable: 'Não disponível para esta categoria',
  no_event_created: "Você ainda não criou um evento privado",
  no_events_available: "Não há eventos disponíveis para esta categoria",
  no_tickets_available: "Não há ingressos disponíveis",
  not_have_account: "Não tem uma conta Vmove",
  not_purchases: "Este usuário não tem nenhuma compra associada.",
  number_of_people: "Número de pessoas",
  oops: "Opa!",
  our_service_includes: "Nosso Serviço Inclui",
  our_team: "Nosso Time",
  paid: "Pago",
  passenger_list: "Lista de Passageiros",
  passenger: "Passageiro",
  passengers: "Passageiros",
  password_is_required: "Senha requerida",
  password_must_be_characters: "A senha deve ter 5 ou mais caracteres",
  password: "Senha",
  pay: "Pagar",
  payment_problem_1: "Enviaremos um e-mail de verificação",
  payment_problem_2: "com detalhes de possíveis falhas causadas",
  payment_info: "Seu pagamento foi",
  payment_state: "Estado de pagamento",
  phone_is_invalid: "Telefone é inválido",
  phone_is_required: "Telefone é obrigatório",
  phone_must_only_contain_numbers: "Telefone deve conter apenas números",
  phone_number: "Número de telefone",
  phone: "Telefone celular",
  place: "Lugar",
  please_select_method: "Por favor, selecione seu método de pagamento",
  price: "Preço",
  private_events: "Eventos Privados",
  profile: "Perfil",
  purchase_date: "Data de compra",
  purchase_history: "História de Compras",
  purchase_order: "Pedido de compra",
  recommended_transfer: "Transferência Recomendada",
  recommended_transfers: "Transferências Recomendadas",
  register: "Inscreva-se",
  remove_step: "Remover Etapa",
  remove: "Remover",
  reserve_your_transfer_ticket: "Reserve seu bilhete de transferência",
  reserve_your_transfer: "Reserve sua transferência agora",
  reserved_tickets: "Bilhete reservado",
  return_purchase_history: "Voltar para o histórico de compras",
  routes_vmove: "Rotas Vmove",
  routes: "Rotas",
  rut_run: "RUT / RUN / Passaporte",
  save_changes: "Guardar mudanças",
  saved_addresses: "Endereços salvos",
  search_results: "Resultados da busca",
  search: "Buscar",
  see_available_tickets: "Ver ingressos disponíveis",
  see_more: "Ver Mais",
  see_partners: "Ver parceiros",
  see_video_tutorial: "Veja o tutorial em vídeo",
  select_date: "selecione data",
  select_province: "selecione província",
  select_stop: "Selecionar Parada",
  select_your_address: "Selecione seu endereço",
  select_your_category: "Selecione sua categoria",
  select_your_payment_method: "Selecione sua forma de pagamento",
  share_event: "Compartilhar evento",
  show_event: "Ver evento",
  sign_up_here: "Inscreva-se aqui",
  sign_up: "Cadastre-se",
  start_session: "Entrar",
  state: "Estado",
  street_and_number: "Rua e número",
  street_number_dept: "Rua, número, departamento",
  success_payment_email:
    "Enviamos uma cópia do comprovante de pagamento para seu e-mail.",
  success_payment: "Seu pagamento foi feito com sucesso",
  successfully: "Com sucesso",
  surnames: "Sobrenome",
  take_your_event: "Levamos você ao seu evento",
  tell_us_about_you_marriage: "Contenos sobre seu casamento",
  terms: "Termos",
  they_male: "Eles",
  they_women: "Eles",
  through_starting_point: "Passamos por você até o ponto de partida",
  ticket_detail: "Detalhe do Bilhete",
  ticket: "Ticket",
  to: "Para",
  total: "Total",
  transfer_from: "Transferir de",
  transfer_to_mass_events: "Transferências para Eventos em Massa",
  transfer_to_private_events: "Transferências para Eventos Privados",
  unable_to_upload_your_application:
    "Não é possível fazer upload de seu aplicativo",
  under_review_events: "Em revisión",
  vmove_companies: "Empresas Vmove",
  vmove_b2b: "B2B Vmove",
  vmove_persons: "Vmove Pessoas",
  vmove: "Vmove",
  waiting_for_you: "Estávamos esperando por você",
  we_are: "Nós Somos",
  we_transfer_users: "Hoje transferimos usuários e funcionários de",
  we: "Nós",
  welcome: "Bem Vindo",
  when_done_take_you_back: "Quando terminarmos, nós o levaremos de volta",
  who_travels: "¿Quem viaja?",
  who_we_are: "¿Quem Nós Somos?",
  wire_transfer_description1:
    "Você deve fazer uma transferência bancária para a conta indicada abaixo",
  wire_transfer_description2:
    "Feito o repasse, nossos executivos vão validar o pagamento em menos de 24 horas. Você receberá um e-mail confirmando o recebimento do pagamento.",
  wire_transfer: "Transferência bancária",
  women_stag_party: "Festa do Veado das Vulheres",
  your_events: "Seus Eventos",
  your_home: "Sua Casa",
  you_dont_have_frequent_friends:
    "Você ainda não tem amigos frequentes registrados",
  you_dont_have_frequent_addresses:
    "Você ainda não registrou endereços frequentes",
};

export const PT_BR = () => {
  return language;
};
