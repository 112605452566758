import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/accountPage.js";
import Context from "../../context/Context";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import CircularProgress from "@material-ui/core/CircularProgress";
import PerfilTab from "./AccountTabs/PerfilTab";
import HomeIcon from "@material-ui/icons/Home";
// import EventsTab from './AccountTabs/EventsTab'
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { PurchaseHistory } from "../../components/PurchaseHistory/PurchaseHistory";
import { useParams, Link, Switch, Route, Redirect } from "react-router-dom";
import AuthCheck from "utils/authCheck";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";

import { getUserInfo } from "provider/privateProvider/provider";
import CreatePrivateEvents from "./AccountTabs/CreatePrivateEvents";
import FrequentFriends from "./AccountTabs/FrequentFriends";
import FrequentAddresses from "./AccountTabs/FrequentAddresses";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          classes={{
            root: isMobile ? classes.boxTabPanelMobile : classes.boxTabPanel,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const customTabs = {
  profile: 0,
  payments: 1,
  events: 2,
  // routes: 2,
  frecuentAddresses: 3,
  frecuentFriends: 4,
};

export default function AccountPage(props) {
  const { tab } = useParams();

  const [person, setPerson] = useState(false);
  const [value, setValue] = React.useState(
    customTabs[tab] ? customTabs[tab] : 0
  );
  const contextType = useContext(Context);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const {
    setUserData,
    getUserData,
    setLogStatus,
    getLogStatus,
    t,
  } = contextType;

  const [isLogged, setIsLogged] = useState(true);
  const classes = useStyles();
  const { firstName, lastName } = getUserData();

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getUser = () => {
      if (!(Object.keys(getUserData()).length === 0)) {
        setPerson(true);
      } else {
        getUserInfo().then((res) => {
          setUserData(res);
          setPerson(true);
        });
      }
    };
    getUser();
  }, [person]);

  useEffect(() => {
    const checkLoginStatus = () => {
      AuthCheck()
        .then((res) => {
          setIsLogged(true);
          setLogStatus(true);
        })
        .catch((e) => {
          setIsLogged(false);
          setLogStatus(false);
        });
    };
    checkLoginStatus();
  }, [getLogStatus()]);

  const Profile = () => {
    return person ? (
      <TabPanel>
        <PerfilTab personData={getUserData()} />
      </TabPanel>
    ) : null;
  };

  // const Events = () => {
  //   return (
  //     <TabPanel>
  //       <EventsTab />
  //     </TabPanel>
  //   )
  // }

  // const Routes = () => {
  //   return (
  //     <TabPanel>
  //       <Typography>{'Item Three'}</Typography>
  //     </TabPanel>
  //   )
  // }

  const Payments = () => {
    return (
      <TabPanel>
        <Typography>
          <PurchaseHistory />
        </Typography>
      </TabPanel>
    );
  };

  return isLogged ? (
    // <BrowserRouter>
    <Box classes={{ root: classes.contentWrapper }}>
      <Container maxWidth="lg">
        <Box classes={{ root: classes.boxContentAccount }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={3}>
              {getUserData() ? (
                <Box
                  classes={{
                    root: isMobile
                      ? classes.boxPerfilMobile
                      : classes.boxPerfil,
                  }}
                >
                  <Box
                    classes={{ root: classes.boxAvatar }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Typography
                      classes={{ root: classes.labelName }}
                      className={isMobile ? classes.fs18 : classes.fs20}
                    >
                      {firstName} {lastName}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box classes={{ root: classes.boxLinks }}>
                    <Tabs
                      orientation="vertical"
                      // variant='scrollable'
                      value={value}
                      onChange={handleChange}
                      aria-label="user information"
                      /* className={classes.tabs} */
                      //indicatorColor="secondary"
                      centered={false}
                      variant="fullWidth"
                      selectionFollowsFocus={false}
                      TabIndicatorProps={{ style: { background: "none" } }}
                    >
                      <Tab
                        classes={{
                          root: classes.customTabRoot,
                          wrapper: classes.customTabWrapper,
                          selected: classes.customTabSelected,
                        }}
                        label={t("profile")}
                        icon={
                          <PersonIcon
                            classes={{ root: classes.customTabIcon }}
                          />
                        }
                        {...a11yProps(0)}
                        component={Link}
                        to="/my-account/profile"
                        disableRipple={true}
                      />
                      <Tab
                        classes={{
                          root: classes.customTabRoot,
                          wrapper: classes.customTabWrapper,
                          selected: classes.customTabSelected,
                        }}
                        label={t("purchase_history")}
                        icon={
                          <QueryBuilderIcon
                            classes={{ root: classes.customTabIcon }}
                          />
                        }
                        {...a11yProps(1)}
                        component={Link}
                        to="/my-account/payments"
                        disableRipple={true}
                      />
                      <Tab
                        classes={{
                          root: classes.customTabRoot,
                          wrapper: classes.customTabWrapper,
                          selected: classes.customTabSelected,
                        }}
                        label={t("your_events")}
                        icon={
                          <StarBorderIcon
                            classes={{ root: classes.customTabIcon }}
                          />
                        }
                        {...a11yProps(2)}
                        component={Link}
                        to="/my-account/events"
                        disableRipple={true}
                      />
                      {/* <Tab
                          classes={{
                            root: classes.customTabRoot,
                            wrapper: classes.customTabWrapper,
                            selected: classes.customTabSelected,
                          }}
                          label='Tus Rutas'
                          icon={<LocationOnIcon classes={{ root: classes.customTabIcon }} />}
                          {...a11yProps(2)}
                          component={Link}
                          to='/my-account/routes'
                          disableRipple={true}
                        /> */}
                      <Tab
                        classes={{
                          root: classes.customTabRoot,
                          wrapper: classes.customTabWrapper,
                          selected: classes.customTabSelected,
                        }}
                        label={t("frequent_addresses")}
                        icon={
                          <HomeIcon classes={{ root: classes.customTabIcon }} />
                        }
                        {...a11yProps(3)}
                        component={Link}
                        to="/my-account/frecuentAddresses"
                        disableRipple={true}
                      />
                      <Tab
                        classes={{
                          root: classes.customTabRoot,
                          wrapper: classes.customTabWrapper,
                          selected: classes.customTabSelected,
                        }}
                        label={t("frequent_friends")}
                        icon={
                          <GroupIcon
                            classes={{ root: classes.customTabIcon }}
                          />
                        }
                        {...a11yProps(4)}
                        component={Link}
                        to="/my-account/frecuentFriends"
                        disableRipple={true}
                      />
                    </Tabs>
                  </Box>
                </Box>
              ) : (
                <Box
                  alignItems="center"
                  classes={{ root: classes.boxProgressPerfil }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress
                    classes={{ root: classes.customProgress }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box classes={{ root: classes.boxPerfilNav }}>
                {getUserData() ? (
                  <>
                    <Switch>
                      <Route path="/my-account/profile" component={Profile} />
                      <Route path="/my-account/payments" component={Payments} />
                      <Route
                        path="/my-account/events"
                        component={CreatePrivateEvents}
                      />
                      {/* <Route path='/my-account/routes' component={Routes} /> */}
                      <Route
                        path="/my-account/frecuentAddresses"
                        component={FrequentAddresses}
                      />
                      <Route
                        path="/my-account/frecuentFriends"
                        component={FrequentFriends}
                      />
                    </Switch>
                  </>
                ) : (
                  <Box
                    alignItems="center"
                    classes={{ root: classes.boxProgressPerfil }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress
                      classes={{ root: classes.customProgress }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  ) : (
    // </BrowserRouter>
    <Redirect to="/" />
  );
}
