import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

// Sections for this page
import SectionCarousel from '../Components/Sections/SectionCarousel.js'
import EventSection from './Sections/EventSection.js'
import ComunitySection from './Sections/ComunitySection.js'
import UserSection from './Sections/UserSection.js'
import Finder from '../../assets/img/vmove/icons/finder.svg'
import ChevronDown from '../../assets/img/vmove/icons/chevron-down.svg'
import styles from 'assets/jss/views/landingPage.js'
import MediaQuery from 'react-responsive'

// import Scrollspy from "react-scrollspy";
// import ScrollspyNav from "react-scrollspy-nav";

const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const carouselRef = useRef(null)

  const customScroll = () => {
    const heightToScroll = carouselRef.current.offsetHeight - 120
    window.scrollTo({
      top: heightToScroll,
      left: 0,
      behavior: 'smooth',
    })
  }

  const classes = useStyles()
  return (
    <Box>
      <MediaQuery minDeviceWidth={960}>
        <div ref={carouselRef}>
          <SectionCarousel />
          <Box display='flex' justifyContent='center' alignItems='center'>
            <span onClick={customScroll}>
              <img src={Finder} alt='Finder' className={classes.imageLanding + ' ' + classes.finder} />
            </span>
            <span onClick={customScroll}>
              <img src={ChevronDown} alt='Finder' className={classes.imageLanding + ' ' + classes.chevronDown} />
            </span>
          </Box>
        </div>
      </MediaQuery>
      <EventSection />
      <MediaQuery minDeviceWidth={960}>
        <ComunitySection />
        <UserSection />
      </MediaQuery>
    </Box>
  )
}
