import React from 'react'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Grid } from '@material-ui/core'
import AddressesByTicket from 'components/Forms/ReservationForms/Entity/AddressesByTicket'

const DetailsAddress = (props) => {
  const { updateAddresses, index, ticket, counties, validateForm, setFieldValue, ticketsLength } = props

  return (
    <AccordionDetails>
      <Grid xs={12}>
        <AddressesByTicket
          updateAddresses={updateAddresses}
          addresses={ticket.addresses}
          stops={ticket.stops}
          currentTicket={index}
          counties={counties}
          validateForm={validateForm}
          approaches={ticket.approach}
          setFieldValue={setFieldValue}
          ticketsLength={ticketsLength}
        />
      </Grid>
    </AccordionDetails>
  )
}

export default DetailsAddress
