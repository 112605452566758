import React, { useState, useEffect } from 'react'
import styles from 'assets/jss/views/orderDetailPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'
import { Redirect, useParams } from 'react-router-dom'
import { getTicketDetail } from 'provider/privateProvider/provider'

import LoadingOrderState from './OrderStates/LoadingOrderState'
import SuccessOrderState from './OrderStates/SuccessOrderState'
import ErrorOrderState from './OrderStates/ErrorOrderState'
import CancelledOrderState from './OrderStates/CancelledOrderState'

const useStyles = makeStyles(styles)

const OrderDetailPage = (props) => {
  const classes = useStyles()
  //const [detail, setDetail] = useState({})
  const [detailState, setDetailState] = useState('Loading')

  const { id } = useParams()

  useEffect(() => {
    getTicketDetail(id, props.location.search)
      .then((response) => {
        //setDetail(response)
        const state = response.order.paymentState
        switch (state) {
          case 'cancelled':
            setDetailState('Cancelled')
            break
          case 'paid':
            setDetailState('Success')
            break
          default:
            setDetailState('Error')
            break
        }
      })
      .catch((error) => {
        setDetailState('Error')
      })
  }, [id])

  const renderDetail = () => {
    switch (detailState) {
      case 'Loading':
        return <LoadingOrderState />
      case 'Error':
        return <ErrorOrderState />
      case 'Success':
        return <SuccessOrderState />
      case 'Cancelled':
        return <CancelledOrderState />
      default:
        return <Redirect to='/' />
    }
  }

  return (
    <Box className={classes.contentWrapper}>
      <Container className={classes.containerDetailInfo}>{renderDetail()}</Container>
    </Box>
  )
}

export default OrderDetailPage
