import React, { useEffect, useState, useContext } from 'react'
import { Box, Button, Divider, Grid, ListItem } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import Input from 'components/Tools/Input'
import InputControlObject from 'components/Tools/InputControlObject'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import Context from 'context/Context'
import ForcedSelect from 'components/Tools/ForcedSelect'
import ForcedSelectObject from 'components/Tools/ForcedSelectObject'

const useStyles = makeStyles(styles)

const AddressesByTicket = (props) => {
  const { addresses, updateAddresses, stops, currentTicket, counties, validateForm, setFieldValue, approach, ticketsLength } = props
  const [counter, setCounter] = useState(addresses.length)
  const contextType = useContext(Context)
  const { getUserData, t } = contextType

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  let frequentAddressIsPresent = false;

  const frequentAddress = getUserData().frequentAddresses.map((address)=>{
    if(address.myAddress === true){
      return {
        name: address.name,
        countyId: address.county.id,
      };
    };
    }).filter((address)=> address !== undefined && address);

  const frequentAddressVerify = () => {
    getUserData().frequentAddresses.forEach((item)=>{
      if (item?.myAddress === true) {
            frequentAddressIsPresent = true;
          } 
    })
  }

  useEffect(()=>{
    frequentAddressVerify()
    if(frequentAddressIsPresent){
      handleFrequentAddress();

      if(currentTicket === ticketsLength-1)
        { 
          handleIncrement(); 
          handleDecrement();
        }
    handleDecrement();
    }
  },[])

  useEffect(()=>{
    counter < 1 && setCounter(counter +1 )
  },[counter])

  const initialTickets = {
        address: '',
        commune_id: '',
      }

  const handleFrequentAddress = () => {
    setCounter(addresses.length + 1);
    addresses.push({address: frequentAddress[0].name, commune_id: frequentAddress[0].countyId})
    addresses.reverse();
    updateAddresses(addresses, counter);
  };

  const handleIncrement = () => {
    setCounter(counter + 1)
    addresses.push(initialTickets)
    updateAddresses(addresses, counter)
  }

  const handleDecrement = () => {
    setCounter(counter - 1)
    addresses.pop()
    updateAddresses(addresses, counter)
    validateForm()
  }

  return (
    <>
      {addresses.map((address, index) => (
        <div key={`${index}`}>
          <>
            <ListItem className={isMobile ? classes.customItemMobile : classes.customItem} id={`ticket_${index + 1}`}>
              <Grid container display='flex' alignItems='center' spacing={2} justify='flex-end'>
                <Box
                  alignItems='center'
                  className={isMobile ? classes.boxPointMobile : classes.boxPoint}
                  display='flex'
                  justifyContent='center'
                >
                  <FiberManualRecordIcon fontSize='small' className={classes.iconCircle} />
                </Box>
                
                {!approach ? (
                  frequentAddressIsPresent 
                  ? 
                  (<>
                    <Grid item xs={11} md={6} className={classes.itemFullCenter} style={{color: '#000000'}}>
                      <Input label={t('address_input')} name={`tickets[${currentTicket}].addresses[${index}].address`}/>
                    </Grid>
                    <Grid item xs={11} md={5}>
                      <InputControlObject
                        label={process.env.REACT_APP_TRANSLATE_DISTRICT}
                        name={`tickets[${currentTicket}].addresses[${index}].commune_id`}
                        data={counties}
                      />
                    </Grid>
                  </>)
                    :
                  (<>
                    <Grid item xs={11} md={6} className={classes.itemFullCenter} style={{color: '#000000'}}>
                      <Input label={t('address_input')} name={`tickets[${currentTicket}].addresses[${index}].address`}/>
                    </Grid>
                    <Grid item xs={11} md={5}>
                      <InputControlObject
                        label={process.env.REACT_APP_TRANSLATE_DISTRICT}
                        name={`tickets[${currentTicket}].addresses[${index}].commune_id`}
                        data={counties}
                      />
                    </Grid>
                  </>)
                ) : (
                  <>
                    <Grid item xs={11} md={6}>
                      <ForcedSelect
                        label={t('address_input')}
                        name={`tickets[${currentTicket}].addresses[${index}].address`}
                        // data={approaches.map((element) => element.name)}
                        data={approach.address}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={11} md={5}>
                      <ForcedSelectObject
                        label={process.env.REACT_APP_TRANSLATE_DISTRICT}
                        name={`tickets[${currentTicket}].addresses[${index}].commune_id`}
                        data={approach.countyId}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </>
                )
                }
              </Grid>
            </ListItem>
            {index + 1 !== stops && <Divider classes={{ root: classes.customDividerCollapse }} />}
          </>
        </div>
      ))}
      <Grid container display='flex' alignItems='center' justify='center' className={classes.gridButtonContainer} spacing={3}>
        {stops > 1 && (
          <>
            <Grid item>
              <Button
                disabled={counter <= 1}
                onClick={() => handleDecrement()}
                classes={{ root: classes.buttonRemove }}
                endIcon={<RemoveCircleOutlineIcon />}
              >
                {t('remove')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={counter === stops}
                onClick={() => handleIncrement()}
                classes={{ root: classes.buttonAdd }}
                endIcon={<AddCircleOutlineIcon />}
              >
                {t('add')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default AddressesByTicket
