import React, { useContext, Fragment, useEffect, useState } from "react";
import styles from "assets/jss/components/cardPrivateEventList.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Container,
  CardContent,
  CircularProgress,
  Grid,
  createMuiTheme,
  Button,
} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { ThemeProvider } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { getPrivateEventList } from "provider/privateProvider/provider.js";

import moment from "moment";
import { useSnackbar } from "notistack";
import Context from "../../context/Context";

const useStyles = makeStyles(styles);

const PrivateEventsHistoryList = () => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [dataList, setDataList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const contextType = useContext(Context);
  const { t } = contextType;

  useEffect(() => {
    getPrivateEventList(4)
      .then((data) => {
        setDataList(data);
      })
      .catch((error) => {
        setError(true);
        console.error("error active_private_event_list", error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{ height: "500px", alignItems: "center", paddingTop: "25%" }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  const action = (key) => {
    return (
      <Fragment>
        <Button
          className={classes.dismissNotification}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </Fragment>
    );
  };

  return (
    <>
      {!error ? (
        dataList.message ? (
          <div className={classes.divContainer}>
            <Grid
              container
              xs={12}
              md={12}
              justify="center"
              className={classes.msgError}
            >
              {t("no_events_available")}
            </Grid>
          </div>
        ) : (
          dataList.map((item) => (
            <div className={classes.divContainer}>
              <Card variant="outlined" className={classes.root}>
                <Grid item md={8} xs={12}>
                  <div className={classes.details}>
                    <CardContent className={classes.content}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.dateContent}
                      >
                        {moment(item.startDate, "MM/DD/YYYY").format(
                          "DD/MM/yyyy"
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.titleContent}
                      >
                        {item.name}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        className={classes.addressContent}
                      >
                        {item.address}
                      </Grid>
                    </CardContent>
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div className={classes.divStatusFinished}>
                    {t("event_finished")}
                  </div>
                  {item.imagePath && (
                    <img
                      src={item.imagePath}
                      className={classes.cover}
                      alt={item.name}
                    />
                  )}
                  <div>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      className={classes.divOptions}
                    >
                      <Grid item xs={12} className={classes.icon}>
                        <Button className={classes.repeatEventButton}>
                          {t("event_repeat")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Card>
            </div>
          ))
        )
      ) : (
        <div className={classes.divContainer}>
          <Grid
            container
            xs={12}
            md={12}
            justify="center"
            className={classes.msgError}
          >
            {t("unable_to_upload_your_application")}
          </Grid>
        </div>
      )}
    </>
  );
};

export default PrivateEventsHistoryList;
