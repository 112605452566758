import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/languageSelectorStyle.js'
import Context from '../../../../src/context/Context'

const useStyles = makeStyles(styles)

export default function LocaleSelector(props) {
  const [languageSelected, setLanguageSelected] = useState(true)
  const contextType = useContext(Context)
  const { setLanguage } = contextType
  const classes = useStyles()

  const changeSelectLanguage = (action) => (event) => {
    let language = event.currentTarget.getAttribute('value')
    setLanguage(language)
    setLanguageSelected(action)
  }

  return (
    <Fragment>
      <Grid item>
        {props.locales.map((locale, index) => {
          return (
            <Fragment key={index}>
              <Button
                classes={{ root: classes.buttonSelector }}
                className={
                  languageSelected || index === 0 ? classes.labelSelected : classes.labelNotSelected
                }
                onClick={changeSelectLanguage(index === 0)}
                value={locale.code}
              >
                {locale.code.split('_')[0].toUpperCase()}
              </Button>
              {index < props.locales.length - 1 ? <span>/</span> : null}
            </Fragment>
          )
        })}
      </Grid>
    </Fragment>
  )
}

LocaleSelector.propTypes = {
  locales: PropTypes.array.isRequired,
}
