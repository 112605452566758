import React, { useContext, useState } from 'react'
import Context from '../../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/resetPasswordStyle.js'
import { useMediaQuery } from 'react-responsive'
import { Link, useParams } from 'react-router-dom'
import { Grid, Box, Button, TextField, Snackbar, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { ResetPasswordSchema } from '../../../utils/validations'
import { postResetPassword } from 'provider/accessProvider/provider'

const initialValues = {
  password1: '',
  password2: '',
}

const useStyles = makeStyles(styles)

const ResetPassword = () => {
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const [resetPassword, setResetPassword] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const { token } = useParams()

  const handleSubmit = (values) => {
    if (values.password1 === values.password2) {
      postResetPassword(token, values)
        .then((response) => setResetPassword(true))
        .catch((error) => setOpenSnack(true))
    } else {
      setOpenSnack(true)
    }
  }

  const resetPage = () => {
    return (
      <Grid container>
        <Grid className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Typography className={classes.resetTitle}>Restablecer Contraseña</Typography>
          </Grid>

          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Typography className={classes.resetMessage}>
              Ingrese una nueva contraseña para poder restablecerla. Las contraseñas deben ser iguales.
            </Typography>
          </Grid>

          <Formik initialValues={initialValues} validationSchema={ResetPasswordSchema} onSubmit={handleSubmit}>
            <Form>
              <Grid item xs={12} md={12}>
                <Field
                  as={TextField}
                  classes={{ root: classes.resetInput }}
                  fullWidth
                  type='password'
                  label={'contraseña'}
                  name='password1'
                  placeholder={t('')}
                  variant='outlined'
                  helperText={<ErrorMessage name='password1' render={(msg) => <Typography className={classes.error}>{msg}</Typography>} />}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  as={TextField}
                  classes={{ root: classes.resetInput }}
                  fullWidth
                  type='password'
                  label={'contraseña'}
                  name='password2'
                  placeholder={t('')}
                  variant='outlined'
                  helperText={<ErrorMessage name='password2' render={(msg) => <Typography className={classes.error}>{msg}</Typography>} />}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.itemFullCenter}>
                <Button
                  type='submit'
                  classes={{ root: classes.buttonBack }}
                  variant='contained'
                  className={isMobile ? classes.fs16 : classes.fs18}
                >
                  RESTABLECER
                </Button>
              </Grid>
            </Form>
          </Formik>
          <Snackbar
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            open={openSnack}
            onClose={() => setOpenSnack(false)}
            autoHideDuration={4000}
          >
            <Alert severity='error'>LAS CONTRASEÑAS INGRESADAS DEBEN COINCIDIR</Alert>
          </Snackbar>
        </Grid>
      </Grid>
    )
  }

  const successPage = () => {
    return (
      <Grid container>
        <Grid className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Typography className={classes.resetTitle}>¡Contraseña Restablecida Exitosamente!</Typography>
          </Grid>

          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Typography className={classes.resetMessage}>
              Su contraseña ha sido actualizada. Ahora, para iniciar sesión, vuelva a la página principal.
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Link to='/'>
              <Button classes={{ root: classes.buttonBack }} variant='contained' className={isMobile ? classes.fs16 : classes.fs18}>
                {t('back_to_main_screen')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return <Box classes={{ root: classes.contentWrapper }}>{!resetPassword ? resetPage() : successPage()}</Box>
}

export default ResetPassword
