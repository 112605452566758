import React, { useContext } from "react";
import Context from "../../../context/Context";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/components/loginSelectorStyle.js";
import { useMediaQuery } from "react-responsive";
import validate from "./LoginFormValidationRules";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esDate from "date-fns/locale/es";
import moment from "moment";

import orange from "@material-ui/core/colors/orange";

import { ThemeProvider } from "@material-ui/styles";

import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  Modal,
  Typography,
  createMuiTheme,
} from "@material-ui/core";

import Logo from "../../../assets/img/vmove/logos/vmove-logo-2.svg";
import Times from "../../../assets/img/vmove/icons/times.svg";
import Mail from "../../../assets/img/vmove/icons/mail.svg";
import Lock from "../../../assets/img/vmove/icons/lock.svg";
import User from "../../../assets/img/vmove/icons/user.svg";
import Phone from "../../../assets/img/vmove/icons/phone.svg";

import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles(styles);

const RenderModalSignUp = (props) => {
  const contextType = useContext(Context);
  const { t } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const phoneStyle = {
    width: "100%",
    border: "1px solid #CDCDCD",
    borderRadius: "12px",
    fontSize: "14px",
    height: "55px",
    opacity: "1",
    "&:react-tel-input": {
      "&:focus": {
        borderColor: "#000000",
      },
    },
  };
  const {
    setSignupErrors,
    signUp,
    values,
    setSignupIsSubmitting,
    handleSignUp,
    signupErrors,
    setValues,
    logIn,
    setLogIn,
    setSignUp,
  } = props;

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  const handleChangeLogin = () => (event) => {
    if (logIn) {
      setLogIn(false);
      setSignUp(true);
    } else if (signUp) {
      setSignUp(false);
      setLogIn(true);
    }
  };

  const handleSignUpSubmit = (event) => {
    if (event) event.preventDefault();
    setSignupErrors(validate("SignUp", values));
    setSignupIsSubmitting(true);
  };

  const handleInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleInputChangePhone = (value) => {
    setValues((values) => ({
      ...values,
      phone: value,
    }));
  };

  const handleInputChangeDate = (value) => {
    setValues((values) => ({
      ...values,
      birthday: value,
    }));
  };

  const printErrors = (error) => {
    return (
      <Typography
        align="left"
        classes={{ root: classes.labelError }}
        variant="caption"
      >
        {t(error)}
      </Typography>
    );
  };

  return (
    <Modal
      className={classes.modal}
      open={signUp}
      onClose={handleSignUp()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Grid container display="flex" justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
          <Fade in={signUp}>
            <Box className={classes.paper}>
              <Box
                classes={{ root: classes.paperBackground }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <img alt="Logo" src={Logo} className={classes.logoModal} />
                <Typography className={classes.titleModal}>
                  ¡{t("welcome")}!
                </Typography>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  classes={{ root: classes.boxTimes }}
                >
                  <Button
                    classes={{ root: classes.timesButton }}
                    onClick={handleSignUp()}
                  >
                    <img alt="X" src={Times} />
                  </Button>
                </Box>
              </Box>
              <Box
                className={
                  isMobile ? classes.paperContentMobile : classes.paperContent
                }
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <form
                  autoComplete="off"
                  className={classes.loginForm}
                  noValidate
                  onSubmit={handleSignUpSubmit}
                >
                  <Grid container justify="center" align="center">
                    <Grid item xs={8} md={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <FormControl className={classes.customFormControl}>
                            <Input
                              autoComplete="off"
                              classes={{
                                focused: classes.customFocused,
                                root: classes.loginInputAuto,
                              }}
                              disableUnderline
                              name="email"
                              onChange={handleInputChange}
                              placeholder={t("email")}
                              required
                              InputAdornmentProps={{
                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                              }}
                              type="email"
                              value={values.email || ""}
                              variant="outlined"
                            />
                            {signupErrors.email &&
                              printErrors(signupErrors.email)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl className={classes.customFormControl}>
                            <Input
                              autoComplete="new-password"
                              classes={{
                                focused: classes.customFocused,
                                root: classes.loginInputAuto,
                              }}
                              disableUnderline
                              name="password"
                              onChange={handleInputChange}
                              placeholder={t("password")}
                              required
                              startAdornment={
                                <InputAdornment position="start">
                                  <img alt="Lock" src={Lock} />
                                </InputAdornment>
                              }
                              type="password"
                              value={values.password || ""}
                              variant="outlined"
                            />
                            {signupErrors.password &&
                              printErrors(signupErrors.password)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControl className={classes.customFormControl}>
                            <Input
                              classes={{
                                focused: classes.customFocused,
                                root: classes.loginInputAuto,
                              }}
                              disableUnderline
                              name="name"
                              onChange={handleInputChange}
                              placeholder={t("name")}
                              required
                              startAdornment={
                                <InputAdornment position="start">
                                  <img alt="User" src={User} />
                                </InputAdornment>
                              }
                              type="text"
                              value={values.name || ""}
                              variant="outlined"
                            />
                            {signupErrors.name &&
                              printErrors(signupErrors.name)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <FormControl className={classes.customFormControl}>
                            <Input
                              classes={{
                                focused: classes.customFocused,
                                root: classes.loginInputAuto,
                              }}
                              disableUnderline
                              name="lastName"
                              onChange={handleInputChange}
                              placeholder={t("surnames")}
                              required
                              startAdornment={
                                <InputAdornment position="start">
                                  <img alt="User" src={User} />
                                </InputAdornment>
                              }
                              type="text"
                              value={values.lastName || ""}
                              variant="outlined"
                            />
                            {signupErrors.lastName &&
                              printErrors(signupErrors.lastName)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl className={classes.customFormControl}>
                            <PhoneInput
                              country={process.env.REACT_APP_PHONE_COUNTRY}
                              localization={es}
                              value={values.phone}
                              inputStyle={phoneStyle}
                              placeholder={t("phone")}
                              specialLabel={""}
                              defaultMask={". .... .... ... ..."}
                              onChange={(phone) =>
                                handleInputChangePhone(phone)
                              }
                            />
                            {/* <Input
                              classes={{
                                focused: classes.customFocused,
                                root: classes.loginInputAuto,
                              }}
                              disableUnderline
                              name="phone"
                              onChange={handleInputChange}
                              placeholder={t("phone")}
                              required
                              startAdornment={
                                <InputAdornment position="start">
                                  <img alt="Phone" src={Phone} />
                                </InputAdornment>
                              }
                              type="text"
                              value={values.phone || ""}
                              variant="outlined"
                            /> */}
                            {signupErrors.phone &&
                              printErrors(signupErrors.phone)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl className={classes.customFormControl}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esDate}>                              
                                <DatePicker
                                  disableFuture
                                  openTo="year"
                                  format="dd/MM/yyyy"
                                  label={null}
                                  views={["year", "month", "date"]}
                                  className={'login-datepicker'}
                                  classes={{
                                    focused: classes.customFocused,
                                    root: classes.loginInputAuto,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  defaultValue={null}
                                  value={values.birthday || null}
                                  placeholder={t("birthday")}     
                                  onChange={handleInputChangeDate}
                                  autoOk
                                  required
                                  variant="inline"
                                  inputVariant="outlined"
                                  InputAdornmentProps={{ position: "start" }}
                                />
                              </MuiPickersUtilsProvider>
                            </ThemeProvider>
                            {signupErrors.birthday &&
                              printErrors(signupErrors.birthday)}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box
                            className={
                              isMobile
                                ? classes.boxTermsAndConditionsMobile
                                : classes.boxTermsAndConditions
                            }
                            display="flex"
                            justifyContent="flex-start"
                          >
                            <p className={classes.customLabelStart}>
                              {t("clicking_register_termins")}
                              <span className={classes.linkAcceptTerms}>
                                {" "}
                                {t("terms")}
                              </span>{" "}
                              {t("by")} {t("vmove")}
                            </p>
                          </Box>
                          <FormControl className={classes.customFormControl}>
                            <Button
                              classes={{ root: classes.buttonModalAuto }}
                              type="submit"
                            >
                              {t("register")}
                            </Button>
                          </FormControl>
                          <Box
                            className={
                              isMobile
                                ? classes.boxRegisterMobile
                                : classes.boxRegister
                            }
                            display="flex"
                            justifyContent="center"
                          >
                            <p className={classes.alreadyUser}>
                              ¿{t("already_a_user")}?
                            </p>
                            <span
                              className={classes.linkLogIn}
                              onClick={handleChangeLogin()}
                            >
                              {t("log_in")}
                            </span>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Fade>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RenderModalSignUp;
