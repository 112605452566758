/*!
=========================================================
* Tools shortcuts for project
=========================================================
*/

// Fonts
const latoRegular = "Lato-Regular";
const latoBold = "Lato-Bold";

// margin
const m_0 = {
  margin: "0"
};

const m1r = {
  margin: "1rem 0"
};

// marginTop
const mt05r = {
  marginTop: "0.5rem"
};

const mt1r = {
  marginTop: "1rem"
};

const mt1_5r = {
  marginTop: "1.5rem"
};

const mt2r = {
  marginTop: "2rem"
};

const mt3r = {
  marginTop: "3rem"
};

// marginBottom
const mb05r = {
  marginBottom: "0.5rem"
};

const mb1r = {
  marginBottom: "1rem"
};

const mb1_5r = {
  marginBottom: "1.5rem"
};

const mb2r = {
  marginBottom: "2rem"
};

// fontSize
const fs12 = {
  fontSize: "12px"
};

const fs13 = {
  fontSize: "13px"
};

const fs14 = {
  fontSize: "14px"
};

const fs16 = {
  fontSize: "16px"
};

const fs18 = {
  fontSize: "18px"
};

const fs20 = {
  fontSize: "20px"
};

const fs24 = {
  fontSize: "24px"
};

const fs25 = {
  fontSize: "25px"
};

const fs30 = {
  fontSize: "30px"
};

const fs32 = {
  fontSize: "32px"
};

const fs40 = {
  fontSize: "40px"
};


const fs50 = {
  fontSize: "50px"
};

const fs60 = {
  fontSize: "60px"
};

// lineHeight
const lh_0 = {
  lineHeight: "0"
};

// Width
const w100 = {
  width: "100% !important"
};

// Padding
const p0 = {
  padding: "0 !important"
};

export {
  latoRegular,
  latoBold,
  m_0,
  mt05r,
  m1r,
  mt1r,
  mt1_5r,
  mt2r,
  mt3r,
  mb05r,
  mb1r,
  mb1_5r,
  mb2r,
  fs12,
  fs13,
  fs14,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs30,
  fs32,
  fs40,
  fs50,
  fs60,
  lh_0,
  w100,
  p0
};
