import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Context from '../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, Grid } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import styles from 'assets/jss/components/eventCardsStyle.js'
import '../../assets/scss/components/eventCardsStyle.scss'
import Moment from 'react-moment'
import 'moment-timezone'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(styles)

export default function EventCards(props) {
  const contextType = useContext(Context)
  const { getLanguage, t } = contextType
  const classes = useStyles()
  const { bgColor, event } = props
  const { id, slug, translations, firstPlaceName, //calendars, 
    startDate,
    minTicketPrice } = event
  //const eventDate = calendars[0].startDate
  const eventDate = startDate.replace(/-/g, "/")

  const minTicketPriceTranslations = translations[getLanguage()].minTicketPrice

  const formattedDate = (eventDate) => {
    const newFormatDate = eventDate.replace(/-/g, ' ')

    return newFormatDate
  }

  const dateMomentFormat = (eventDate, format) => {
    const momentFormat = <Moment format={format}>{formattedDate(eventDate)}</Moment>

    return momentFormat
  }

  const dateLocaleString = (eventDate, type) => {
    const date = new Date(Date.parse(formattedDate(eventDate)))
    const localeDate = date.toLocaleString(getLanguage().replace('_', '-'), {
      [type]: 'long',
    })

    return localeDate.charAt(0).toUpperCase() + localeDate.slice(1)
  }

  return (
    <Grid className={classes.cardSizeFive}>
      <Box className={`${classes.cardSize} ${classes.eventCard}`}>
        <img alt='Event' src={translations[getLanguage()].imagePath} className={classes.eventImage} />
        <Box className='boxWrapper'>
          <Link to={{pathname: `/event/${slug}`, state:{slug: slug, id: id}}}>
            <Box className='boxInfo' style={{ backgroundColor: bgColor }} />
            <Box className='boxOverlay'>
              <Button className={classes.buttonOverlay + ' ' + classes.fs12}>{t('reserve_your_transfer')}</Button>
            </Box>
            <Box className='boxInfoText'>
            { event.periodicityType === 1 
              ?
              <>
                <p className={classes.fs20 + ' ' + classes.m_0}>
                  {translations[getLanguage()] !== undefined
                          ? translations[getLanguage()].name
                          : null}
                </p>
                <p className={classes.fs16 + ' ' + classes.m_0}>
                  {dateMomentFormat(eventDate, 'DD')} {dateLocaleString(eventDate, 'month')} / {dateMomentFormat(eventDate, 'HH:mm')} {t('hours')}
                </p>
              </>
              :
              <>
                <p className={classes.fs20 + ' ' + classes.m_0}>
                  {translations[getLanguage()] !== undefined
                          ? translations[getLanguage()].name
                          : null}
                </p>
                <p className={classes.fs16 + ' ' + classes.m_0}>
                  {'Elige tu fecha'}
                </p>
              </>
              }
              <Box display='flex' justifyContent='center' className='boxButtonBuy'>
                <Button className={classes.buttonBuy + ' ' + classes.fs12}>
                  {t('transfer_from')}
                  <span className={classes.spanPrice + ' ' + classes.fs16}>
                    <NumberFormat
                      value={minTicketPrice === undefined ? minTicketPriceTranslations : minTicketPrice}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={process.env.REACT_APP_MONEY_SIGN}
                      renderText={(value) => (
                        <Box display='flex' justifyContent='center' alignItems='center' value={minTicketPrice === undefined ? minTicketPriceTranslations : minTicketPrice} className={classes.fs14}>
                          {value}
                        </Box>
                      )}
                    />
                  </span>
                </Button>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </Grid>
  )
}

EventCards.defaultProps = {
  bgColor: '#8e98a7',
}

EventCards.propTypes = {
  event: PropTypes.object.isRequired,
  bgColor: PropTypes.string,
}
