import { makeStyles } from '@material-ui/core'
import { ticketStateStyle } from '../../assets/jss/components/purchaseHistoryList'
import { useMediaQuery } from 'react-responsive'
import React, { useEffect, useState, useContext } from 'react'
import Context from 'context/Context'

const useStyles = makeStyles(ticketStateStyle)

export const TicketState = (props) => {
  const [color, setColor] = useState({})
  const { state } = props
  const classes = useStyles(color)
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const contextType = useContext(Context)
  const { t } = contextType

  useEffect(() => {
    const getTicketColor = () => {
      switch (state) {
        case 'awaiting_payment':
          setColor({ color: '#C4C100' })
          break
        case 'awaiting_transfer_payment':
          setColor({ color: '#C4C100' })
          break
        case 'paid':
          setColor({ color: '#3CA707' })
          break
        case 'cancelled':
          setColor({ color: '#FF0303' })
          break
        default:
          setColor({ color: '#258A0C' })
          break
      }
    }
    getTicketColor()
  }, [])

  return (
    <>
      {!isMobile && <div className={classes.state}>{t(state)}</div>}
      {isMobile && <div className={classes.stateResponsive} />}
    </>
  )
}
