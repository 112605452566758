import {
  customDivider,
  dullOrangeColor,
  grayColorPayment,
  grayColorSearchText,
  orangeColor,
  orangeColorRgba,
  whiteColor,
} from 'assets/jss/style.js'
import { mt1r, mt2r, latoBold, latoRegular, fs14, fs16, fs20 } from 'assets/jss/tools.js'

const paymentTabStyle = {
  headerTitle: {
    color: dullOrangeColor,
    fontFamily: latoBold,
    fontSize: '20px',
    marginBottom: '1rem',
  },
  titleCoupon: {
    fontFamily: latoRegular,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 40,
  },
  boxRenderTickets: {
    padding: '25px 0 1rem 0',
  },
  coupon: {
    color: orangeColor,
    marginBottom: '40px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '1px 4px 5px 0px rgba(0,0,0,0.75);',
    padding: 20,
    minWidth: 320,
  },
  boxTickets: {
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: '1.5rem',
    minHeight: '63px',
    opacity: '1',
    width: '100%',
  },
  disableHover: {
    margin: 0,
    padding: '20px 0',
    '&:hover': {
      cursor: 'default',
    },
  },
  summaryCategory: {
    margin: 0,
    padding: 0,
  },
  boxPaymentResume: {
    marginBottom: '2.5rem',
  },
  boxRenderDetail: {
    marginBottom: '2.5rem',
  },
  containerTickets: {
    padding: '1rem 0',
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0',
  },
  itemFullCenterEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  itemColumn: {
    flexDirection: 'column',
  },
  addressInput: {
    background: whiteColor,
    border: '1px solid #8E98A7',
    borderRadius: '5px',
    color: grayColorSearchText,
    fontFamily: latoRegular,
    fontSize: '14px',
    height: '45px',
    margin: '5px 0 5px 0',
    opacity: '1',
    padding: '0 15px',
    width: '100%',
  },
  boxInfo: {
    background: 'lightgray',
    minHeight: '60px',
    padding: '10px 20px',
  },
  boxAddressCollapse: {
    borderRadius: '0 0 12px 12px',
    boxShadow: '0px 3px 6px #00000029',
    height: 'auto',
    opacity: '1',
  },
  addressCollapse: {
    width: '100%',
  },
  boxCircle: {
    background: '#F29E6F',
    borderRadius: '50%',
    height: '15px',
    width: '15px',
  },
  labelAddressInput: {
    color: grayColorPayment,
    fontFamily: latoRegular,
  },
  itemSpacing: {
    padding: '0.5rem 0',
  },
  customList: {
    padding: '2rem 0 1rem 0',
  },
  customItem: {
    display: 'flex',
    padding: '1rem 4rem',
    alignItems: 'center',
  },
  customItemText: {
    margin: '0',
    color: orangeColorRgba,
  },
  customItemIcon: {
    margin: '0',
  },
  customInput: {
    borderWidth: '1px',
    padding: '6px 12px',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  boxTotalButton: {
    padding: '3px 3px',
  },
  notchedOutline: {
    borderColor: 'lightgray !important',
    '&:hover': {
      borderColor: 'red',
    },
  },
  customInputTotal: {
    color: grayColorPayment,
    height: 'auto',
    textAlign: 'center',
  },
  customFormControl: {
    margin: '0.8rem 0',
  },
  customLabel: {
    color: grayColorPayment,
    fontFamily: latoRegular,
    fontSize: '16px',
    marginBottom: '0.2rem',
  },
  customLabelFocused: {
    color: '#7E7E7E !important',
    fontFamily: latoBold,
  },
  customTextField: {
    background: whiteColor,
    border: '1px solid #8E98A7',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    height: '47px',
    padding: '0 15px',
  },
  buttonTicket: {
    background: orangeColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
      opacity: '1',
    },
  },
  buttonCoupon: {
    marginTop: 10,
    background: orangeColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
      opacity: '1',
    },
  },
  couponSection: {    
    margin: 0,
    marginTop: 10,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    fontFamily: latoRegular,
    fontSize: '16px',
    opacity: '0.9',
    textTransform: 'initial',
    display: 'inline-flex',
    outline: 0,
    padding: '10px',
    position: 'relative',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  couponLink: {
    marginTop: 10,
    fontFamily: latoRegular,
    color: orangeColor,
    fontSize: '16px',
    opacity: '0.9',
    cursor: 'pointer',
    textTransform: 'initial'
  },
  couponDiscountPercentage: {
    margin: 0,
    marginTop: 15,
    borderRadius: '5px',
    marginLeft: 10,
    fontFamily: latoRegular,
    color: orangeColor,
    fontSize: '24px',
    opacity: '0.9',
    textTransform: 'initial'
  },
  buttonTicketExpanded: {
    background: whiteColor,
    border: '1px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
  },
  labelRegular: {
    color: grayColorPayment,
    fontFamily: latoRegular,
  },
  labelBold: {
    color: grayColorPayment,
    fontFamily: latoBold,
  },
  boxHeaderResume: {
    marginBottom: '1rem',
  },
  buttonEdit: {
    background: 'transparent',
    color: '#0035B0',
    fontSize: '14px',
    marginLeft: '1rem',
    minWidth: '0',
    padding: '3px 8px',
    textTransform: 'initial',
  },
  editIcon: {
    fontSize: '14px',
  },
  imagePayment: {
    height: 'auto',
    width: '231px',
  },
  imageTransfer: {
    height: 'auto',
    width: '200px',
  },
  labelTransfer: {
    color: grayColorPayment,
    fontFamily: latoBold,
    fontSize: '16px',
    marginTop: '10px',
  },
  paymentMessage: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    fontSize: '20px',
    margin: '15px 0 15px 0',
  },
  buttonPayment: {
    border: 'none',
    width: '300px',
    height: '318px',
    boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.36);',
    borderRadius: '5px',
    background: 'transparent',
    '&:hover,&:focus': {
      border: 'none',
      background: 'transparent',
    },
  },
  buttonPaymentActive: {
    border: '2px solid',
    borderColor: orangeColor,
    borderRadius: '5px',
    width: '300px',
    height: '318px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerReservedMobile: {
    margin: '5px 0 !important',
  },
  containerModal: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  borderCard: {
    margin: 'auto',
    maxWidth: '599px',
    boxShadow: '0px 3px 6px #00000029',
  },
  titlesCard: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  contentCard: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  closeModal:{
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonProgress: {
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  mt1r,
  mt2r,
  customDivider,
  fs14,
  fs16,
  fs20,
}

export default paymentTabStyle
