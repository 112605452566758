import React, { useState } from 'react'
import styles from 'assets/jss/views/createPrivateEventStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import ActivePrivateEvents from 'components/CreateEventsTabs/ActivePrivateEvents'
import CreatePrivateEventForm from 'components/CreateEventsTabs/CreatePrivateEventForm'
import UpdatePrivateEventForm from 'components/CreateEventsTabs/UpdatePrivateEventForm'

const useStyles = makeStyles(styles)

const CreatePrivateEvents = ({linkToEvent}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [eventId, setEventId] = useState(0)
  const classes = useStyles()

  const activeView = () => {
    switch (activeTab) {
      case 0:
        return <ActivePrivateEvents setActiveTab={setActiveTab} setEventId={setEventId} linkToEvent={linkToEvent} />
      case 1:
        return <CreatePrivateEventForm setActiveTab={setActiveTab} />
        case 2:
          return <UpdatePrivateEventForm setActiveTab={setActiveTab} eventId={eventId} />
      default:
        return <ActivePrivateEvents setActiveTab={setActiveTab} setEventId={setEventId} linkToEvent={linkToEvent} />
    }
  }

  return <div className={classes.container}>{activeView()}</div>
}

export default CreatePrivateEvents
