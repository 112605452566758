import { primaryColor } from "assets/jss/material-kit-react.js";
import { whiteColor } from "assets/jss/style.js";
import { latoRegular } from "assets/jss/tools.js";

const footerMobileStyle = {
  containerMobile: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  footerContentMobile: {
    padding: "60px 30px 30px 30px",
    color: whiteColor
  },
  title: {
    borderBottom: `1px solid ${whiteColor}`,
    borderTop: `1px solid ${whiteColor}`,
    fontFamily: latoRegular,
    fontSize: "16px"
  },
  boxSocialNetworks: {
    marginTop: "1.5rem"
  },
  imageSocial: {
    height: "33px",
    margin: "0 10px",
    width: "33px"
  },
  boxLinks: {
    padding: "50px 40px"
  },
  customList: {
    padding: "0"
  },
  customItem: {
    padding: "5px 0"
  },
  itemSpacing: {
    padding: "0.5rem 0"
  },
  customItemText: {
    fontFamily: latoRegular,
    fontSize: "16px",
    margin: "0"
  },
  imageLogo: {
    height: "29px",
    maxWidth: "100%"
  },
  boxCopyRight: {
    padding: "40px 0 0 0"
  },
  copyRight: {
    fontFamily: latoRegular,
    fontSize: "12px",
    marginBottom: "2px"
  },
  address: {
    fontFamily: latoRegular,
    fontSize: "10px"
  },
  upperText: {
    textTransform: "uppercase"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  customLink: {
    color: whiteColor,
    "& :hover": {
      color: whiteColor
    }
  }
};
export default footerMobileStyle;
