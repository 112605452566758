
export default function validate(type, values) {
  let errors = {};
  let emailValidation = /\S+@\S+\.\S+/;
  let phoneValidation = /^[0-9\b]+$/;
  let nameValidation = "^[a-zA-Z ]*$";

  if (type === "LogIn") {
    if (!values.email) {
      errors.email = "email_is_required";
    } else if (!emailValidation.test(values.email)) {
      errors.email = "email_is_invalid";
    }
    if (!values.password) {
      errors.password = "password_is_required";
    } else if (values.password.length < 5) {
      errors.password = "password_must_be_characters";
    }
  } else if (type === "SignUp") {
    if (!values.email) {
      errors.email = "email_is_required";
    } else if (!emailValidation.test(values.email)) {
      errors.email = "email_is_invalid";
    }
    if (!values.password) {
      errors.password = "password_is_required";
    } else if (values.password.length < 5) {
      errors.password = "password_must_be_characters";
    }
    if (!values.name) {
      errors.name = "name_is_required";
    } else if (!values.name.match(nameValidation)) {
      errors.name = "name_must_contain_only_letters";
    }
    if (!values.lastName) {
      errors.lastName = "lastname_is_required";
    } else if (!values.lastName.match(nameValidation)) {
      errors.lastName = "lastname_must_contain_only_letters";
    }
    if (!values.phone) {
      errors.phone = "phone_is_required";
    } else if (!phoneValidation.test(values.phone)) {
      errors.phone = "phone_must_only_contain_numbers";
    }
    if (!values.birthday) {
      errors.birthday = "birthday_is_required";
    }
  }
  return errors;
}
