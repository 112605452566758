import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Box, IconButton, Popover, Typography, Grid } from "@material-ui/core/";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import styles from "assets/jss/views/eventPageSections/categoryTabStyle.js";

const useStyles = makeStyles(styles);

const PopoverDetail = (props) => {
  const { paragraph, imagePath, index } = props;
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const handlePopoverClose = () => {
    setOpenPopoverId(null);
    setAnchorEl(null);
  };

  const handlePopoverClick = (event, popoverId) => {
    setOpenPopoverId(popoverId);
    setAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <IconButton
        size="small"
        aria-label="question"
        classes={{
          root: classes.customIconQuestion,
        }}
        onMouseEnter={(e) => handlePopoverClick(e, index)}
        onMouseLeave={handlePopoverClose}
        //onClick={(e) => handlePopoverClick(e, index)}
      >
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        style={{ pointerEvents: 'none'}}
        open={openPopoverId === index}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        classes={{
          paper: classes.customPaperPopover,
        }}
      >
        {!imagePath && (
          <Typography
            classes={{
              root: classes.customLabelPopover,
            }}
            className={isMobile ? classes.fs14 : classes.fs16}
          >
            {paragraph}
          </Typography>
        )}
        {imagePath ? (
          <Box
            classes={{
              root: classes.boxImagePopover,
            }}
          >
            <img
              alt="Path Popover"
              className={classes.imagePopover}
              src={imagePath}
            />
          </Box>
        ) : null}
      </Popover>
    </Fragment>
  );
};

export default PopoverDetail;
