import React, { Component } from "react";
import PropTypes from "prop-types";
import { AppBar, Container, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "assets/jss/components/infoBarStyle.js";
import LanguageSelector from "./LanguageSelector.js";
import LoginSelector from "./InfoBar/LoginSelector.js";
import SearchForm from "./InfoBar/SearchForm.js";
import SocialNetwork from "./InfoBar/SocialNetwork.js";
import Facebook from "../../assets/img/vmove/social-networks/facebook_orange.svg";
import Instagram from "../../assets/img/vmove/social-networks/instagram_orange.svg";
import Youtube from "../../assets/img/vmove/social-networks/youtube_orange.svg";

export class InfoBar extends Component {
  render() {
    const { classes } = this.props;
    return (
      <AppBar className={classes.infoBar}>
        <Container>
          <Grid container alignItems="center">
            <Grid item xs={3} md={1}>
              <Grid container spacing={1}>
                <Grid item>
                  <SocialNetwork
                    image={Facebook}
                    link={process.env.REACT_APP_LINK_FACEBOOK}
                    text="Facebook"
                  />
                </Grid>
                <Grid item>
                  <SocialNetwork
                    image={Instagram}
                    link={process.env.REACT_APP_LINK_INSTAGRAM}
                    text="Instagram"
                  />
                </Grid>
                <Grid item>
                  <SocialNetwork
                    image={Youtube}
                    link={process.env.REACT_APP_LINK_YOUTUBE}
                    text="Youtube"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={2}>
              <Grid container spacing={1} justify="flex-end">
                <LanguageSelector />
              </Grid>
            </Grid>
            <Grid item xs={4} md={9}>
              <Grid
                container
                spacing={4}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item md={4}>
                  <SearchForm />
                </Grid>
                <Grid item>
                  <LoginSelector />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    );
  }
}

InfoBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoBar);
