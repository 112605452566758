import React, { useContext, useEffect, useState } from "react";
import {
  AccordionDetails,
  Divider,
  Grid,
  ListItem,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import styles from "assets/jss/views/eventPageSections/generalEventsStyles.js";
import NumberFormat from "react-number-format";
import Context from "context/Context";

const useStyles = makeStyles(styles);

const DetailsPayment = (props) => {
  const { index, ticket } = props;
  const contextType = useContext(Context);
  const { getLanguage, t, getDiscount } = contextType;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const [discount, setDiscount] = useState([]);

  useEffect(() => {
    if (getDiscount()?.code) {
      setDiscount(getDiscount().productVariants);
    }
  }, [getDiscount()]);

  const getDiscountPrice = () => {
    let productVariant = ticket.productVariantId;
    return discount
      .filter((coupon) => coupon.product_variant_id === productVariant)
      .reduce((acc, currentValue) => acc + Number(currentValue.discount_price), 0);
  };

  return (
    <AccordionDetails>
      <Grid item xs={12}>
        <ListItem
          className={classes.customItemPaymentTitle}
          id={`ticket_${index + 1}`}
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            spacing={1}
            justify="flex-start"
          >
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2">{t("category")}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2">{t("passengers")}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2">{t("price")}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem
          className={classes.customItemPaymentList}
          id={`ticket_${index + 1}`}
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            spacing={1}
            justify="flex-start"
          >
            <Grid item xs={6} md={3}>
              <Typography>{ticket.name[getLanguage()].name}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>{`${ticket.capacity} ${t("passengers")}`}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography>
                {discount.length > 0 ? (
                  <>
                    <NumberFormat
                      value={getDiscountPrice()}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={process.env.REACT_APP_MONEY_SIGN}
                      renderText={(value) => value}
                    />
                  </>
                ) : (
                  <NumberFormat
                    value={ticket.price}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={process.env.REACT_APP_MONEY_SIGN}
                    renderText={(value) => value}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem
          className={
            isMobile
              ? classes.customItemPaymentMobile
              : classes.customItemPayment
          }
          id={`ticket_${index + 1}`}
        >
          <Grid
            container
            display="flex"
            alignItems="center"
            spacing={1}
            justify="flex-end"
          >
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("passengers")}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("email")}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("phone_number")}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("address_single")}</Typography>
            </Grid>
          </Grid>
        </ListItem>
        {ticket.passengers.map((passenger, index) => (
          <div key={`${index}`}>
            <ListItem
              className={
                isMobile
                  ? classes.customItemPaymentMobile
                  : classes.customItemPayment
              }
              id={`ticket_${index + 1}`}
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                spacing={1}
                justify="flex-end"
              >
                <Grid item xs={12} md={3}>
                  <Typography>{passenger.name}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>{passenger.email}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>{passenger.phone}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>{passenger.passengerAddress}</Typography>
                </Grid>
              </Grid>
            </ListItem>
            {index + 1 !== ticket.passengers.length ? (
              <Divider classes={{ root: classes.customDividerCollapse }} />
            ) : null}
          </div>
        ))}
      </Grid>
    </AccordionDetails>
  );
};

export const DetailsPaymentMobile = (props) => {
  const { index, ticket } = props;
  const contextType = useContext(Context);
  const { getLanguage, t } = contextType;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  return (
    <AccordionDetails>
      <Grid item xs={12}>
        <Divider classes={{ root: classes.customDividerCollapse }} />
        <ListItem
          className={
            isMobile
              ? classes.customItemPaymentMobile
              : classes.customItemPayment
          }
          id={`ticket_${index + 1}`}
        >
          <Grid container display="flex" spacing={1} justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("details")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.customDividerCollapse }} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("category")}: </Typography>
              <Typography>{ticket.name[getLanguage()].name}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("passengers")}: </Typography>
              <Typography>{`${ticket.capacity} ${t("passengers")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle2">{t("price")}: </Typography>
              <Typography>
                <NumberFormat
                  value={
                    ticket.discountPrice > 0
                      ? ticket.discountPrice
                      : ticket.price
                  }
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={process.env.REACT_APP_MONEY_SIGN}
                  renderText={(value) => value}
                />
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem
          className={
            isMobile
              ? classes.customItemPaymentMobile
              : classes.customItemPayment
          }
        >
          <Grid container display="flex" spacing={1} justify="flex-start">
            <Grid item xs={12}>
              <Divider classes={{ root: classes.customDividerCollapse }} />
            </Grid>
            <Divider classes={{ root: classes.customDividerCollapse }} />
            <Grid item xs={12}>
              <Typography variant="subtitle2">{t("passenger_list")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider classes={{ root: classes.customDividerCollapse }} />
            </Grid>
          </Grid>
        </ListItem>
        {ticket.passengers.map((passenger, index) => (
          <div key={`${index}`}>
            <ListItem
              className={
                isMobile
                  ? classes.customItemPaymentMobile
                  : classes.customItemPayment
              }
              id={`ticket_${index + 1}`}
            >
              <Grid
                container
                display="flex"
                alignItems="center"
                spacing={1}
                justify="flex-end"
              >
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">{`${t("passenger")} ${index +
                    1}:`}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">
                    <strong>{t("name")}: </strong>
                  </Typography>
                  <Typography>{passenger.name}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">
                    <strong>{t("email")}: </strong>
                  </Typography>
                  <Typography>{passenger.email}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">
                    <strong>{t("phone")}: </strong>
                  </Typography>
                  <Typography>{passenger.phone}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">
                    <strong>{t("address_input")}: </strong>
                  </Typography>
                  <Typography>{passenger.passengerAddress}</Typography>
                </Grid>
              </Grid>
            </ListItem>
            {index + 1 !== ticket.passengers.length ? (
              <Divider classes={{ root: classes.customDividerCollapse }} />
            ) : null}
          </div>
        ))}
      </Grid>
    </AccordionDetails>
  );
};

export default DetailsPayment;
