import React, { useContext } from 'react'
import styles from 'assets/jss/views/eventPageSections/paymentTabStyle.js'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const Wiretransfer = (props) => {
  const { paymentResponse } = props
  const classes = useStyles()
  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Card variant='outlined' className={classes.borderCard}>
      <CardContent>
        <Grid container>
          <Grid item xs={6} md={6} spacing={3}>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('bank')}:`}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('account_id')}:`}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('account_type')}:`}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('dni')}:`}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('email')}:`}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} spacing={3}>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.bankName}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.accountId}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.accountType}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.dni}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export const WiretransferMobile = (props) => {
  const { paymentResponse } = props
  const classes = useStyles()
  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Card variant='outlined' className={classes.borderCard}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} spacing={3}>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('bank')}:`}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.bankName}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('account_id')}:`}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.accountId}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('account_type')}:`}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.accountType}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('dni')}:`}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.dni}
            </Typography>
            <Typography gutterBottom className={classes.titlesCard}>
              {`${t('email')}:`}
            </Typography>
            <Typography gutterBottom className={classes.contentCard}>
              {paymentResponse.email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Wiretransfer
