/* Colors */
const blueColor = "#2D7DD2";
const darkBlueColor = "#003049";
const dullOrangeColor = "#F29E6F";
const grayColor = "#8E98A7";
const grayColorFooter = "#00181B";
const grayColorModal = "#F6F6F6";
const grayColorPayment = "#7E7E7E";
const grayColorSearchBg = "#F6F6F6";
const grayColorSearchText = "#BCBABA";
const lightBlueColor = "#5C9EAD";
const lightGrayColor = "#EFF1F3";
const orangeColor = "#FF5E05";
const orangeColorRgba = "rgba(252,76,23,.7)";
const whiteColor = "#FFFFFF";

const contentWrapper = {
  background: "#EFF1F3",
  height: "auto",
  minHeight: "calc(100% - 120px)",
  paddingTop: "120px"
};

const contentWrapperMobile = {
  height: "auto",
  paddingTop: "90px"
};

const customDivider = {
  background: orangeColor
};

export {
  blueColor,
  contentWrapper,
  contentWrapperMobile,
  customDivider,
  darkBlueColor,
  dullOrangeColor,
  grayColor,
  grayColorFooter,
  grayColorModal,
  grayColorPayment,
  grayColorSearchBg,
  grayColorSearchText,
  lightBlueColor,
  lightGrayColor,
  orangeColor,
  orangeColorRgba,
  whiteColor
};
