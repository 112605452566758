import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import MediaQuery from "react-responsive";
import { AccordionSummary, Box, Grid, Typography } from "@material-ui/core/";
import Context from "context/Context";

import { ExpandMore } from "@material-ui/icons/";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import styles from "assets/jss/views/eventPageSections/categoryTabStyle.js";
import StarSmall from "assets/img/vmove/icons/star-small.svg";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(styles);

const SummaryCategory = (props) => {
  const { citySelected, category, expanded, categoriesLength } = props;

  const [discounts, setDiscounts] = useState([]);

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const contextType = useContext(Context);
  const { getLogStatus, getLanguage, t, getDiscount } = contextType;

  //console.log(`category`, category);

  useEffect(() => {
    if (getDiscount().used === false) {
      setDiscounts(getDiscount().productVariants);
    }
  }, [getDiscount]);

  const getPricesTickets = (tickets) => {
    if (discounts?.length > 0) {
      discounts.map((discount) => {
        tickets.map((ticket) => {
          if (ticket.productVariantId === discount.product_variant_id) {
            ticket.price = Number(discount.discount_price);
          }
        });
      });
    }

    return tickets.map((ticket) => {
      return ticket.price;
    });
  };

  const renderPricesTickets = (tickets) => {
    if (tickets.length > 0) {
      let pricesTickets = getPricesTickets(tickets);
      const newPricesTickets = new Set(pricesTickets);
      if (newPricesTickets.size > 1) {
        return <span className={classes.smallLabel}>{"Desde"}</span>;
      }
    }
  };

  const getMinorPrice = (category) => {
    const arrPrice = category.subCategories
      .filter((categoryFilter) => {
        return categoryFilter.provinceId === citySelected.id;
      })
      .map((subcategory) => subcategory.price);
    return Math.min.apply(Math, arrPrice);
  };

  return (
    <AccordionSummary
      aria-controls="panel1bh-content"
      id="panel1bh-header"
      classes={{
        content: classes.accorditionSumary,
        root: classes.accorditionSumaryRoot,
      }}
    >
      {category.priority === categoriesLength ? (
        <Box display="flex">
          <Typography
            align="start"
            className={classes.categoryRecommended + " " + classes.fs14}
          >
            <img alt="Start" src={StarSmall} />
            <span className={classes.spanRecommendText}>
              {t("recommended_transfer")}
            </span>
          </Typography>
        </Box>
      ) : null}
      <Grid
        container
        display="flex"
        justify="center"
        alignItems="center"
        className={
          isMobile
            ? classes.containerCategoriesMobile
            : classes.containerCategories
        }
      >
        <Grid item xs={6} md={2} className={classes.itemFullCenter}>
          <img
            alt={category.translations[getLanguage()].name}
            src={category.translations[getLanguage()].imagePath}
            className={classes.imageCategories}
          />
        </Grid>
        <MediaQuery minDeviceWidth={961}>
          <Grid item xs={6} md={1} className={classes.itemDivider}>
            <Box classes={{ root: classes.boxDivider }} />
          </Grid>
        </MediaQuery>
        <Grid item xs={6} md={5}>
          <Typography
            align="start"
            classes={{ root: classes.categoryTitle }}
            className={isMobile ? classes.fs18 : classes.fs20}
          >
            {category.translations[getLanguage()].name}
          </Typography>
          <Typography
            align="start"
            classes={{ root: classes.categoryHeader }}
            className={
              isMobile ? classes.fs16 + " " + classes.w100 : classes.fs18
            }
          >
            {category.translations[getLanguage()].description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          classes={{ root: classes.itemFullCenter }}
          className={isMobile ? classes.mt1r : null}
        >
          <Typography
            align="center"
            classes={{ root: classes.categoryPrice }}
            className={isMobile ? classes.fs18 : classes.fs20}
          >
            {renderPricesTickets(category.subCategories)}
            <NumberFormat
              value={getMinorPrice(category)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={process.env.REACT_APP_MONEY_SIGN}
              renderText={(value) => (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  value={getMinorPrice(category)}
                  classes={{
                    root: classes.customInputTotal,
                  }}
                  className={isMobile ? classes.fs18 : classes.fs20}
                >
                  {value ? value : t("no_avariable")}
                </Box>
              )}
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          classes={{ root: classes.itemFullCenter }}
          className={isMobile ? classes.mt1r : null}
        >
          {getLogStatus() && (
            <>{expanded ? <ExpandLessIcon /> : <ExpandMore />}</>
          )}
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

export default SummaryCategory;
