import decode from 'jwt-decode'

export default () => {
  return new Promise((resolve, reject) => {
    try {
      const token = localStorage.getItem('token')
      if (!token) reject()

      const { exp } = decode(token)
      const time = (new Date().getTime() + 5000) / 1000

      exp > time ? resolve() : reject()
    } catch (e) {
      localStorage.removeItem('token')
      reject()
    }
  })
}
