import React from "react";
import PropTypes from "prop-types";

export default function SocialNetwork(props) {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      <img alt={props.text} src={props.image} />
    </a>
  );
}

SocialNetwork.propTypes = {
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
