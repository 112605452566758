import { grayColorSearchBg, orangeColor } from 'assets/jss/style.js'
import { latoRegular, fs14, fs20 } from 'assets/jss/tools.js'

const searchFormStyle = {
  searchForm: {
    height: '100%',
    margin: '0',
  },
  searchInput: {
    background: grayColorSearchBg,
    borderRadius: '2px',
    color: orangeColor,
    fontFamily: latoRegular,
    fontStyle: 'italic',
    opacity: '1',
    padding: '0 15px',
  },
  customFormControl: {
    width: '100%',
  },
  fs14,
  fs20,
}

export default searchFormStyle
