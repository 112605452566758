import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Grid,
  makeStyles,
  createMuiTheme,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { purchaseListStyle } from "../../assets/jss/components/purchaseHistoryList";
import CustomCircularProgress from "components/CircularProgress/CustomCircularProgress";
import Context from "context/Context";
import orange from "@material-ui/core/colors/orange";
import { ThemeProvider } from "@material-ui/styles";

import { getPurchaseHistory } from "provider/privateProvider/provider";
import { PurchaseHistoryList } from "./PurchaseHistoryList";
import { TicketDetail } from "./TicketDetail";

const useStyles = makeStyles(purchaseListStyle);

export const PurchaseHistory = () => {
  const [loader, setLoader] = useState(true);
  const [viewDetail, setViewDetail] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const classes = useStyles();

  const contextType = useContext(Context);
  const { t } = contextType;

  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const openDetail = (orderId) => {
    setOrderId(orderId);
    setViewDetail(true);
  };

  const closeDetail = () => {
    setOrderId(0);
    setViewDetail(false);
  };

  useEffect(() => {
    const getUserPurchases = () => {
      getPurchaseHistory()
        .then((res) => {
          setPurchases(res.sort((a, b) => a.date - b.date));
        })
        .finally(() => {
          setLoader(false);
        });
    };
    getUserPurchases();
  }, []);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <>
      {!viewDetail ? (
        <>
          <div className={classes.title}>
            {t("purchase_history").toUpperCase()}
          </div>
          <div className={classes.container}>
            <PurchaseHistoryList
              purchases={purchases}
              openDetail={openDetail}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={
              isMobile
                ? { paddingBottom: 30, paddingLeft: 25 }
                : { paddingBottom: 30 }
            }
          >
            <Grid
              container
              justify="space-between"
              alignContent="center"
              alignItems="center"
            >
              <span
                className={classes.buttonDetail}
                onClick={() => closeDetail()}
              >
                {t("return_purchase_history")}
              </span>
              <div className={classes.titleDetail}>
                {t("detail_of_your_reservation").toUpperCase()}
              </div>
            </Grid>
          </div>
          {loader && (
            <Grid item>
              <CustomCircularProgress size={60} />
            </Grid>
          )}
          {!loader && (
            <div className={classes.container}>
              <TicketDetail orderId={orderId} />
            </div>
          )}
        </>
      )}
    </>
  );
};
