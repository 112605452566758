import React from 'react'
import { Route } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

const RouteLayout = ({ component: Component, ...rest }) => {
  return (
    <ScrollToTop>
      <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
    </ScrollToTop>
  )
}

export default RouteLayout
