import { whiteColor, grayColor } from "../style";
import { latoBold } from "assets/jss/tools";

const frequentFriendsPage = {
  root: {
    flexGrow: 1,
  },
  contentWrapper: {
    padding: "4% 10%",
    color: grayColor,
    fontFamily: latoBold,
    //container: {
    //padding: 20,
    maxHeight: 550,
    overflow: "auto",
    overflowX: "hidden",
    //},
  },
  contentTab: {
    color: grayColor,
    fontFamily: latoBold,
    //textAlign: 'center',
  },
  tar: {
    textAlign: "right",
  },
  tac: {
    textAlign: "center",
  },
  unregisteredFriends: {
    paddingBottom: "3%",
    textAlign: "center",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "20px",
  },
  formTitle: {
    color: "#6CA4DF",
    fontSize: "20px",
    fontFamily: latoBold,
  },
  addFriendButtonGrid: {
    paddingTop: "3%",
    textAlign: "center",
  },
  friendListBox: {
    paddingTop: "5%",
  },
  customIcon: {
    color: "#6CA4DF",
  },
  friendFormBox: {
    paddingTop: "5%",
    paddingBottom: "2%",
  },
  friendName: {
    textTransform: "capitalize",
  },
  divider: {
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  addFriendButton: {
    color: whiteColor,
    borderColor: "#848484",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "8px 40px",
    backgroundColor: "#6CA4DF",
    borderRadius: 20,
    transition: "0.3s",
    textTransform: "capitalize",
    "&:hover": {
      color: "#6CA4DF",
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
};
export default frequentFriendsPage;
