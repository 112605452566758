import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/views/eventPageSections/detailStyle.js'
import { useMediaQuery } from 'react-responsive'
import { Box, CircularProgress, TextField, Popper } from '@material-ui/core'

const useStyles = makeStyles(styles)

const SelectComponent = (props) => {
  const { selected, setSelected, currentStep, data, label } = props

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  const checkSelectAutoComplete = isMobile ? classes.selectOptionAutoCompleteMobile : classes.selectOptionAutoComplete

  return (
    <>
      {data && data.length > 0 ? (
        <Autocomplete
          className={isMobile && classes.w50}
          classes={{
            option: classes.customMenuItem,
            inputRoot: checkSelectAutoComplete,
            popupIndicator: classes.custSelectIcon,
          }}
          disabled={currentStep !== 0}
          options={data}
          disableUnderline
          getOptionLabel={(option) => option[label]}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.customMenuItem}
              classes={{
                root: classes.custOutlined,
              }}
              variant='outlined'
            />
          )}
          onChange={(event, value) => setSelected(value)}
          value={selected}
          disableClearable
          PopperComponent={(params) => <Popper {...params} style={isMobile ? {} : { width: 260 }} placement='bottom-end' />}
        />
      ) : (
        <Box className={isMobile ? classes.boxProgressSelectMobile : classes.boxProgressSelect}>
          <CircularProgress classes={{ root: classes.custProgress }} />
        </Box>
      )}
    </>
  )
}

export default SelectComponent
