import BannerEmpresa from 'assets/img/vmove/about-us-page/banner_empresas.jpg'
import { contentWrapper, orangeColor, dullOrangeColor, whiteColor } from 'assets/jss/style.js'
import { mt05r, mt1r, mt2r, mb1r, latoBold, latoRegular, fs16, fs18, fs20, fs24, fs25, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const aboutUsPage = {
  boxContentWrapper: {
    backgroundImage: `url(${BannerEmpresa})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'calc(100vh - 120px)',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  bgWhite: {
    background: `${whiteColor} !important`,
  },
  imgFluid: {
    width: '100%',
    height: '100%',
  },
  imageMobile: {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: '550px 70px',
  },
  containerDetailInfo: {
    position: 'relative',
    background: whiteColor,
    bottom: '20vh',
  },
  containerDetailInfoMobile: {
    position: 'relative',
    borderTopLeftRadius: '60px 35px',
    background: whiteColor,
    bottom: '4vh',
  },
  boxTitle: {
    padding: '1.2rem 0',
  },
  labelTitle: {
    color: dullOrangeColor,
    fontFamily: latoBold,
    textTransform: 'initial',
  },
  customDivider: {
    height: '3px',
    background: dullOrangeColor,
  },
  gridContainerBody: {
    padding: '3rem 2rem',
  },
  gridContainerBodyMobile: {
    padding: '2rem 1rem',
  },
  labelSubtitle: {
    fontFamily: latoBold,
    color: '#202124',
    textTransform: 'initial',
  },
  labelBody: {
    // marginLeft: "0.5rem",
    // textIndent: "0.5rem",
    color: '#727C8E',
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
  },
  labelHeader: {
    fontFamily: latoBold,
    textTransform: 'initial',
    color: dullOrangeColor,
  },
  boxMedia: {
    height: '301px',
    width: '100%',
  },
  boxYoutube: {
    width: '100%',
    height: '301px',
  },
  itemFlex: {
    display: 'flex',
  },
  boxImage: {
    backgroundImage: `url(${BannerEmpresa})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '177px',
    margin: '0 3px',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '177px',
  },
  boxCompanies: {
    padding: '5rem 2rem 10rem 2rem',
  },
  logoCompanies: {
    height: '69px',
  },
  imageLeaf: {
    width: '55px',
  },
  imageMoney: {
    height: '42px',
    width: '200px',
  },
  labelBodyMobile: {
    marginLeft: '1.2rem',
  },
  buttonOverlay: {
    border: '2px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    textTransform: 'initial',
    width: '100%',
  },
  boxWrapper: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    left: '0',
    opacity: '0',
    position: 'absolute',
    transition: '0.5s ease',
    width: '100%',
    '&:hover, &:visited': {
      background: 'linear-gradient(180deg, rgba(233,169,77,0) 65%, rgba(233,169,77,0) 65%, rgba(233,169,77,0.7) 65%);',
      opacity: '0.8',
    },
  },
  boxWrapperMobile: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    left: '0',
    opacity: '0',
    position: 'absolute',
    transition: '0.5s ease',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(233,169,77,0) 65%, rgba(233,169,77,0) 65%, rgba(233,169,77,0.7) 65%);',
    opacity: '0.8',
  },
  boxLabelInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    bottom: 10,
  },
  buttonLinkedIn: {
    padding: '2px',
    minWidth: '0',
  },
  iconLinkedIn: {
    color: '#0035B0',
  },
  labelBold: {
    fontFamily: latoBold,
    padding: 2,
    textAlign: 'center',
    fontSize: 15,
  },
  labelRegular: {
    fontFamily: latoRegular,
    padding: 2,
    textAlign: 'center',
    fontSize: 13,
  },
  contentWrapper,
  mt05r,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs32,
  fs50,
  fs60,
}

export default aboutUsPage
