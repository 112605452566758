import { orangeColor, whiteColor, dullOrangeColor } from 'assets/jss/style.js'
import { fs14, fs18, latoRegular, latoBold } from 'assets/jss/tools'

const reservationTabsStyle = {
  boxTabPanel: {
    padding: '25px 0',
  },
  customAppBar: {
    boxShadow: 'none',
  },
  customTab: {
    borderBottom: '10px solid #EFEFEF',
    color: '#7E7E7E',
    fontFamily: latoRegular,
    fontSize: '15px',
    letterSpacing: 'initial',
    padding: '0',
    textTransform: 'initial',
    whiteSpace: 'nowrap',
  },
  customTabSelected: {
    opacity: '1',
    color: orangeColor,
  },
  customIndicator: {
    background: orangeColor,
    borderRadius: '5px',
    height: '10px',
  },
  customTabPanel: {
    padding: '0',
  },
  brLeft: {
    borderRadius: '5px 0 0 5px',
  },
  brRight: {
    borderRadius: '0 5px 5px 0',
  },
  boxTabsTicket: {
    padding: '2rem 0',
  },
  boxSummary: {
    border: '1px solid #F29E6F',
    borderRadius: '10px',
    height: 'auto',
    opacity: '1',
  },
  customItemSummary: {
    alignItems: 'center',
    display: 'flex',
    padding: '0',
  },
  labelTotal: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    padding: '0.5rem 0',
    textTransform: 'uppercase',
  },
  customInputTotal: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    fontSize: '18px',
  },
  customInputTotalMobile: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    fontSize: '14px',
    padding: '0.5rem 0.5rem 0.5rem 0',
  },
  itemSummaryCategories: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemSummaryCategoriesMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px',
  },
  summaryCategorySelected: {
    color: '#727C8E',
    fontFamily: latoRegular,
    textTransform: 'initial',
  },
  summaryValueSelected: {
    marginLeft: '1.5rem',
  },
  summaryValueSelectedMobile: {
    marginLeft: '0.5rem',
  },
  buttonContinue: {
    fontSize: '18px',
    fontFamily: latoBold,
    height: '90px',
    borderRadius: '0 9px 9px 0',
    background: orangeColor,
    color: whiteColor,
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
    },
  },
  buttonContinueMobile: {
    background: orangeColor,
    borderRadius: '0 0 9px 9px',
    fontFamily: latoBold,
    fontSize: '14px',
    height: '50px',
    color: whiteColor,
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
    },
  },
  buttonOrange: {
    background: orangeColor,
  },
  customButtonDisabled: {
    background: dullOrangeColor,
    color: whiteColor + '!important',
  },
  containerResumeData: {
    padding: '0 1rem 0 2rem',
  },
  itemFullCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  itemFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customStepLabel: {
    color: '#7E7E7E',
    fontFamily: latoRegular,
  },
  customStepLabelActive: {
    color: orangeColor + '!important',
  },
  customStepLabelCompleted: {
    color: '#7E7E7E !important',
  },
  customStepButton: {
    fontFamily: latoRegular,
    textTransform: 'initial',
    padding: '5px 2px',
    letterSpacing: '0',
  },
  customBoxStepper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  customLineActive: {
    '& $customLine': {
      borderColor: orangeColor,
    },
  },
  customLineCompleted: {
    '& $customLine': {
      borderColor: orangeColor,
    },
  },
  customLine: {
    borderColor: '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
  customAlternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  customStepRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  boxStepIcon: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  boxStepIconActive: {
    color: orangeColor,
  },
  stepIncompleted: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  stepCompleted: {
    color: orangeColor,
    zIndex: 1,
    fontSize: 18,
  },
  formWebpay: {
    display: 'none',
  },
  fs14,
  fs18,
}

export default reservationTabsStyle
