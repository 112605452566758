import { contentWrapper, orangeColor, dullOrangeColor, whiteColor } from 'assets/jss/style.js'
import { mb1r, latoBold, latoRegular, fs16, fs18, fs20, fs25, fs50, fs60 } from 'assets/jss/tools.js'

const accountPage = {
  boxContentAccount: {
    paddingTop: '2rem',
    paddingBottom: '3rem',
  },
  boxPerfil: {
    background: whiteColor,
    borderRadius: '20px',
    minHeight: '660px',
  },
  boxPerfilMobile: {
    background: whiteColor,
    borderRadius: '20px',
    minHeight: 'auto',
  },
  boxAvatar: {
    padding: '2rem 1rem',
  },
  labelName: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    textTransform: 'capitalize',
  },
  boxLinks: {
    padding: '2rem 0rem 2rem 0rem',
  },
  boxPerfilNav: {
    background: whiteColor,
    borderRadius: '10px',
    minHeight: '660px',
  },
  customTabRoot: {
    height: 40,
    padding: '1rem 0rem 1rem 0rem',
  },
  customTabWrapper: {
    alignItems: 'center',
    color: '#111111',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: latoRegular,
    fontSize: '18px',
    textTransform: 'initial',
    justifyContent: 'start',
  },
  customTabIcon: {
    marginLeft: '2rem',
    marginBottom: '0 !important',
    marginRight: '1rem',
  },
  customTabSelected: {
    position: 'relative',
    width: '100%',
    top: 0,
    '& .MuiTab-wrapper': {
      color: '#FF5E05',
      background: '#F29E6F',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      height: 40,
    },
  },
  labelTitlePabel: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    textTransform: 'uppercase',
  },
  labelTitlePabelMobile: {
    color: '#7E7E7E',
    fontFamily: latoBold,
    textTransform: 'uppercase',
    paddingLeft: '25px',
  },
  boxProgressPerfil: {
    background: whiteColor,
    borderRadius: '20px',
    minHeight: '660px',
    width: '100%',
  },
  customProgress: {
    color: `${dullOrangeColor} !important`,
  },
  boxTabPanel: {
    padding: '2rem 3rem',
  },
  boxTabPanelMobile: {
    padding: '2rem 0rem',
  },
  boxContainerPerfil: {
    padding: '3rem 1.5rem',
    width: '100%',
  },
  customFormControlAccount: {
    margin: '1rem 0 0.5rem 0',
  },
  customLabel: {
    color: '#7E7E7E',
    fontSize: '16px',
    fontFamily: latoRegular,
    marginBottom: '0.2rem',
  },
  customFocused: {
    color: '#7E7E7E !important',
  },
  customTextField: {
    background: whiteColor,
    border: '2px solid #CDCDCD',
    borderRadius: '5px',
    color: '#7E7E7E',
    fontFamily: latoRegular,
    height: '47px',
    padding: '0 15px',
  },

  titleContact: {
    color: dullOrangeColor,
    fontFamily: latoBold,
  },
  containerFormContact: {
    padding: '1.5rem 0 2.5rem 0',
  },
  itemDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customFormControl: {
    marginBottom: '0.5rem',
  },
  customLabelContact: {
    color: '#7E7E7E',
    fontFamily: latoRegular,
    fontSize: '16px',
    marginBottom: '0.2rem',
  },
  customLabelFocused: {
    color: '#7E7E7E !important',
    fontFamily: latoBold,
  },
  customTextFieldContact: {
    background: whiteColor,
    border: '2px solid #CDCDCD',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    height: '47px',
    padding: '0 15px',
  },
  customTextAreaFieldContact: {
    background: whiteColor,
    border: '2px solid #CDCDCD',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    paddingLeft: '1rem',
  },
  customTextArea: {
    fontFamily: latoRegular,
    background: 'red',
  },
  buttonSend: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    fontSize: '20px',
    height: '63px',
    opacity: '0.9',
    textTransform: 'initial',
    width: '292px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  buttonSave: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    fontSize: '16px',
    height: '49px',
    opacity: '0.9',
    textTransform: 'initial',
    width: '228px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  iconPhone: {
    marginRight: '5px',
  },
  labelPhone: {
    alignItems: 'center',
    margin: '7px 0',
    color: '#7E7E7E',
    display: 'flex',
    fontFamily: latoRegular,
  },
  boxSave: {
    padding: '2rem 0',
    width: '100%',
  },
  customTextFieldSelect: {
    '& .MuiSelect-select': {
      alignItems: 'center',
      background: whiteColor,
      //boxShadow: '0px 3px 6px #00000029',
      borderRadius: '5px',
      color: '#7E7E7E',
      display: 'flex',
      fontFamily: latoRegular,
      height: '47px',
      padding: '0 15px',
    },
    '& .MuiSelect-icon': {
      color: orangeColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CDCDCD',
        border: '2px solid #CDCDCD',
      },
      '&:hover fieldset': {
        borderColor: '#CDCDCD',
        border: '2px solid #CDCDCD',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CDCDCD',
        border: '2px solid #CDCDCD',
      },
    },
  },
  boxInfoImportant: {
    paddingTop: '2rem',
  },
  labelInfo: {
    paddingTop: '3rem',
    color: '#CDCDCD',
    fontFamily: latoBold,
    textTransform: 'initial',
  },
  boxCreate: {
    paddingTop: '1rem',
    width: '100%',
  },
  contentWrapper,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs25,
  fs50,
  fs60,
}

export default accountPage
