import React, { useContext, useEffect, useState } from 'react'
import Context from '../../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/loginSelectorStyle.js'
import { useMediaQuery } from 'react-responsive'
import validate from './LoginFormValidationRules'

import { Backdrop, Box, Button, Fade, FormControl, Grid, Input, InputAdornment, Modal, Snackbar, Typography } from '@material-ui/core'

import Logo from '../../../assets/img/vmove/logos/vmove-logo-2.svg'
import Times from '../../../assets/img/vmove/icons/times.svg'
import Mail from '../../../assets/img/vmove/icons/mail.svg'
import Lock from '../../../assets/img/vmove/icons/lock.svg'
import { postRecoveryPassword } from 'provider/accessProvider/provider'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(styles)

const RenderModalLogIn = (props) => {
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const [forgotPassword, setForgotPassword] = useState(false)
  const [openSnack, setOpenSnack] = useState(false)
  const [recoverStep, setRecoverStep] = useState(0)

  const { logIn, setLogIn, setSignUp, setErrors, setLoginIsSubmitting, setValues, values, signUp, handleLogIn, errors } = props

  useEffect(() => setForgotPassword(false), [logIn])

  const handleSubmitRecover = (event) => {
    event.preventDefault()
    postRecoveryPassword(values)
      .then((res) => {
        setRecoverStep(1)
      })
      .catch((e) => {
        setOpenSnack(true)
        setRecoverStep(0)
      })
  }

  const handleChangeLogin = () => (event) => {
    if (logIn) {
      setLogIn(false)
      setSignUp(true)
    } else if (signUp) {
      setSignUp(false)
      setLogIn(true)
    }
  }

  const handleLogInSubmit = (event) => {
    if (event) event.preventDefault()
    setErrors(validate('LogIn', values))
    setLoginIsSubmitting(true)
  }

  const handleInputChange = (event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  const printErrors = (error) => {
    return (
      <Typography align='left' classes={{ root: classes.labelError }} variant='caption'>
        {t(error)}
      </Typography>
    )
  }

  const login = () => {
    return (
      <Box className={classes.paper}>
        <Box classes={{ root: classes.paperBackground }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <img alt='Logo' src={Logo} className={classes.logoModal} />
          <Typography className={classes.titleModal}>¡{t('waiting_for_you')}!</Typography>
          <Box display='flex' justifyContent='flex-end' classes={{ root: classes.boxTimes }}>
            <Button classes={{ root: classes.timesButton }} onClick={handleLogIn()}>
              <img alt='X' src={Times} />
            </Button>
          </Box>
        </Box>
        <Box className={classes.paperContent} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <form autoComplete='off' className={classes.loginForm} noValidate onSubmit={handleLogInSubmit}>
            <FormControl>
              <Input
                autoComplete='off'
                classes={{
                  focused: classes.customFocused,
                  root: classes.loginInput,
                }}
                disableUnderline
                id='input-with-icon-adornment'
                name='email'
                onChange={handleInputChange}
                placeholder={t('email')}
                required
                type='email'
                value={values.email || ''}
                startAdornment={
                  <InputAdornment position='start'>
                    <img alt='Mail' src={Mail} />
                  </InputAdornment>
                }
              />
              {errors.email && printErrors(errors.email)}
            </FormControl>
            <FormControl>
              <Input
                autoComplete='new-password'
                disableUnderline
                id='input-with-icon-adornment'
                name='password'
                onChange={handleInputChange}
                placeholder={t('password')}
                required
                type='password'
                variant='outlined'
                value={values.password || ''}
                classes={{
                  focused: classes.customFocused,
                  root: classes.loginInput,
                }}
                startAdornment={
                  <InputAdornment position='start'>
                    <img alt='Lock' src={Lock} />
                  </InputAdornment>
                }
              />
              {errors.password && printErrors(errors.password)}
            </FormControl>
            <Box className={isMobile ? classes.boxForgetPasswordMobile : classes.boxForgetPassword} display='flex' justifyContent='center'>
              <span onClick={() => setForgotPassword(true)} className={classes.linkForgetPassword}>
                ¿{t('forget_password')}?
              </span>
            </Box>
            <FormControl>
              <Button classes={{ root: classes.buttonModal }} type='submit'>
                {t('start_session')}
              </Button>
            </FormControl>
            <Box className={isMobile ? classes.boxRegisterMobile : classes.boxRegister} display='flex' justifyContent='space-between'>
              <p className={classes.withoutAccount}>¿{t('not_have_account')}?</p>
              <span className={classes.linkSignUp} onClick={handleChangeLogin()}>
                {t('sign_up_here')}
              </span>
            </Box>
          </form>
        </Box>
      </Box>
    )
  }

  const currentRecoverComponent = () => {
    switch (recoverStep) {
      case 0:
        return recoverPassword()
      case 1:
        return successfullRecover()
      default:
        return recoverPassword()
    }
  }

  const successfullRecover = () => {
    return (
      <Box className={classes.paper}>
        <Box classes={{ root: classes.paperBackground }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <img alt='Logo' src={Logo} className={classes.logoModal} />
          <Typography className={classes.titleModal}>Recuperación de la Cuenta</Typography>
          <Box display='flex' justifyContent='flex-end' classes={{ root: classes.boxTimes }}>
            <Button classes={{ root: classes.timesButton }} onClick={handleLogIn()}>
              <img alt='X' src={Times} />
            </Button>
          </Box>
        </Box>
        <Box className={classes.paperContent} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Box className={classes.recoverMessage}>
            <p>
              Se enviará un correo electrónico con un link para poder restablecer su contraseña. Revise su bandeja de entrada y corrobore
              esta información.
            </p>
          </Box>
        </Box>
      </Box>
    )
  }

  const recoverPassword = () => {
    return (
      <Box className={classes.paper}>
        <Box classes={{ root: classes.paperBackground }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <img alt='Logo' src={Logo} className={classes.logoModal} />
          <Typography className={classes.titleModal}>Recuperación de la cuenta</Typography>
          <Box display='flex' justifyContent='flex-end' classes={{ root: classes.boxTimes }}>
            <Button classes={{ root: classes.timesButton }} onClick={handleLogIn()}>
              <img alt='X' src={Times} />
            </Button>
          </Box>
        </Box>
        <Box className={classes.paperContent} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Box className={classes.recoverMessage}>
            <p>Para obtener un código de verificación, primero confirma la dirección de correo electrónico que deseas recuperar.</p>
          </Box>
          <form autoComplete='off' className={classes.loginForm} noValidate onSubmit={(event, val) => handleSubmitRecover(event)}>
            <FormControl>
              <Input
                autoComplete='off'
                classes={{
                  focused: classes.customFocused,
                  root: classes.loginInput,
                }}
                disableUnderline
                id='input-with-icon-adornment'
                name='email'
                onChange={handleInputChange}
                placeholder={t('email')}
                required
                type='email'
                value={values.email || ''}
                startAdornment={
                  <InputAdornment position='start'>
                    <img alt='Mail' src={Mail} />
                  </InputAdornment>
                }
              />
              {errors.email && printErrors(errors.email)}
            </FormControl>
            <Box className={isMobile ? classes.boxForgetPasswordMobile : classes.boxForgetPassword} display='flex' justifyContent='center'>
              <span onClick={() => setForgotPassword(false)} className={classes.linkForgetPassword}>
                ¿Ya tienes cuenta? ¡Ingresa Aquí!
              </span>
            </Box>
            <FormControl>
              <Button classes={{ root: classes.buttonModal }} type='submit'>
                Enviar
              </Button>
            </FormControl>
            <Box className={isMobile ? classes.boxRegisterMobile : classes.boxRegister} display='flex' justifyContent='space-between'>
              <p className={classes.withoutAccount}>¿{t('not_have_account')}?</p>
              <span className={classes.linkSignUp} onClick={handleChangeLogin()}>
                {t('sign_up_here')}
              </span>
            </Box>
          </form>
        </Box>
        <Snackbar
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={openSnack}
          onClose={() => setOpenSnack(false)}
          autoHideDuration={4000}
        >
          <Alert severity='error'>El correo ingresado no se encuentra registrado.</Alert>
        </Snackbar>
      </Box>
    )
  }

  return (
    <Modal
      className={classes.modal}
      open={logIn}
      onClose={() => {
        setForgotPassword(false)
        setRecoverStep(0)
        handleLogIn()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Grid container display='flex' justify='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
          <Fade in={logIn}>{!forgotPassword ? <>{login()}</> : <>{currentRecoverComponent()}</>}</Fade>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default RenderModalLogIn
