import { orangeColor } from "assets/jss/style.js";
import { latoBold, latoRegular } from "assets/jss/tools.js";

const languageSelectorStyle = {
  buttonFlag: {
    borderRadius: "0",
    height: "25px",
    minWidth: "0",
    overflow: "hidden",
    padding: "0",
    width: "25px"
  },
  buttonSelector: {
    borderRadius: "0",
    color: orangeColor,
    fontFamily: latoRegular,
    height: "25px",
    minWidth: "0",
    overflow: "hidden",
    padding: "0",
    width: "25px"
  },
  customFlag: {
    height: "auto",
    maxWidth: "100%"
  },
  labelSelected: {
    fontFamily: latoBold
  },
  labelNotSelected: {
    fontFamily: latoRegular
  },
  boxProgress: {
    alignItems: "center",
    display: "flex !important",
    height: "100%",
    justifyContent: "center",
    width: "100%"
  },
  customProgress: {
    color: orangeColor,
    height: "20px",
    width: "20px"
  }
};
export default languageSelectorStyle;
