const createPrivateEventStyle = {
  container: {
    padding: 20,
    maxHeight: 660,
    overflow: 'auto',
    overflowX: 'hidden',
  },
}

export default createPrivateEventStyle
