import {
  contentWrapper,
  contentWrapperMobile,
  darkBlueColor,
  orangeColor,
  whiteColor
} from "assets/jss/style.js";
import {
  mt1r,
  mt2r,
  mb1r,
  latoBold,
  latoRegular,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs30,
  fs32,
  fs50,
  fs60
} from "assets/jss/tools.js";

const boyfriendsPage = {
  containerEvents: {
    padding: "2rem 0"
  },
  boxHeaderEvents: {
    padding: "2rem 0"
  },
  titleEvent: {
    color: darkBlueColor,
    fontFamily: latoBold,
    marginLeft: "0.5rem",
    textTransform: "uppercase"
  },
  gridContainerList: {
    paddingBottom: "3rem",
    minHeight: "50vh"
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  boxCircularProgress: {
    height: "400px"
  },
  customProgress: {
    color: `${orangeColor} !important`
  },
  labelSuccess: {
    color: darkBlueColor,
    fontFamily: latoRegular
  },
  labelError: {
    color: darkBlueColor,
    fontFamily: latoRegular
  },
  boxSuccess: {
    padding: "1rem"
  },
  boxError: {
    background: "red",
    borderRadius: "5px",
    color: whiteColor,
    padding: "0.5rem 1rem"
  },
  iconResponse: {
    marginRight: "0.5rem"
  },
  linkAccount: {
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: "17px",
    textTransform: "initial",
    "&:hover, &:visited": {
      color: orangeColor
    }
  },
  contentWrapper,
  contentWrapperMobile,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs30,
  fs32,
  fs50,
  fs60
};

export default boyfriendsPage;
