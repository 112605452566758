import React, { useContext } from 'react'
import Context from '../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/views/eventPageSections/reservationTabsStyle.js'
import Check from '@material-ui/icons/Check'
import { Box, Button, Step, StepConnector, StepLabel, Stepper } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(styles)

export default function ReservationTabs(props) {
  const { steps, activeStep, setActiveStep } = props
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  const handleBack = (index) => {
    setActiveStep(index)
  }

  const QontoStepIcon = (checker) => {
    const { active, completed } = checker

    return (
      <Box
        classes={{ root: classes.boxStepIcon }}
        className={active ? classes.boxStepIconActive : null}
      >
        {completed ? (
          <Check className={classes.stepCompleted} />
        ) : (
          <Box className={classes.stepIncompleted} />
        )}
      </Box>
    )
  }

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      classes={{
        root: isMobile ? classes.customStepRoot : null,
      }}
      connector={
        <StepConnector
          classes={{
            active: classes.customLineActive,
            alternativeLabel: classes.customAlternativeLabel,
            completed: classes.customLineCompleted,
            line: classes.customLine,
          }}
        />
      }
    >
      {steps.map((step, index) => (
        <Step key={index} classes={{ root: isMobile ? classes.customStepRoot : null }}>
          <StepLabel
            StepIconComponent={QontoStepIcon}
            classes={{
              active: classes.customStepLabelActive,
              alternativeLabel: classes.customStepLabel,
              completed: classes.customStepLabelCompleted,
              labelContainer: classes.labelContainer,
            }}
          >
            <Button
              disabled={activeStep <= index}
              onClick={() => handleBack(index)}
              classes={{ root: classes.customStepButton }}
              className={activeStep === index ? classes.customStepLabelActive : null}
            >
              {isMobile ? step.icon : t(step.label)}
            </Button>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
