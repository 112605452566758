import { response, responseImg, responseXlsx } from './client'

export const getUserInfo = () => {
  const options = {
    url: `/shop-api/me`,
  }
  return response(options)
}

export const putUserInfo = (data) => {
  const options = {
    method: 'PUT',
    url: `/shop-api/me`,
    data,
  }
  return response(options)
}

export const getPurchaseHistory = () => {
  const options = {
    url: `/shop-api/payment-history`,
  }
  return response(options)
}

export const setPayment = (data) => {
  const options = {
    method: 'POST',
    url: `/shop-api/init-payment`,
    data,
  }
  return response(options)
}

export const getTicketDetail = (id, query) => {
  if(query){    
    const options = {
      url: `/shop-api/order-detail/${id}${query}`,
    }
    return response(options)
  }
  else{    
    const options = {
      url: `/shop-api/order-detail/${id}`,
    }
    return response(options)
  }
}

export const postCreatePrivateEvent = (data) =>{
  const options={
    method: 'POST',
    url: '/shop-api/request-events',
    data,
  }
  return response(options)
}

export const putCreatePrivateEvent = (id, data) =>{
  const options={
    method: 'PUT',
    url: `/shop-api/request-events/${id}`,
    data,
  }
  return response(options)
}

export const postCreatePrivateEventImage = (id, data) =>{
  const options={
    method: 'POST',
    url: `/shop-api/event-requests/${id}/upload-image`,
    data,
  }
  return responseImg(options)
}

export const postCreatePrivateEventExcel = (id, data) =>{
  const options={
    method: 'POST',
    url: `/shop-api/event-requests/${id}/upload-guests`,
    data,
  }
  return responseXlsx(options)
}

export const getPrivateEventList = (data) => {
  const options={
    url: `shop-api/event-requests?state=${data}`,
  }
  return response(options)
}

export const getPrivateEventById = (id) => {
  const options={
    url: `shop-api/event-requests/${id}`,
  }
  return response(options)
}

export const getInvitedEvents = () => {
  const options={
    url: `shop-api/invited-events`,
  }
  return response(options)
}

export const getInvitedEventById = (eventId) => {
  const options={
    url: `api/events/${eventId}`,
  }
  return response(options)
}

export const getFrequentFriends = () => {
  const options={
    url: `/shop-api/frequent-friends`,
  }
  return response(options)
}

export const postFrequentFriends = (data) => {
  const options={
    method: 'POST',
    url: `/shop-api/frequent-friends`,
    data,
  }
  return response(options)
}

export const getFrequentAddresses = () => {
  const options={
    url: `/shop-api/frequent-addresses`,
  }
  return response(options)
}

export const postFrequentAddresses = (data) => {
  const options={
    method: 'POST',
    url: `/shop-api/frequent-addresses`,
    data,
  }
  return response(options)
}

export const putFrequentAddresses = (id) => {
  const options={
    method: 'PUT',
    url: `/shop-api/frequent-addresses/${id}/my-address`,
  }
  return response(options)
}

export const getCoupon = (couponCode, eventId) => {
  const options={
    url: `shop-api/discounts/${couponCode}/event/${eventId}`,
  }
  return response(options)
}