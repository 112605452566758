import PeopleAtConcert from 'assets/img/vmove/faq-page/people-at-concert.png'
import { contentWrapper, orangeColor, dullOrangeColor, whiteColor } from 'assets/jss/style.js'
import { mt1r, mt2r, mb1r, latoBold, latoRegular, fs16, fs18, fs20, fs24, fs25, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const faqPage = {
  boxWrapper: {
    backgroundImage: `url(${PeopleAtConcert})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: 'calc(100vh - 120px)',
    justifyContent: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  bgWhite: {
    background: `${whiteColor} !important`,
  },
  imgFluid: {
    width: '100%',
    height: '100%',
  },
  imageMobile: {
    width: '100%',
    height: '100%',
    // borderBottomRightRadius: "350px 63px",
    borderBottomRightRadius: '550px 70px',
  },
  containerDetailInfo: {
    position: 'absolute',
    bottom: '0',
  },
  boxTitle: {
    padding: '2rem 0',
  },
  labelTitle: {
    color: orangeColor,
    fontFamily: latoBold,
    textTransform: 'initial',
  },
  customAppBar: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '1rem 0',
  },
  customTab: {
    color: whiteColor,
    fontFamily: latoRegular,
    padding: '0',
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
    },
  },
  boxTabPanel: {
    height: 'auto',
  },
  boxButton: {
    padding: '3rem 0 4rem 0',
  },
  buttonBack: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    height: '63px',
    opacity: '0.9',
    width: '292px',
    textTransform: 'initial',
    '&:hover,&:focus': {
      color: whiteColor,
      background: orangeColor,
      opacity: '1',
    },
  },
  customArrow: {
    color: dullOrangeColor,
  },
  customLinkFaq: {
    color: '#727C8E',
    display: 'flex',
    fontFamily: latoRegular,
    alignItems: 'center',
  },
  customDivider: {
    height: '3px',
    background: dullOrangeColor,
  },
  gridContainerBody: {
    padding: '3rem 2rem',
  },
  gridContainerBodyMobile: {
    padding: '2rem 1rem',
  },
  labelSubtitle: {
    fontFamily: latoBold,
    color: '#202124',
    textTransform: 'initial',
  },
  labelBody: {
    fontFamily: latoRegular,
    textTransform: 'initial',
    color: '#727C8E',
  },
  labelHeader: {
    fontFamily: latoBold,
    textTransform: 'initial',
    color: orangeColor,
  },
  boxMedia: {
    height: '301px',
  },
  boxYoutube: {
    maxWidth: '100%',
    height: '301px',
  },
  itemFlex: {
    display: 'flex',
  },
  boxImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '177px',
    margin: '0 3px',
    width: '177px',
  },
  boxCompanies: {
    padding: '5rem 2rem 10rem 2rem',
  },
  logoCompanies: {
    height: '69px',
  },
  customNavTabIndicator: {
    background: orangeColor,
  },
  pb10: {
    paddingBottom: 10,
  },
  pt50: {
    paddingTop: 50,
  },
  pt20: {
    paddingTop: 20,
  },
  contentWrapper,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs32,
  fs50,
  fs60,
}

export default faqPage
