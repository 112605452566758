import React, { Fragment, useContext, useEffect, useState } from 'react'
import Context from '../../../src/context/Context'
import { Grid } from '@material-ui/core'
import CountrySelector from './LanguageSelector/CountrySelector'
import LocaleSelector from './LanguageSelector/LocaleSelector'
import CustomCircularProgress from '../../components/CircularProgress/CustomCircularProgress'

import { getCountriesPage, getLocalesPage } from 'provider/publicProvider/provider'

export default function LanguageSelector() {
  const [boolCountries, setBoolCountries] = useState(false)
  const [boolLocale, setBoolLocale] = useState(false)
  const contextType = useContext(Context)
  const { getCountries, getCountry, getLocales, setCountries, setLocales } = contextType

  useEffect(() => {
    const getAllLocales = () => {
      getLocalesPage(getCountry()).then((res) => {
        setBoolLocale(true)
        setLocales(res)
      })
    }
    const getAllCountries = () => {
      getCountriesPage().then((res) => {
        setBoolCountries(true)
        setCountries(res)
        getAllLocales()
      })
    }
    getAllCountries()
  }, [boolCountries, boolLocale])

  return (
    <Fragment>
      {getCountries().length > 0 && getLocales().length > 0 ? (
        <Fragment>
          {/* <CountrySelector countries={getCountries()} />
          <LocaleSelector locales={getLocales()} /> */}
        </Fragment>
      ) : (
        // <Grid item>
        //   <CustomCircularProgress size={20} />
        // </Grid>
        <></>
      )}
    </Fragment>
  )
}
