import { contentWrapper, contentWrapperMobile, darkBlueColor, orangeColor, whiteColor } from 'assets/jss/style.js'
import { mt1r, mt2r, mb1r, latoBold, fs16, fs18, fs20, fs24, fs25, fs30, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const massiveEventsPage = {
  containerEvents: {
    padding: '2rem 0',
  },
  boxHeaderEvents: {
    padding: '2rem 0',
  },
  titleEvent: {
    color: darkBlueColor,
    fontFamily: latoBold,
    marginLeft: '0.5rem',
    textTransform: 'uppercase',
  },
  gridContainerList: {
    paddingBottom: '3rem',
    minHeight: '50vh',
  },
  gridContainer: {
    paddingBottom: '3rem',
    minHeight: '50vh',
    maxWidth: '800px',
    margin: 'auto',
    padding: '0 15px',
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonBack: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    height: '63px',
    marginTop: '1rem',
    opacity: '0.9',
    textTransform: 'initial',
    width: '292px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  boxCircularProgress: {
    height: '400px',
  },
  customProgress: {
    color: `${orangeColor} !important`,
  },
  contentWrapper,
  contentWrapperMobile,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs30,
  fs32,
  fs50,
  fs60,
}

export default massiveEventsPage
