import React, { useContext, useState, useEffect } from 'react'
import Context from '../../context/Context'
import { useMediaQuery } from 'react-responsive'
import styles from 'assets/jss/views/massiveEventsPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import EventCards from '../../components/EventCards/EventCards'
import SearchIcon from '@material-ui/icons/Search'
import { Box, Button, CircularProgress, Container, Grid, Grow, Typography } from '@material-ui/core'

import { setSearch } from 'provider/publicProvider/provider'

const useStyles = makeStyles(styles)

export default function SearcherPage(props) {
  const classes = useStyles()
  const [eventList, setEventList] = useState([])
  const [loading, setLoading] = useState(true)
  const [openGrow, setOpenGrow] = useState(false)
  const contextType = useContext(Context)
  const { t, getSearch } = contextType
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const customJustify = isMobile ? 'center' : 'initial'

  useEffect(() => {
    if (getSearch()) {
      setSearch(getSearch()).then((response) => {
        setOpenGrow(false)
        setEventList(response)
      })
    }
  }, [getSearch()])

  useEffect(() => {
    if (eventList.length > 0) {
      setOpenGrow(true)
      setLoading(false)
    } else {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [eventList])

  return (
    <Box className={isMobile ? classes.contentWrapperMobile : classes.contentWrapper}>
      <Container>
        <Box display='flex' alignItems='center' justifyContent={customJustify} classes={{ root: classes.boxHeaderEvents }}>
          <SearchIcon fontSize='large' />
          <Typography classes={{ root: classes.titleEvent }} className={isMobile ? classes.fs16 : classes.fs30}>
            {t('search_results')}
          </Typography>
        </Box>
        <Grid alignContent='center' alignItems='center' container className={classes.gridContainerList} justify='center' spacing={2}>
          {eventList.length > 0 ? (
            eventList.map((event) => {
              return (
                <Grow in={openGrow} timeout={1000}>
                  <Grid
                    alignContent='center'
                    alignItems='center'
                    container
                    justify='center'
                    style={{ padding: 10 }}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                  >
                    <EventCards key={event.id} event={event} isSearch={true} bgColor='#8e98a7' />
                  </Grid>
                </Grow>
              )
            })
          ) : (
            <Grid item xs={12} md={12} classes={{ root: classes.itemFullCenter }} className={classes.boxCircularProgress}>
              {loading ? (
                <CircularProgress classes={{ root: classes.customProgress }} />
              ) : (
                <Typography>{'No se han encontrado resultados'}</Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Link to='/'>
              <Button classes={{ root: classes.buttonBack }} variant='contained' className={isMobile ? classes.fs16 : classes.fs18}>
                {t('back_to_main_screen')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
