import BannerNovios from 'assets/img/vmove/about-us-page/banner_novios.jpg'
import { contentWrapper, orangeColor, dullOrangeColor, whiteColor } from 'assets/jss/style.js'
import { mt05r, mt1r, mt2r, mb1r, latoBold, latoRegular, fs16, fs18, fs20, fs24, fs25, fs32, fs50, fs60 } from 'assets/jss/tools.js'

const boyfriendsPage = {
  itemDirectionColumnVideo: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px !important',
  },
  boxContentWrapper: {
    backgroundImage: `url(${BannerNovios})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'calc(100vh - 120px)',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  bgWhite: {
    background: `${whiteColor} !important`,
  },
  imgFluid: {
    width: '100%',
    height: '100%',
  },
  imageMobile: {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: '550px 70px',
  },
  containerDetailInfo: {
    position: 'relative',
    background: whiteColor,
    bottom: '20vh',
  },
  containerDetailInfoMobile: {
    position: 'relative',
    borderTopLeftRadius: '60px 35px',
    background: whiteColor,
    bottom: '4vh',
  },
  boxTitle: {
    padding: '1.2rem 0',
  },
  labelTitle: {
    color: dullOrangeColor,
    fontFamily: latoBold,
    textTransform: 'initial',
  },
  customDivider: {
    height: '3px',
    background: dullOrangeColor,
  },
  gridContainerBody: {
    padding: '3rem 2rem',
  },
  gridContainerBodyMobile: {
    padding: '2rem 1rem',
  },
  labelSubtitle: {
    fontFamily: latoBold,
    color: '#202124',
    textTransform: 'initial',
  },
  labelBody: {
    // marginLeft: "0.5rem",
    // textIndent: "0.5rem",
    color: '#727C8E',
    fontFamily: latoRegular,
    fontSize: '16px',
    textTransform: 'initial',
  },
  labelBodySuccessWeb: {
    // marginLeft: "0.5rem",
    // textIndent: "0.5rem",
    marginTop: '160px !important'
  },
  labelHeader: {
    fontFamily: latoBold,
    textTransform: 'initial',
    color: orangeColor,
  },
  boxMedia: {
    height: '301px',
    width: '100%',
  },
  boxYoutube: {
    width: '100%',
    height: '301px',
  },
  itemFlex: {
    display: 'flex',
  },
  boxImage: {
    backgroundImage: `url(${BannerNovios})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '177px',
    margin: '0 3px',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '177px',
    '&:hover, &:visited': {
      opacity: '0.8',
    },
  },
  boxCompanies: {
    padding: '5rem 2rem 10rem 2rem',
  },
  logoCompanies: {
    height: '69px',
  },
  imageLeaf: {
    width: '55px',
  },
  imageMoney: {
    height: '42px',
    width: '200px',
  },
  labelBodyMobile: {
    marginLeft: '1.2rem',
  },
  buttonOverlay: {
    border: '2px solid',
    borderColor: whiteColor,
    borderRadius: '24px',
    color: whiteColor,
    textTransform: 'initial',
    width: '100%',
  },
  boxWrapper: {
    alignItems: 'center',
    bottom: '0',
    color: '#2699FB',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    left: '0',
    opacity: '0',
    padding: '1rem 0',
    position: 'absolute',
    right: '0',
    top: '0',
    transition: '0.5s ease',
    width: '100%',
    '&:hover, &:visited': {
      background: '#F1F9FF',
      opacity: '0.8',
    },
  },
  boxLabelInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '1rem',
    width: '100%',
  },
  buttonLinkedIn: {
    padding: '2px',
    minWidth: '0',
  },
  iconLinkedIn: {
    color: '#0035B0',
  },
  labelBold: {
    fontFamily: latoBold,
  },
  labelRegular: {
    fontFamily: latoRegular,
  },
  containerFormContact: {
    padding: '1.5rem 0 2.5rem 0',
  },
  containerVideo: {
    padding: '2rem 0 2.5rem 0',
  },
  itemDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemFullCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customFormControl: {
    marginBottom: '0.5rem',
  },
  customLabelContact: {
    color: '#7E7E7E',
    fontFamily: latoRegular,
    fontSize: '16px',
    marginBottom: '0.2rem',
  },
  customLabelFocused: {
    color: '#7E7E7E !important',
    fontFamily: latoBold,
  },
  customTextFieldContact: {
    background: whiteColor,
    border: '2px solid #CDCDCD',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    height: '47px',
    padding: '0 15px',
  },
  customTextAreaFieldContact: {
    background: whiteColor,
    border: '2px solid #CDCDCD',
    borderRadius: '5px',
    color: '#CDCDCD',
    fontFamily: latoRegular,
    paddingLeft: '1rem',
  },
  buttonSend: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    fontSize: '20px',
    height: '63px',
    opacity: '0.9',
    textTransform: 'initial',
    width: '292px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  customTextFieldOutlined: {
    '& .MuiInputLabel-outlined': {
      color: '#8E98A7',
      fontFamily: latoRegular,
      top: '0px !important',
    },
    '& label.Mui-focused': {
      color: '#8E98A7',
      top: '0px !important',
    },
    '& .MuiOutlinedInput-input ': {
      borderRadius: '5px',
      height: '47px',
      color: '#8E98A7',
      fontFamily: latoRegular,
      padding: '0 15px',
    },
    '& .MuiOutlinedInput-input:focus ': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#8E98A7',
      color: '#8E98A7',
      textTransform: '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8E98A7',
      },
      '&:hover fieldset': {
        borderColor: '#8E98A7',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8E98A7',
      },
    },
    '& muiInputBase-input:focus': {
      color: '#8E98A7',
      fontFamily: latoRegular,
    },
  },
  contentWrapper,
  mt05r,
  mt1r,
  mt2r,
  mb1r,
  fs16,
  fs18,
  fs20,
  fs24,
  fs25,
  fs32,
  fs50,
  fs60,
}

export default boyfriendsPage
