import React, { useContext } from 'react'
import { AccordionSummary, Button, Grid, Typography } from '@material-ui/core/'
import { useMediaQuery } from 'react-responsive'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const SummaryPayment = (props) => {
  const { placeAddress, index, expanded, handleChange, subcategoryName } = props
  const contextType = useContext(Context)
  const { getLanguage } = contextType

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  return (
    <AccordionSummary
      classes={{ content: classes.disableHover, root: classes.summaryCategory }}
      aria-controls='panel1bh-content'
      id='panel1bh-header'
    >
      <Grid container justify='space-between' className={isMobile ? classes.containerTicketsMobile : classes.containerTickets}>
        <Grid item xs={8} md={8} className={classes.itemFullCenter}>
          <Button
            classes={{ root: expanded ? classes.buttonTicketExpanded : classes.buttonTicket }}
            className={isMobile ? classes.fs14 : classes.fs16}
            onClick={handleChange(`panel${index}`)}
          >
            {`Ticket ${index + 1}`}
          </Button>
          <Typography className={classes.ml10}>{`${subcategoryName[getLanguage()].name} - ${placeAddress.split(',')[0]}`}</Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  )
}

export default SummaryPayment
