import { Component } from 'react'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.children.props.location.pathname !== prevProps.children.props.location.pathname
    ) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return this.props.children
  }
}

export default ScrollToTop
