import Stars from "assets/img/vmove/landing-page/stars_wallpaper.jpg";
import { whiteColor } from "assets/jss/style.js";
import {
  latoRegular,
  fs12,
  fs14,
  fs16,
  fs18,
  fs20,
  fs25,
  fs30
} from "assets/jss/tools.js";

const comunityStyle = {
  boxComunity: {
    backgroundImage: `url(${Stars})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "250px"
  },
  itemComunity: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem"
  },
  itemFullCenter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  comunityTitle: {
    borderBottom: "1px solid",
    borderColor: whiteColor,
    borderTop: "1px solid",
    color: whiteColor,
    fontFamily: latoRegular,
    fontWeight: "normal"
  },
  itemBody: {
    border: "1px solid #707070",
    borderRadius: "2px",
    color: whiteColor,
    minHeight: "153px",
    opacity: "1",
    padding: "20px 20px !important"
  },
  imageSocial: {
    margin: "0 10px"
  },
  customCard: {
    background: "transparent",
    borderRadius: "0",
    boxShadow: "none",
    color: whiteColor,
    height: "100%",
    margin: "0"
  },
  labelComunityHeader: {
    fontFamily: latoRegular,
    fontStyle: "italic"
  },
  labelComunityName: {
    fontFamily: latoRegular
  },
  labelHashtag: {
    color: "#FF9A11",
    fontFamily: latoRegular,
    fontStyle: "italic"
  },
  cover: {
    maxWidth: "100%"
  },
  boxAvatar: {
    marginTop: "10px"
  },
  boxLabelAvatar: {
    marginLeft: "0.5rem"
  },
  spanHashtag: {
    marginRight: "4px"
  },
  fs12,
  fs14,
  fs16,
  fs18,
  fs20,
  fs25,
  fs30
};

export default comunityStyle;
