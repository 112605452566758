import React, { useEffect } from 'react'

const Mercadopago = (props) => {
  const { paymentResponse } = props
  const { preference_id, preference_init_point } = paymentResponse

  useEffect(() => {
    if (preference_init_point !== '') {
      document.getElementById('formPayment').click()
    }
  }, [preference_init_point])

  return (
    <div>
      <a href={preference_init_point} id='formPayment'></a>
    </div>
  )
}

export default Mercadopago
