import React, { Fragment, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { Box } from '@material-ui/core'

import MediaQuery from 'react-responsive'

import ContentBar from './ContentBar.js'
import ContentBarMobile from './ContentBarMobile.js'
import Context from '../../context/Context'
import HeaderLinks from 'components/Header/HeaderLinks.js'
import InfoBar from './InfoBar.js'

import Logo from 'assets/img/vmove/vmove-logo.png'
import styles from 'assets/jss/components/headerStyle.js'

import { getChannelsPage } from 'provider/publicProvider/provider'

const useStyles = makeStyles(styles)

export default function Header() {
  const contextType = useContext(Context)
  const { getCountry, setCountry, setLanguage } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  useEffect(() => {
    const getChannels = () => {
      getChannelsPage().then((res) => {
        if (!getCountry()) {
          setCountry(res.countryCode)
          setLanguage(res.defaultLocaleCode)
        }
      })
    }
    getChannels()
  }, [getCountry() /* , getLanguage() */])

  return (
    <Box classes={{ root: classes.boxHeader }}>
      {isMobile ? (
        <ContentBarMobile />
      ) : (
        <Fragment>
          <MediaQuery minDeviceWidth={960}>
            <InfoBar />
          </MediaQuery>
          <ContentBar
            brand={Logo}
            rightLinks={<HeaderLinks />}
            changeColorOnScroll={{
              height: 1000,
              color: 'orange',
            }}
          />
        </Fragment>
      )}
    </Box>
  )
}
