import { orangeColor } from "assets/jss/style.js";

const infoBarStyle = {
  infoBar: {
    alignItems: "center",
    background: "white",
    boxShadow: "none",
    color: orangeColor,
    display: "flex",
    flexFlow: "row",
    height: "40px",
    minHeight: "30%",
    position: "relative",
    width: "100%"
  }
};

export default infoBarStyle;
