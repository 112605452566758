import React, { useContext } from 'react'
import { AccordionSummary, Box, Button, Grid, Typography } from '@material-ui/core/'
import { useMediaQuery } from 'react-responsive'
import Context from 'context/Context'

import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'
import PopoverDetail from 'components/Popover/PopoverDetail'

const useStyles = makeStyles(styles)

const SummaryAddress = (props) => {
  const { placeAddress, index, stops, expanded, quantity, subcategoryName, handleChange } = props

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })
  const contextType = useContext(Context)
  const { getLanguage, t } = contextType

  return (
    <AccordionSummary
      classes={{ content: classes.disableHover, root: classes.summaryCategory }}
      aria-controls='panel1bh-content'
      id='panel1bh-header'
    >
      <Grid container justify='space-between' className={isMobile ? classes.containerTicketsMobile : classes.containerTickets}>
        <Grid item xs={8} md={8} className={classes.itemFullCenter}>
          <Button
            classes={{ root: expanded ? classes.buttonTicketExpanded : classes.buttonTicket }}
            className={isMobile ? classes.fs14 : classes.fs16}
            onClick={handleChange(`panel${index}`)}
          >
            {`Ticket ${index + 1}`}
          </Button>
          <Typography className={classes.ml10}>{`${subcategoryName[getLanguage()].name} - ${placeAddress.split(',')[0]}`}</Typography>
          {isMobile ? null : (
            <div className={classes.info}>
              <PopoverDetail index={index} paragraph={`${t('information_to_add_addresses')} ${stops} ${t('addresses').toLowerCase()}.`} />
            </div>
          )}
        </Grid>
        <Grid item xs={4} md={4} className={classes.itemFullCenterEnd}>
          {!expanded ? (
            <Box className={classes.boxQuantity}>
              <span className={classes.labelQuantity}>{`${quantity}/${stops}`}</span>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </AccordionSummary>
  )
}

export default SummaryAddress
