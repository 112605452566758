import { contentWrapper, contentWrapperMobile } from 'assets/jss/style.js'

const orderDetailPage = {
  gridContainer: {
    border: '2px solid',
    borderRadius: 40,
    borderColor: '#F19D6F',
    borderWidth: 10,
  },
  imageErrorIcon: {
    margin: 'auto',
    display: 'flex',
    height: 150,
    width: 150,
    color: '#F29E6F',
  },
  imageIcon: {
    margin: 'auto',
    display: 'flex',
    width: 150,
  },
  imageIconCanceled: {
    margin: 'auto',
    display: 'flex',
    width: 150,
    transform: 'scaleY(-1)',
  },
  title: {
    fontWeight: '500',
    fontSize: '2.6rem',
    color: '#666666',
  },
  titleMobile: {
    fontWeight: '500',
    fontSize: '1rem',
    color: '#666666',
  },
  subTitle: {
    fontSize: '5rem',
    fontWeight: '400',
    color: '#7F7F7F',
    lineHeight: 2,
  },
  subTitleMobile: {
    fontSize: '1.5rem',
    fontWeight: '400',
    color: '#7F7F7F',
    lineHeight: 2,
  },
  subTitleName: {
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
  body: {
    lineHeight: 'initial',
    fontSize: '2.15rem',
    color: '#707070',
  },
  bodyMobile: {
    lineHeight: 'initial',
    fontSize: '1rem',
    color: '#707070',
  },
  containerDetailInfo: {
    padding: 40,
    background: 'white',
  },
  mb25: {
    marginBottom: 25,
  },
  detailStrong: {
    marginTop: 5,
    fontSize: '1rem',
  },
  pt0: {
    paddingTop: '0px !important',
  },
  pt12: {
    paddingTop: '12px !important',
  },
  fullHeight: {
    height: 'calc(80vh - 120px)',
  },
  contentWrapper,
  contentWrapperMobile,
}

export default orderDetailPage
