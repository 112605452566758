import { orangeColor } from "assets/jss/style.js";

const headerStyle = {
  appbarMobile: {
    background: orangeColor,
    boxShadow: "none",
    height: "90px"
  },
  toolbarMobile: {
    minHeight: "90px"
  },
  boxHeader: {
    height: "120px",
    position: "fixed",
    width: "100%",
    zIndex: "1100"
  },
  menuButton: {
    color: "white"
  }
};

export default headerStyle;
