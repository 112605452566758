import React, { useContext, useState } from 'react'
import Context from '../../../context/Context'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/components/searchFormStyle.js'

import { Button, FormControl, Input, InputAdornment } from '@material-ui/core'
import Search from '../../../assets/img/vmove/icons/search.svg'
import { Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(styles)

export default function SearchForm(props) {
  const contextType = useContext(Context)
  const { t, setSearch } = contextType
  const [searchInput, setSearchInput] = useState('')
  const [searchActive, setSearchActive] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })
  const classes = useStyles()

  const handleChange = (event) => {
    setSearchInput(event.target.value)
    setSearchActive(false)
  }

  const handleClick = () => {
    setSearch(searchInput)
    setSearchActive(true)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <>
      {searchActive && (
        <>
          <Redirect to='/search' />
        </>
      )}
      <FormControl className={classes.customFormControl}>
        <Input
          disableUnderline
          id='input-with-icon-adornment'
          placeholder={t('search')}
          variant='outlined'
          className={isMobile ? `${classes.searchInput} ${classes.fs20}` : `${classes.searchInput} ${classes.fs14}`}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position='end'>
              <Button onClick={() => handleClick()}>
                <img alt='Search' src={Search} />
              </Button>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  )
}
