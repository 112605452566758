import React, { useEffect } from 'react'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

const ForcedSelectObject = (props) => {
  const { name, label, data, setFieldValue } = props
  const classes = useStyles()

  useEffect(() => {
    setFieldValue(name, data[0].id)
  }, [])

  return (
    <FormControl classes={{ root: classes.customTextFieldSelect }}>
      <Field
        disabled={true}
        autocomplete='off'
        as={TextField}
        label={label}
        name={`${name}`}
        setValue={data[0].id}
        variant='outlined'
        value={data[0].name}
      >
        <MenuItem value={data[0].id}>{data[0].name}</MenuItem>
      </Field>
    </FormControl>
  )
}

export default ForcedSelectObject
