import {
  contentWrapperMobile,
  darkBlueColor,
  dullOrangeColor,
  whiteColor,
  orangeColor,
} from "assets/jss/style.js";
import {
  latoRegular,
  latoBold,
  fs16,
  fs30,
  mb1_5r,
  mt1r,
  mt1_5r,
} from "assets/jss/tools.js";

const eventsSectionStyle = {
  contentWrapperMobile,
  sectionEvent: {
    height: "auto",
  },
  massiveEventList: {
    display: "grid",
    width: 246,
  },
  eventFeatured: {
    background: "white",
    minHeight: "50vh",
    padding: "50px 0",
  },
  featuredEventMobile: {
    background: "white",
    minHeight: "50vh",
    padding: "93px 2rem 2rem 2rem",
  },
  boxFeatured: {
    background: dullOrangeColor,
    color: whiteColor,
    minHeight: "38px",
    padding: "1px 10px",
  },
  massiveEvents: {
    background: whiteColor,
    height: "auto",
    minHeight: "50vh",
    padding: "50px 0",
  },
  massiveEventsMobile: {
    background: whiteColor,
    height: "auto",
    minHeight: "50vh",
    padding: "1.5rem 2rem 3rem 2rem",
  },
  titleEvent: {
    color: darkBlueColor,
    fontFamily: latoBold,
    marginLeft: "0.5rem",
    textTransform: "uppercase",
  },
  allReservations: {
    fontFamily: latoRegular,
    fontSize: "20px",
  },
  gridContainerList: {
    minHeight: "25vh",
  },
  gridContainerMassiveEvents: {
    minHeight: "25vh",
    justifyContent: "center",
    marginTop: 4,
  },
  iconMobile: {
    width: "26px",
  },
  boxCircularProgress: {
    height: '150px',
    alignItems: 'center',
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
  },
  bgWhite: {
    background: `${whiteColor} !important`,
  },
  customCardEvent: {
    background: "transparent",
    boxShadow: "none",
  },
  textSeeMoreFoot: {
    textAlign: "center",
    color: orangeColor,
    fontFamily: latoBold,
    textDecoration: "none",
    "&:visited": {
      color: orangeColor,
      fontFamily: latoBold,
      textDecoration: "none",
    },
    "&:hover": {
      color: orangeColor,
      fontFamily: latoBold,
      textDecoration: "none",
    },
    "&:active": {
      color: orangeColor,
      fontFamily: latoBold,
      textDecoration: "none",
    },
  },
  fs16,
  fs30,
  latoBold,
  mb1_5r,
  mt1_5r,
  mt1r,
};

export default eventsSectionStyle;
