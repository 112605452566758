import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styles from "assets/jss/views/eventPageSections/generalEventsStyles.js";
import PaymentList from "components/Payment/PaymentList";
import Context from "context/Context";

import { getPaymentMethods } from "provider/publicProvider/provider";
import TicketDetails from "components/TicketDetails/TicketDetails";

const useStyles = makeStyles(styles);

const PaymentTab = (props) => {
  const {
    setActiveStep,
    tickets,
    paymentMethod,
    setPaymentMethod,
    paymentType,
    eventId,
  } = props;
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const contextType = useContext(Context);
  const { t, getCountry } = contextType;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });

  const handlePaymentMethod = (method) => (event) => {
    setPaymentMethod(method);
  };

  useEffect(() => {
    const getPaymentMethodList = () => {
      getPaymentMethods(getCountry()).then((res) => {
        setPaymentMethodList(res);
      });
    };
    getPaymentMethodList();
  }, []);

  return (
    <>
      <Grid justify="space-between" alignItems="center" container>
        <Typography
          classes={{ root: classes.addressTitle }}
          className={isMobile ? classes.fs18 : classes.fs20}
        >
          {t("detail_of_your_reservation")}
        </Typography>
        <Button
          classes={{ root: classes.buttonEditTicket }}
          className={isMobile ? classes.fs14 : classes.fs16}
          startIcon={<ArrowBackIcon />}
          onClick={() => setActiveStep(2)}
        >
          {t("go_back")}
        </Button>
      </Grid>

      <TicketDetails tickets={tickets} />

      {paymentType === 1 ? (
        paymentMethodList.length > 0 ? (
          <PaymentList
          tickets={tickets}
            paymentMethod={paymentMethod}
            paymentMethodList={paymentMethodList}
            handlePaymentMethod={handlePaymentMethod}
            setPaymentMethod={setPaymentMethod}
            eventId={eventId}
          />
        ) : null
      ) : (
        <Typography
        align='center'
        classes={{ root: classes.invitedEventPayment }}
        className={isMobile ? classes.fs20 : classes.fs25}
        style={{paddingTop: '3%', paddingBottom: '3%'}}
      >
        {'Los viajes a este evento son financiados por el organizador, por lo que no debes pagar por el viaje.'}
      </Typography>
      )}
    </>
  );
};

export default PaymentTab;
