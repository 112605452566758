import React, { useContext } from 'react'
import styles from 'assets/jss/views/orderDetailPage.js'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import Context from 'context/Context'
import { useMediaQuery } from 'react-responsive'
import ThumbUp from 'assets/img/vmove/icons/thumb_up.svg'

const useStyles = makeStyles(styles)

const CancelledOrderState = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={12}>
        <img alt='Thumb Up' src={ThumbUp} className={classes.imageIconCanceled} />
      </Grid>
      <Grid item xs={12} sm={12} className={classes.mb25}>
        <Typography align={'center'} variant='h3' className={isMobile ? classes.titleMobile : classes.title}>
          {t('payment_info')}
        </Typography>
        <Typography align={'center'} className={isMobile ? classes.subTitleMobile : classes.subTitle}>
          {t('incorrectly')}
        </Typography>
        <Typography align={'center'} className={isMobile ? classes.bodyMobile : classes.body}>
          {t('payment_problem_1')}
        </Typography>
        <Typography align={'center'} className={isMobile ? classes.bodyMobile : classes.body}>
          {t('payment_problem_2')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CancelledOrderState
