import { orangeColor } from "assets/jss/style";

const customCircularProgressStyle = {
  boxProgress: {
    alignItems: "center",
    display: "flex !important",
    height: "100%",
    justifyContent: "center",
    width: "100%"
  },
  customProgress: {
    color: orangeColor
  }
};

export default customCircularProgressStyle;
