import React, { useContext, Fragment } from 'react'
import Context from '../../context/Context'
import { NavLink } from 'react-router-dom'
import { Box, Button, Container, Grid, List, ListItem, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Logo from 'assets/img/vmove/logos/vmove-logo-white.png'
import Webpay from 'assets/img/vmove/footer/webpay-plus.png'
import styles from 'assets/jss/components/footerDesktopStyle.js'

const useStyles = makeStyles(styles)

export default function FooterDesktop(props) {
  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()

  return (
    <Fragment>
      <Container>
        <Grid
          container
          alignItems='flex-start'
          alignContent='center'
          justify='center'
          spacing={4}
          className={classes.gridContainer}
        >
          <Grid item xs={12} md className={classes.itemLogo}>
            <NavLink to='/'>
              <Button>
                <img alt='VMove' src={Logo} />
              </Button>
            </NavLink>
          </Grid>
          <Grid item xs={12} md>
            <Typography className={classes.title}>{t('menu')}</Typography>
            <List classes={{ root: classes.customList }}>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  exact
                  to='/'
                  className={classes.customLink}
                  activeClassName={classes.customLinkActive}
                >
                  {t('home')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/about-us'
                  className={classes.customLink}
                  activeClassName={classes.customLinkActive}
                >
                  {t('about_us')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/vmove-companies'
                  className={classes.customLink}
                  activeClassName={classes.customLinkActive}
                >
                  {t('vmove_companies')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/how-book'
                  className={classes.customLink}
                  activeClassName={classes.customLinkActive}
                >
                  {t('how_to_book')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link href = {process.env.REACT_APP_HELP_LINK_PRIVACIDAD} className={classes.customLink}>
                  Privacidad
                </Link>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link href = {process.env.REACT_APP_HELP_LINK_CONDICIONES_GENERALES} className={classes.customLink}>
                  Condiciones Generales
                </Link>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link href = {process.env.REACT_APP_HELP_LINK_CONDICIONES_USO} className={classes.customLink}>
                  Condiciones de Uso
                </Link>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link href = {process.env.REACT_APP_HELP_LINK_COOKIES} className={classes.customLink}>
                  Cookies
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md>
            <Typography className={classes.title}>{t('events')}</Typography>
            <List classes={{ root: classes.customList }}>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/massive-events'
                  className={classes.customLink}
                  activeClassName={classes.customLinkActive}
                >
                  {t('massive_events')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/private-events'
                  activeClassName={classes.customLinkActive}
                  className={classes.customLink}
                >
                  {t('private_events')}
                </NavLink>
              </ListItem>
              {/* <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to="/routes"
                  activeClassName={classes.customLinkActive}
                  className={classes.customLink}
                >
                  {t("routes")}
                </NavLink>
              </ListItem> */}
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/wedding'
                  activeClassName={classes.customLinkActive}
                  className={classes.customLink}
                >
                  {t('boyfriends')}
                </NavLink>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md>
            <Typography className={classes.title}>{t('we')}</Typography>
            <List classes={{ root: classes.customList }}>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/contact'
                  activeClassName={classes.customLinkActive}
                  className={classes.customLink}
                >
                  {t('contact')}
                </NavLink>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <NavLink
                  to='/faq'
                  activeClassName={classes.customLinkActive}
                  className={classes.customLink}
                >
                  {t('frequent_questions')}
                </NavLink>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md>
            <img className={classes.imgWebpay} alt='Webpay' src={Webpay} />
          </Grid>
        </Grid>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          classes={{ root: classes.boxCopyRight }}
        >
          <Typography align='center' className={classes.copyRight + ' ' + classes.upperText}>
            {t('copy_right')}
          </Typography>
          <Typography align='center' className={classes.copyRight}>
            {t('address')}
          </Typography>
        </Box>
      </Container>
    </Fragment>
  )
}
