import {
  orangeColor,
  grayColorModal,
  whiteColor,
  grayColor,
} from "assets/jss/style.js";
import {
  latoRegular,
  latoBold,
  fs12,
  fs14,
  fs16,
  fs18,
  m_0,
} from "assets/jss/tools.js";
import { dullOrangeColor } from "../style";

const loginSelectorStyle = {
  buttonLogin: {
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: "16px",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "initial",
    "&:hover, &:visited": {
      color: orangeColor,
    },
  },
  buttonLoginMobile: {
    border: "0.5px solid #F6F6F6",
    borderRadius: "13px",
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "0",
    lineHeight: "1",
    textTransform: "initial",
    "&:hover, &:visited": {
      color: whiteColor,
    },
  },
  buttonLoginEventMobile: {
    border: "0.5px solid",
    borderColor: orangeColor,
    borderRadius: "13px",
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "0",
    lineHeight: "1",
    textTransform: "initial",
    marginBottom: "15px",
    "&:hover, &:visited": {
      color: whiteColor,
      backgroundColor: orangeColor,
    },
  },
  buttonOnlyLogin: {
    color: orangeColor,
    fontFamily: latoRegular,
    fontSize: "18px",
    fontWeight: "normal",
    letterSpacing: "0",
    textTransform: "initial",
    "&:hover, &:visited": {
      color: orangeColor,
    },
  },
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "10px",
    height: "auto",
    position: "relative",
    background:
      "linear-gradient(to left," +
      grayColorModal +
      " 50%," +
      orangeColor +
      " 50%)",
  },
  logoModal: {
    height: "100px",
  },
  titleModal: {
    color: whiteColor,
    fontFamily: latoRegular,
    fontSize: "23px",
    margin: "0",
    marginTop: "10px",
    textTransform: "initial",
  },
  paperBackground: {
    backgroundColor: orangeColor,
    borderBottomRightRadius: "50px 50px",
    borderRadius: "10px 10px",
    height: "auto",
    padding: "20px 0",
    position: "relative",
    width: "100%",
  },
  boxTimes: {
    paddingRight: "20px",
    position: "absolute",
    top: "20px",
    width: "100%",
  },
  timesButton: {
    minWidth: "0",
  },
  paperContent: {
    backgroundColor: "#F6F6F6",
    borderRadius: "0 0 10px 10px",
    borderTopLeftRadius: "50px 50px",
    minHeight: "150px",
    padding: "30px 0 30px 0",
  },
  paperContentMobile: {
    backgroundColor: "#F6F6F6",
    borderRadius: "0 0 10px 10px",
    borderTopLeftRadius: "50px 50px",
    minHeight: "150px",
    padding: "20px 0 10px 0",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
  },

  loginInput: {
    background: whiteColor,
    border: "1px solid #CDCDCD",
    borderRadius: "12px",
    color: grayColor,
    fontFamily: latoRegular,
    fontSize: "14px",
    height: "55px",
    margin: "5px 0 5px 0",
    opacity: "1",
    padding: "0 15px",
    width: "282px",
  },
  loginInputAuto: {
    background: whiteColor,
    border: "1px solid #CDCDCD",
    borderRadius: "12px",
    color: grayColor,
    fontFamily: latoRegular,
    fontSize: "14px",
    height: "55px",
    opacity: "1",
    padding: "0 15px",
    width: "100%",
    
  },
  customFormControl: {
    display: "flex",
    "&.react-tel-input &.form-control": {
        "&:focus": {
          borderColor: "#red",
        },
    },
  },
  customFocused: {
    borderColor: orangeColor,
  },
  boxForgetPassword: {
    padding: "10px 0 45px 0",
  },
  boxForgetPasswordMobile: {
    padding: "10px 0 10px 0",
  },
  boxTermsAndConditions: {
    padding: "10px 0 45px 0",
    maxWidth: "282px",
  },
  boxTermsAndConditionsMobile: {
    padding: "10px 0 5px 0",
    maxWidth: "282px",
  },
  recoverMessage: {
    fontSize: "14px",
    maxWidth: "400px",
    textAlign: "center",
    fontFamily: latoRegular,
    marginBottom: "10px",
  },
  linkForgetPassword: {
    color: "#707070",
    fontFamily: latoRegular,
    fontSize: "16px",
    opacity: "0.9",
    "&:hover, &:visited": {
      color: "#707070",
      opacity: "1",
      cursor: "pointer",
    },
  },
  linkAcceptTerms: {
    color: orangeColor,
    fontFamily: latoBold,
    fontSize: "14px",
    opacity: "0.9",
    "&:hover, &:visited": {
      color: orangeColor,
      opacity: "1",
      cursor: "pointer",
    },
  },
  buttonModal: {
    background: orangeColor,
    borderRadius: "25px",
    color: whiteColor,
    fontFamily: latoBold,
    fontSize: "16px",
    height: "50px",
    opacity: "0.9",
    "&:hover": {
      color: whiteColor,
      background: orangeColor,
      opacity: "1",
    },
  },
  buttonModalAuto: {
    background: orangeColor,
    borderRadius: "25px",
    color: whiteColor,
    fontFamily: latoBold,
    fontSize: "16px",
    height: "50px",
    opacity: "0.9",
    width: "100%",
    "&:hover": {
      color: whiteColor,
      background: orangeColor,
      opacity: "1",
    },
  },
  customLabelStart: {
    textAlign: "start",
  },
  boxRegister: {
    padding: "30px 0 20px 0",
  },
  boxRegisterMobile: {
    padding: "10px 0 5px 0",
  },
  withoutAccount: {
    fontFamily: latoRegular,
    fontSize: "13px",
    margin: "0",
  },
  alReadyUser: {
    fontFamily: latoRegular,
    fontSize: "14px",
    margin: "0",
  },
  linkSignUp: {
    color: orangeColor,
    fontFamily: latoBold,
    fontSize: "13px",
    opacity: "0.9",
    "&:hover, &:visited": {
      color: orangeColor,
      opacity: "1",
      cursor: "pointer",
    },
  },
  linkLogIn: {
    color: orangeColor,
    fontFamily: latoBold,
    fontSize: "14px",
    marginLeft: "0.5rem",
    opacity: "0.9",
    "&:hover, &:visited": {
      color: orangeColor,
      opacity: "1",
      cursor: "pointer",
    },
  },
  h50vh: {
    height: "50vh !important",
  },
  mb5p: {
    marginBottom: "5px",
  },
  mr10p: {
    marginRight: 10,
  },
  customMenu: {
    borderRadius: "0",
    color: "#707070",
    top: "40px !important",
  },
  customMenuList: {
    padding: "0",
  },
  customMenuItem: {
    fontFamily: latoRegular,
    color: "#707070",
  },
  customLisItemText: {
    fontFamily: latoRegular,
  },
  customMenuIcon: {
    color: orangeColor,
    minWidth: 30,
  },
  customProgress: {
    color: `${dullOrangeColor} !important`,
    height: "20px !important",
    width: "20px !important",
  },
  customLink: {
    alignItems: "center",
    color: "#707070",
    display: "flex",
    "&:hover, &:visited": {
      color: "#707070",
    },
  },
  labelError: {
    color: "#FF0000",
    fontFamily: latoRegular,
  },
  dismissNotification: {
    color: whiteColor,
  },
  timeAndDate: {
    padding: "10px 10px",
    "&.datepicker-input::-webkit-calendar-picker-indicator": {
      color: orangeColor,
      backgroundColor: whiteColor,
    },
  },
  fs12,
  fs14,
  fs16,
  fs18,
  m_0,
};
export default loginSelectorStyle;
