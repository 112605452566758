import React from 'react'
import TickerOrder from './Detail/TicketOrder'
import DetailOrder from './Detail/DetailOrder'

const Order = (props) => {
  const { tickets, order, event } = props.detail

  return (
    <>
      <DetailOrder order={order} event={event} />
      <TickerOrder tickets={tickets} />
    </>
  )
}

export default Order
