import React, { useState, useContext, useEffect } from "react";
import Context from "../../../context/Context";

import {
  Box,
  Container,
  CircularProgress,
  Grid,
  Typography,
  createMuiTheme,
} from "@material-ui/core";
import EventList from "../../EventPage/EventList.js";
import EventListFive from "../../../components/EventCards/EventCardsFive.js";
import orange from "@material-ui/core/colors/orange";
import { ThemeProvider } from "@material-ui/styles";

import { useMediaQuery } from "react-responsive";
import Van from "../../../assets/img/vmove/icons/van.svg";
import Star from "../../../assets/img/vmove/icons/star.svg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/landingPageSections/eventsSectionStyle.js";

import {
  getRecommendedEvents,
  getEventsByType,
} from "provider/publicProvider/provider";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function EventSection() {
  const [eventList, setEventList] = useState([]);
  const [recommendedEventList, setRecommendedEventList] = useState([]);
  const contextType = useContext(Context);
  const { getLanguage, getCountry, t } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [loaderEventList, setLoaderEventList] = useState(true);
  const [loaderRecommendedEventList, setLoaderRecommendedEventList] = useState(
    true
  );

  useEffect(() => {
    const getRecommendedEventList = (recommendedList) => {
      const result = recommendedList.map((event) => event.regularEvent);
      return result;
    };
    const getRecommendedList = () => {
      getRecommendedEvents()
        .then((res) => {
          setRecommendedEventList(getRecommendedEventList(res));
        })
        .finally(() => {
          setLoaderRecommendedEventList(false);
        });
    };
    const getEventList = async () => {
      getEventsByType(getCountry(), 1)
        .then((res) => {
          setEventList(res.slice(0, 20));
        })
        .finally(() => {
          setLoaderEventList(false);
        });
    };
    getRecommendedList();
    getEventList();
  }, [getCountry(), getLanguage()]);

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  return (
    <Box className={classes.sectionEvent} id="sectionEvent">
      <Box
        className={
          isMobile
            ? classes.featuredEventMobile + " " + classes.bgWhite
            : classes.eventFeatured
        }
      >
        <Container>
          <Box
            display="flex"
            alignItems="center"
            classes={{ root: classes.mb1_5r }}
            className={isMobile ? classes.mt1_5r : null}
          >
            <img
              alt="van"
              src={Van}
              className={isMobile ? classes.iconMobile : null}
            />
            <Typography
              classes={{ root: classes.titleEvent }}
              className={isMobile ? classes.fs16 : classes.fs30}
            >
              {t("recommended_transfers")}
            </Typography>
          </Box>
          {loaderRecommendedEventList === true ? (
            <Grid
              item
              xs={12}
              md={12}
              classes={{ root: classes.itemFullCenter }}
              className={classes.boxCircularProgress}
            >
              <CircularProgress classes={{ root: classes.customProgress }} />
            </Grid>
          ) : (
            <ThemeProvider theme={defaultMaterialTheme}>
              <EventList
                eventList={recommendedEventList}
                isRecommended={true}
              />
            </ThemeProvider>
          )}
        </Container>
      </Box>
      {!isMobile ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          classes={{ root: classes.boxFeatured }}
        >
          <Typography align="center" className={classes.allReservations}>
            *{t("all_reservations_include")}.
          </Typography>
        </Box>
      ) : null}
      <Box
        className={
          isMobile
            ? classes.massiveEventsMobile + " " + classes.bgWhite
            : classes.massiveEvents
        }
      >
        <Container>
          <Box
            display="flex"
            alignItems="center"
            classes={{ root: classes.mb1_5r }}
          >
            <img
              alt="start"
              src={Star}
              className={isMobile ? classes.iconMobile : null}
            />
            <Typography
              classes={{ root: classes.titleEvent }}
              className={isMobile ? classes.fs16 : classes.fs30}
            >
              {t("transfer_to_mass_events")}
            </Typography>
          </Box>
          <Grid
            alignContent="center"
            alignItems="center"
            className={classes.gridContainerMassiveEvents}
            container
            justify="center"
            spacing={2}
          >
            {loaderEventList === true ? (
              <Grid
                item
                xs={12}
                md={12}
                classes={{ root: classes.itemFullCenter }}
                className={classes.boxCircularProgress}
              >
                <ThemeProvider theme={defaultMaterialTheme}>
                  <CircularProgress
                    classes={{ root: classes.customProgress }}
                  />
                </ThemeProvider>
              </Grid>
            ) : eventList.length > 0 ? (
              eventList.map((event, index) => {
                return (
                  <div className={classes.massiveEventList}>
                    <EventListFive event={event} bgColor="#8e98a7" />
                  </div>
                );
              })
            ) : (
              <Typography
                classes={{ root: classes.titleEvent }}
                className={isMobile ? classes.fs16 : classes.fs20}
              >
                {"Sin eventos para mostrar"}
              </Typography>
            )}
          </Grid>
          <Grid item={true} md={12} xs={12} style={{ textAlign: "center" }}>
            <Link to="/massive-events" className={classes.textSeeMoreFoot}>
              VER MÁS
            </Link>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
