import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'formik'
import { Accordion, Box } from '@material-ui/core/'
import styles from 'assets/jss/views/eventPageSections/categoryTabStyle.js'
import SummaryCategory from 'components/Accordion/Summary/SummaryCategory'
import DetailsCategory from 'components/Accordion/Details/DetailsCategory'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const CategoryTab = (props) => {
  const [expanded, setExpanded] = useState('panel0')
  const { oldCitySelected, citySelected, categories, calculateTotal } = props

  const contextType = useContext(Context)
  const { getLogStatus } = contextType

  const classes = useStyles()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (getLogStatus()) {
      setExpanded('panel0')
    }
  }, [getLogStatus()])

  return (
    <div className={classes.root} style={{paddingTop: '2%'}}>
      <FieldArray>
        {(fieldArrayProps) => {
          const { form } = fieldArrayProps
          const { values } = form
          var contenedor = values
          return (
            <>
              {categories.map((category, index) => {
                return (
                  <div>
                  <Box className={expanded === `panel${index}` ? classes.boxCategoryOptionOpen : classes.boxCategoryOption} key={index}>
                    <Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={getLogStatus() ? handleChange(`panel${index}`) : setExpanded(false)}
                      classes={{
                        rounded: classes.boxCategoryOptionBorder,
                      }}
                    >
                      <SummaryCategory
                        citySelected={citySelected}
                        category={category}
                        expanded={expanded === `panel${index}`}
                        categoriesLength={categories.length}
                      />
                      <DetailsCategory
                        citySelected={citySelected}
                        oldCitySelected={oldCitySelected}
                        category={category}
                        contenedor={contenedor}
                        calculateTotal={calculateTotal}
                      />
                    </Accordion>
                  </Box></div>
                )
              })}
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default CategoryTab
