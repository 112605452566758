import {
  contentWrapper,
  //contentWrapperMobile,
  dullOrangeColor,
  orangeColorRgba,
  orangeColor,
  whiteColor,
} from "assets/jss/style.js";
import {
  m1r,
  mt1r,
  mb1r,
  latoBold,
  latoRegular,
  fs14,
  fs16,
  fs18,
  fs20,
  fs25,
  fs30,
  fs40,
  fs50,
  fs60,
} from "assets/jss/tools.js";

const detailStyle = {
  contentWrapper,
  contentWrapperMobile: {
    paddingTop: "20%",
  },
  eventDetailWrapper: {
    height: "50vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    background: "gray",
    filter: "blur(2px)",
  },
  eventDetailCenterWrapper: {
    height: "50vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    background: "transparent",
  },
  itemDetailWrapper: {
    margin: "0 auto",
    height: "50vh",
    width: "50%",
    position: "absolute",
    top: "74px",
  },
  boxWrapper: {
    width: "100%",
  },
  containerDetailWrapper: {
    position: "absolute",
  },
  boxDetailWrapper: {
    "& p": {
      color: whiteColor,
      fontFamily: latoBold,
      lineHeight: "normal",
      margin: "0",
    },
  },
  containerDetailInfo: {
    background: whiteColor,
    bottom: "20vh",
    position: "relative",
  },
  containerDetailInfoMobile: {
    position: "relative",
    background: whiteColor,
  },
  gridContainerDetailInfo: {
    padding: "45px 0",
  },
  gridContainerDetailInfoMobile: {
    padding: "15px 0 45px 0",
  },
  containerContentInfo: {
    background: dullOrangeColor,
    minHeight: "265px",
    padding: "20px 0",
  },
  boxContentInfo: {
    background: dullOrangeColor,
    minHeight: "200px",
    padding: "40px 30px",
    width: "100%",
  },
  boxTitleInfo: {
    "& p": {
      color: whiteColor,
      fontFamily: latoRegular,
      lineHeight: "normal",
      marginBottom: "10px",
    },
  },
  buttonBack: {
    background: orangeColor,
    borderRadius: '43px',
    color: whiteColor,
    fontFamily: latoBold,
    height: '63px',
    marginTop: '1rem',
    opacity: '0.9',
    textTransform: 'initial',
    width: '292px',
    '&:hover': {
      background: orangeColor,
      color: whiteColor,
      opacity: '1',
    },
  },
  logoArena: {
    maxWidth: "100%",
  },
  logoArenaMobile: {
    maxWidth: "50%",
  },
  paragraphHeaderInfo: {
    color: whiteColor,
    fontFamily: latoRegular,
    lineHeight: "normal",
  },
  itemFullCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemFullColumnCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  itemTicketReservation: {
    display: "flex",
  },
  serviceTitle: {
    color: "#7E7E7E",
    fontFamily: latoBold,
    textTransform: "uppercase",
  },
  custSelect: {
    paddingRight: "5px !important",
  },
  custOutlined: {
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#ffffff !important",
    },
  },
  boxProgressSelect: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    height: "33px",
    justifyContent: "center",
    width: "115px",
  },
  custProgress: {
    color: whiteColor,
    height: "20px !important",
    width: "20px !important",
  },
  boxProgressSelectMobile: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    display: "flex",
    height: "33px",
    justifyContent: "center",
    width: "50%",
  },
  selectOptionAutoComplete: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "center",
    minWidth: "150px",
    padding: "0 30px 0 0!important",
    textTransform: "initial",
    "&:focus": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "center",
      minWidth: "150px",
      textTransform: "initial",
    },
  },
  selectOptionAutoCompleteMobile: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "center",
    minHeight: "33px",
    padding: "0 !important",
    textTransform: "initial",
    width: "100%",
    "&:focus": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "center",
      textTransform: "initial",
    },
  },
  selectOption: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "start",
    minWidth: "102px",
    paddingLeft: "0.5rem",
    textTransform: "initial",
    "&:focus": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "start",
      minWidth: "102px",
      textTransform: "initial",
    },
  },
  selectOptionMobile: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "start",
    minHeight: "33px",
    padding: "0",
    paddingLeft: "0.5rem",
    textTransform: "initial",
    width: "100%",
    "&:focus": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "start",
      textTransform: "initial",
    },
  },
  w50: {
    width: "50% !important",
  },
  buttonCity: {
    fontFamily: latoBold,
    fontSize: "12px",
    minHeight: "33px",
    opacity: "0.9",
    textTransform: "initial",
    minWidth: "115px",
  },
  buttonDate: {
    fontFamily: latoBold,
    fontSize: "12px",
    minHeight: "33px",
    opacity: "0.9",
    textTransform: "initial",
    minWidth: "115px",
  },
  boxOptionSelect: {
    alignItems: "center",
    background: whiteColor,
    borderRadius: "40px 0px 0px 40px",
    boxShadow: "0px 3px 6px #00000029",
    color: "#7E7E7E",
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "center",
    minHeight: "33px",
    minWidth: "115px",
    opacity: "0.9",
    padding: "0 5px",
    textTransform: "initial",
  },
  boxOptionSelectMobile: {
    alignItems: "center",
    background: whiteColor,
    borderRadius: "40px 0px 0px 40px",
    boxShadow: "0px 3px 6px #00000029",
    color: "#7E7E7E",
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "center",
    minHeight: "33px",
    opacity: "0.9",
    textTransform: "initial",
    width: "50%",
  },
  boxServicesIncludes: {
    margin: "3rem 0",
  },
  boxServicesIncludesMobile: {
    marginTop: "3rem",
  },
  containerServicesInclude: {
    padding: "2rem 0",
  },
  textBodyServices: {
    color: "#7E7E7E",
    fontFamily: latoRegular,
    textAlign: "center",
    width: "60%",
  },
  boxDetailCenter: {
    width: "700px",
    height: "100%",
  },
  boxInfoCenter: {
    bottom: "0",
    color: "white",
    maxHeight: "300px",
    maxWidth: "500px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    position: "absolute",
  },
  boxContentInfoSeparator: {
    padding: "0 3rem",
  },
  infoIcon: {
    color: orangeColorRgba,
    height: "5em",
    width: "5em",
  },
  dividerVertical: {
    border: "1px solid",
    borderColor: orangeColorRgba,
    height: "170px",
    margin: "0 10px",
  },
  dividerVerticalHeaderInfo: {
    border: "1px dashed",
    borderColor: orangeColorRgba,
    height: "170px",
  },
  labelInfo: {
    color: "gray",
    textAlign: "center",
  },
  dividerHeaderInfo: {
    border: "1px dashed",
    borderColor: orangeColorRgba,
    width: "100%",
  },
  boxIconSeparator: {
    maxWidth: "50%",
    width: "50%",
    overflow: "hidden",
  },
  itemIconSeparator: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: "20px 0",
  },
  itemDividerSeparator: {
    alignItems: "center",
    display: "flex",
    height: "165px",
    justifyContent: "center",
  },
  imgFluid: {
    width: "100%",
    height: "auto",
  },
  imgMobile: {
    width: "100%",
    height: "auto",
  },
  containerPage: {
    paddingTop: "60px",
  },
  title: {
    color: "#fa651d",
    fontSize: "35px",
    margin: "0 !important",
  },
  boxLink: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  prevLink: {
    color: "#a0a0a0 !important",
  },
  currentLink: {
    color: "#fa651d !important",
  },
  // linkLabel: {
  //   color: "#fa651d !important",
  //   "&:focus": {
  //     color: "#fa651d !important"
  //   }
  // },
  separator: {
    borderStyle: "solid",
    borderColor: "#fa651d",
    marginTop: "-10px",
    marginBottom: "15px",
  },
  itemSpacing: {
    padding: "0.5rem 0",
  },
  customItem: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0",
    paddingRight: "0",
  },
  customItemText: {
    margin: "0",
    color: orangeColorRgba,
  },
  mt1r,
  root: {
    flexGrow: 1,
    backgroundColor: "red",
  },
  itemButton: {
    alignItems: "center",
    display: "flex",
  },
  itemButtonMobile: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "2rem",
  },
  selectOpen: {
    display: "block !important",
    position: "absolute",
    zIndex: -1000,
  },
  selectClose: {
    display: "none !important",
  },
  customProgress: {
    color: `${whiteColor} !important`,
    height: "20px !important",
    width: "20px !important",
  },
  customProgressLarge: {
    color: `${whiteColor} !important`,
    height: "40px !important",
    width: "40px !important",
  },
  boxButton: {
    width: "50%",
  },
  boxButtonMobile: {
    padding: "0.5rem 0",
    width: "100%",
  },
  boxCustomProgress: {
    background: dullOrangeColor,
    height: "265px",
  },
  customMenuItem: {
    fontFamily: latoRegular,
  },
  buttonDisabled: {
    cursor: "not-allowed !important",
    pointerEvents: "all !important",
  },
  custSelectIcon: {
    color: whiteColor,
  },
  custSelectIconSelect: {
    color: whiteColor,
    right: "10px",
  },
  custButtonDatetIconSelect: {
    color: whiteColor,
    marginRight: "14px",
  },
  custAutoCompleteDisabled: {
    color: whiteColor,
  },
  vanImage: {
    width: "124px",
  },
  buttonDateOption: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "start",
    minWidth: "102px",
    height: "33px",
    paddingLeft: "0.5rem",
    textTransform: "initial",
    "&:hover": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "start",
      minWidth: "102px",
      textTransform: "initial",
    },
    "&:disabled": {
      color: whiteColor,
    },
  },
  buttonDateOptionMobile: {
    alignItems: "center",
    background: dullOrangeColor,
    borderRadius: "0px 40px 40px 0px",
    boxShadow: "0px 3px 6px #00000029",
    color: whiteColor,
    display: "flex",
    fontFamily: latoBold,
    fontSize: "12px",
    justifyContent: "start",
    minHeight: "33px",
    padding: "0",
    paddingLeft: "0.5rem",
    textTransform: "initial",
    width: "100%",
    "&:hover": {
      alignItems: "center",
      background: dullOrangeColor,
      borderRadius: "0px 40px 40px 0px",
      boxShadow: "0px 3px 6px #00000029",
      color: whiteColor,
      display: "flex",
      fontFamily: latoBold,
      fontSize: "12px",
      justifyContent: "start",
      textTransform: "initial",
    },
    "&:disabled": {
      color: whiteColor,
    },
  },
  m1r,
  mb1r,
  fs14,
  fs16,
  fs18,
  fs20,
  fs25,
  fs30,
  fs40,
  fs50,
  fs60,
};

export default detailStyle;
