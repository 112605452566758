import { orangeColor, whiteColor } from 'assets/jss/style.js'
import { latoRegular } from 'assets/jss/tools.js'
import { dullOrangeColor } from '../style'

const contentBarMobileStyle = {
  appbarMobile: {
    background: orangeColor,
    boxShadow: 'none',
    height: '90px',
  },
  toolbarMobile: {
    minHeight: '90px',
  },
  boxHeader: {
    height: '120px',
    position: 'fixed',
    width: '100%',
    zIndex: '1100',
  },
  menuButton: {
    color: 'white',
  },
  customPaperDrawer: {
    width: '293px',
  },
  boxActions: {
    background: orangeColor,
    color: whiteColor,
    height: '164px',
    width: '100%',
  },
  imageUser: {
    position: 'absolute',
    zIndex: '1000',
  },
  boxBackgroundUser: {
    background: dullOrangeColor,
    borderRadius: '50%',
    height: '79px',
    position: 'relative',
    width: '28%',
  },
  buttonClose: {
    color: whiteColor,
    position: 'absolute',
    right: '0',
    top: '10px',
  },
  boxUserIcon: {
    marginBottom: '0.5rem',
  },
  customList: {
    padding: '1rem 2rem',
  },
  customItemIcon: {
    maxWidth: '30px !important',
  },
  customItemText: {
    color: '#707070',
    fontFamily: latoRegular,
    fontSize: '16px',
    letterSpacing: '0',
    textTransform: 'initial',
    display: 'flex',
    alignItems: 'center',
  },
  customDivider: {
    background: '#707070',
    opacity: '1',
  },
  customLink: {
    display: 'flex',
    flexDirection: 'row',
  },
  boxLanguageSelector: {
    padding: '1rem 0',
    width: '100%',
  },
}

export default contentBarMobileStyle
