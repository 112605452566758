import React, { useContext } from 'react'
import Context from 'context/Context'
import styles from 'assets/jss/views/eventPageSections/paymentTabStyle.js'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Logo from 'assets/img/vmove/logos/vmove_logo_v_orange.jpg'

const useStyles = makeStyles(styles)

const WiretransferButton = (props) => {
  const { paymentMethod, handlePaymentMethod } = props
  const classes = useStyles()
  const contextType = useContext(Context)
  const { t } = contextType

  return (
    <Button
      onClick={handlePaymentMethod('transferencia')}
      className={
        paymentMethod === 'transferencia' ? classes.buttonPaymentActive : classes.buttonPayment
      }
    >
      <Grid>
        <img alt='Logo' src={Logo} className={classes.imageTransfer} />
        <Typography className={classes.labelTransfer}>{t('wire_transfer')}</Typography>
      </Grid>
    </Button>
  )
}

export default WiretransferButton
