import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Grid, Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import CategoryTab from "../../components/Forms/ReservationForms/CategoryTab";
import AddressTab from "../../components/Forms/ReservationForms/AddressTab";
import PassengerTab from "../../components/Forms/ReservationForms/PassengerTab";
import PaymentTab from "../../components/Forms/ReservationForms/PaymentTab";
import FinishTab from "../../components/Forms/ReservationForms/FinishTab";
import LoginSelector from "../../components/Header/InfoBar/LoginSelector";
import {
  defaultSchema,
  AddressTabSchema,
  TicketsSchema,
  PassengerSchema,
} from "utils/validations";

import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import StepperFooter from "../../components/Stepper/StepperFooter";
import StepperHeader from "../../components/Stepper/StepperHeader";
import Context from "context/Context";

import AuthCheck from "utils/authCheck";
import { setPayment } from "provider/privateProvider/provider";

import { Redirect } from "react-router-dom";

import DiscountCoupon from "components/Payment/DiscountCoupon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gridLoginSelector: {
    border: "1px solid #CDCDCD",
    borderRadius: 12,
  },
  paymentMessage: {
    color: "#7E7E7E",
    fontSize: "20px",
    margin: "15px 0 10px 0",
  },
  responsiveFont: {
    color: "#7E7E7E",
    fontSize: "12px",
    margin: "15px 0 10px 0",
  },
}));

const ReservationTabs = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const {
    oldCitySelected,
    category,
    dateSelectedPicker,
    citySelected,
    setCurrentStep,
    paymentType,
    eventId,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [country, setCountry] = useState("");
  const [total, setTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentResponse, setPaymentResponse] = useState(null);

  const [isLogged, setIsLogged] = useState(false);

  const [discount, setDiscounts] = useState([]);

  const contextType = useContext(Context);
  const {
    getLogStatus,
    setLogStatus,
    getCountry,
    getDiscount,
    setDiscount,
  } = contextType;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const steps = [
    {
      icon: <AirportShuttleIcon />,
      label: "select_your_category",
    },
    {
      icon: <HomeIcon />,
      label: "address_input",
    },
    {
      icon: <PeopleIcon />,
      label: "passengers",
    },
    {
      icon: <ShoppingCartIcon />,
      label: "confirm_your_purchase",
    },
  ];

  const categories = category.sort(
    (prev, next) => next.priority - prev.priority
  );

  const tickets = {
    tickets: [],
  };

  useEffect(() => {
    getStepContent();
  }, [getDiscount]);

  const getStepContent = (
    stepIndex,
    categories,
    calculateTotal,
    calculateOriginalTotal,
    values,
    validateForm,
    setFieldValue,
    isValid
  ) => {
    //enableStepperFooter()
    setCurrentStep(stepIndex);
    switch (stepIndex) {
      case 0:
        return (
          <CategoryTab
            citySelected={citySelected}
            categories={categories} //{resAPI}
            calculateTotal={calculateTotal}
            calculateOriginalTotal={calculateOriginalTotal}
            oldCitySelected={oldCitySelected}
          />
        );
      case 1: {
        return (
          <AddressTab
            counties={citySelected.counties}
            validateForm={validateForm}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
          />
        );
      }
      case 2:
        return (
          <PassengerTab
            counties={citySelected.counties}
            validateForm={validateForm}
            setActiveStep={setActiveStep}
          />
        );
      case 3:
        return (
          <PaymentTab
            tickets={values.tickets}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setActiveStep={setActiveStep}
            paymentType={paymentType}
            eventId={eventId}
          />
        );
      case 4:
        return error ? (
          <Redirect to="/order-rejected" />
        ) : (
          <FinishTab
            tickets={values.tickets}
            paymentResponse={paymentResponse}
            paymentMethod={paymentMethod}
            paymentType={paymentType}
          />
        );
      case 5:
        return <Redirect to={"/my-account/payments"} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const calculateTotal = (values, discountProp) => {
    discountProp?.length > 0
      ? setTotal(
          values.tickets.reduce((acc, currentValue) => {
            let productVariant = currentValue.productVariantId;
            return (
              acc +
              discount
                .filter(
                  (coupon) => coupon.product_variant_id === productVariant
                )
                .reduce(
                  (acc, currentValue) =>
                    acc + Number(currentValue.discount_price),
                  0
                )
            );
          }, 0)
        )
      : setTotal(
          values.tickets.reduce(
            (acc, currentValue) => acc + currentValue.price,
            0
          )
        );
  };

  const calculateOriginalTotal = (values, discountProp) => {
    return values.tickets.reduce(
        (acc, currentValue) => acc + currentValue.price,
        0
      )
    ;
  };

  const handleNext = (values) => {
    if (getLogStatus()) {
      setCountry(getCountry());
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (activeStep === 3) {
        const cleaningResponse = values.tickets.map((ticket) => {
          return {
            placeId: ticket.placeId,
            productVariantId: ticket.productVariantId,
            passengers: ticket.passengers,
            addresses: ticket.addresses,
          };
        });
        let response;
        if (discount.length > 0) {
          response = {
            discountCode: getDiscount().code,
            url: country,
            eventDateId: dateSelectedPicker,
            provinceId: citySelected.id,
            paymentCode: paymentMethod,
            tickets: cleaningResponse,
          };
        } else {
          response = {
            url: country,
            eventDateId: dateSelectedPicker,
            provinceId: citySelected.id,
            paymentCode: paymentMethod,
            tickets: cleaningResponse,
          };
        }
        const responsePrepaid = {
          url: country,
          eventDateId: dateSelectedPicker,
          provinceId: citySelected.id,
          paymentCode: "prepagado",
          tickets: cleaningResponse,
        };
        paymentType === 2
          ? setPayment(responsePrepaid)
              .then((res) => {
                setPaymentResponse(res);
              })
              .catch((e) => {
                setError(true);
              })
          : setPayment(response)
              .then((res) => {
                setPaymentResponse(res);
              })
              .catch((e) => {
                setError(true);
              });
        return response;
      }
    }
  };

  const currentFormSchema = () => {
    switch (activeStep) {
      case 0:
        return TicketsSchema;
      case 1:
        return AddressTabSchema;
      case 2:
        return PassengerSchema;
      default:
        return defaultSchema;
    }
  };

  useEffect(() => {
    const enableStepperFooter = () => {
      AuthCheck()
        .then((res) => {
          setIsLogged(true);
          setLogStatus(true);
        })
        .catch((e) => {
          setIsLogged(false);
          setLogStatus(false);
        });
    };
    enableStepperFooter();
  }, [getLogStatus()]);

  useEffect(() => {
    if (getDiscount()?.code) {
      setDiscounts(getDiscount().productVariants);
    }
  }, [getDiscount()]);

  return (
    <div className={classes.root} style={{ paddingTop: "5%" }}>
      {activeStep < 4 && (
        <StepperHeader
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}

      <Formik
        initialValues={tickets}
        validationSchema={() => currentFormSchema()}
        onSubmit={(values) => {
          handleNext(values);
        }}
      >
        {({ values, validateForm, setFieldValue }) => {
          const isValid = currentFormSchema().isValidSync(values);
          return (
            <>
              <Form>
                <div>
                  {getStepContent(
                    activeStep,
                    categories,
                    calculateTotal,
                    calculateOriginalTotal,
                    values,
                    validateForm,
                    setFieldValue
                  )}
                  {discount.length > 0 ? (
                    isLogged ? (
                      <StepperFooter
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        isValid={isValid}
                        total={total}
                        values={values}
                        paymentType={paymentType}
                        calculateTotal={calculateTotal}
                        calculateOriginalTotal={calculateOriginalTotal}
                      />
                    ) : (
                      <Grid
                        container
                        className={classes.gridLoginSelector}
                        justify="center"
                      >
                        <Typography
                          classes={{
                            root: isMobile
                              ? classes.responsiveFont
                              : classes.paymentMessage,
                          }}
                        >
                          {"Debe estar registrado para realizar una compra."}
                        </Typography>
                        <LoginSelector isEvent={true} />
                      </Grid>
                    )
                  ) : isLogged ? (
                    <StepperFooter
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      isValid={isValid}
                      total={total}
                      values={values}
                      paymentType={paymentType}
                      calculateTotal={calculateTotal}
                      calculateOriginalTotal={calculateOriginalTotal}
                    />
                  ) : (
                    <Grid
                      container
                      className={classes.gridLoginSelector}
                      justify="center"
                    >
                      <Typography
                        classes={{
                          root: isMobile
                            ? classes.responsiveFont
                            : classes.paymentMessage,
                        }}
                      >
                        {"Debe estar registrado para realizar una compra."}
                      </Typography>
                      <LoginSelector isEvent={true} />
                    </Grid>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ReservationTabs;
