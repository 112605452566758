import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, Accordion, Box, Typography } from '@material-ui/core'
import EditIcon from "@material-ui/icons/Edit"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { FieldArray } from 'formik'
import { useMediaQuery } from 'react-responsive'
import SummaryAddress from 'components/Accordion/Summary/SummaryAddress'
import DetailsAddress from 'components/Accordion/Details/DetailsAddress'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import Context from 'context/Context'

const useStyles = makeStyles(styles)

const AddressTab = (props) => {
  const { counties, validateForm, setFieldValue, setActiveStep } = props
  const [expanded, setExpanded] = useState('panel0')
  const quantity = []
  const contextType = useContext(Context)
  const { t } = contextType

  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })

  const handleChange = (panel) => (event) => {
    event.preventDefault()
    setExpanded(expanded === panel ? false : panel)
  }

  return (
    <div>
      <FieldArray>
        {(fieldArrayProps) => {
          const { form } = fieldArrayProps
          const { values } = form
          let tickets = values.tickets
          let ticketsLength = tickets.length
          
          return (
            <>
              <Grid justify="space-between" alignItems="center" container>
                <Typography
                  classes={{ root: classes.addressTitle }}
                  className={isMobile ? classes.fs18 : classes.fs20}
                >
                  {t('enter_your_address')}
                </Typography>
                <Button
                  classes={{ root: classes.buttonEditTicket }}
                  className={isMobile ? classes.fs14 : classes.fs16}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setActiveStep(0)}
                >
                  {t("go_back")}
                </Button>
              </Grid>

              {}
              
              {tickets.map((ticket, index) => {
                quantity.push(ticket.addresses.length)

                const updateAddresses = (newAddress) => {
                  ticket.addresses.slice(index, 1, newAddress)
                }

                return (
                  <div key={index}>
                    <Box classes={{ root: classes.boxTickets }} id={index} data-ticket-id={index}>
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        classes={{
                          root: classes.accordion,
                          rounded: classes.boxAddressOptionBorder,
                        }}
                      >
                        <SummaryAddress
                          index={index}
                          stops={ticket.stops}
                          expanded={expanded === `panel${index}`}
                          name={ticket.name}
                          quantity={quantity[index]}
                          subcategoryName={ticket.subcategoryName}
                          handleChange={handleChange}
                          placeAddress={ticket.placeAddress}
                        />
                        <DetailsAddress
                          updateAddresses={updateAddresses}
                          index={index}
                          ticket={ticket}
                          counties={counties}
                          validateForm={validateForm}
                          setFieldValue={setFieldValue}
                          ticketsLength={ticketsLength}
                        />
                      </Accordion>
                    </Box>
                  </div>
                )
              })}
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default AddressTab
