import React, { useContext, Fragment } from "react";
import Context from "../../context/Context";
import { Link } from "react-router-dom";
import { Box, Container, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/components/footerMobileStyle.js";
import Facebook from "../../assets/img/vmove/social-networks/facebook.svg";
import Instagram from "../../assets/img/vmove/social-networks/instagram.svg";
import Youtube from "../../assets/img/vmove/social-networks/youtube.svg";
import Logo from "assets/img/vmove/vmove-logo.png";

const useStyles = makeStyles(styles);

export default function FooterMobile(props) {
  const contextType = useContext(Context);
  const { t } = contextType;
  const classes = useStyles();
  return (
    <Fragment>
      <Container className={classes.containerMobile}>
        <Box
          alignItems="center"
          classes={{ root: classes.footerContentMobile }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography className={classes.title}>
            {t("join_our_community")}
          </Typography>
          <Box
            classes={{ root: classes.boxSocialNetworks }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <img
              alt="Facebook"
              src={Facebook}
              className={classes.imageSocial}
            />
            <img
              alt="Instagram"
              src={Instagram}
              className={classes.imageSocial}
            />
            <img alt="Youtube" src={Youtube} className={classes.imageSocial} />
          </Box>
          <Box width="100%" classes={{ root: classes.boxLinks }}>
            <List classes={{ root: classes.customList }}>
              <ListItem classes={{ root: classes.customItem }}>
                <Link to="/about-us" className={classes.customLink}>
                  <Typography className={classes.customItemText}>
                    {t("about_us")}
                  </Typography>
                </Link>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link to="/contact" className={classes.customLink}>
                  <Typography className={classes.customItemText}>
                    {t("contact")}
                  </Typography>
                </Link>
              </ListItem>
              <ListItem classes={{ root: classes.customItem }}>
                <Link to="/faq" className={classes.customLink}>
                  <Typography className={classes.customItemText}>
                    {t("frequent_questions")}
                  </Typography>
                </Link>
              </ListItem>
            </List>
          </Box>
          <Box width="100%" display="flex" justifyContent="center">
            <img alt="Vmove" src={Logo} className={classes.imageLogo} />
          </Box>
          <Box
            alignItems="center"
            classes={{ root: classes.boxCopyRight }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
          >
            <Typography
              align="justify"
              className={classes.copyRight + " " + classes.upperText}
            >
              {t("copy_right")}
            </Typography>
            <Typography align="justify" className={classes.address}>
              {t("address")}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}
