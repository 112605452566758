import React, { Fragment, useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import Context from "context/Context";
import {
  AccordionDetails,
  Box,
  Divider,
  Grid,
  ListItem,
  Typography,
} from "@material-ui/core/";
import styles from "assets/jss/views/eventPageSections/categoryTabStyle.js";
import NumberFormat from "react-number-format";
import CounterButtons from "components/Tools/CounterButtons";
import PopoverDetail from "components/Popover/PopoverDetail";
const useStyles = makeStyles(styles);

const DetailsCategory = (props) => {
  const {
    oldCitySelected,
    citySelected,
    category,
    contenedor,
    calculateTotal,
  } = props;

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 959px)" });
  const spacingMobile = isMobile ? 1 : 0;
  const contextType = useContext(Context);
  const { getLanguage, t, getDiscount } = contextType;
  const [discount, setDiscounts] = useState([]);

  useEffect(() => {
    if (getDiscount()?.code) {
      setDiscounts(getDiscount().productVariants);
    }
  }, [getDiscount()]);

  return (
    <>
      <Grid item xs={12} md={12} className={classes.itemDividerHorizontal}>
        <Box classes={{ root: classes.boxDividerHorizontal }} />
      </Grid>
      <AccordionDetails classes={{ root: classes.accorditionSumaryRoot }}>
        <Grid xs={12}>
          {discount.productVariants > 0
            ? category.subCategories
                .filter((categoryFilter) => {
                  return categoryFilter.provinceId === citySelected.id;
                })
                .sort((prev, next) => {
                  if (prev.price > next.price) return 1;
                  if (prev.price < next.price) return -1;

                  if (prev.translations[getLanguage()].name > next.translations[getLanguage()].name) return 1;
                  if (prev.translations[getLanguage()].name < next.translations[getLanguage()].name) return -1;
                })
                .map((subcategory, index) => {
                  const updateTicket = (selectedTickets) => {
                    contenedor.tickets = selectedTickets;
                    calculateTotal(contenedor);
                  };
                  return (
                    <Fragment key={`${index} ${subcategory.provinceId}`}>
                      <ListItem
                        id={"index_" + category.id}
                        className={
                          isMobile
                            ? classes.customItemMobile
                            : classes.customItem
                        }
                      >
                        <Grid
                          container
                          display="flex"
                          justify="center"
                          alignItems="center"
                          className={classes.containerCategories}
                          spacing={spacingMobile}
                        >
                          {isMobile ? (
                            <Fragment>
                              <Grid
                                item
                                xs={1}
                                className={classes.itemFullCenter}
                              >
                                <PopoverDetail
                                  imagePath={
                                    subcategory.translations[getLanguage()]
                                      .imagePath
                                  }
                                  index={index}
                                  paragraph={`${
                                    subcategory.translations[getLanguage()].name
                                  } - ${t("maximum")} ${
                                    subcategory.capacity
                                  } ${t("passangers_and")} ${
                                    subcategory.stops
                                  } ${t("addresses").toLowerCase()}.`}
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  align="start"
                                  classes={{
                                    root: classes.categoryCollapseHeader,
                                  }}
                                  className={
                                    isMobile ? classes.fs13 : classes.fs14
                                  }
                                >
                                  {`${subcategory.translations[getLanguage()].name}`}
                                </Typography>
                                <Typography
                                  align="start"
                                  className={
                                    classes.categoryCollapseHeader +
                                    " " +
                                    classes.fs14
                                  }
                                >
                                  {
                                    subcategory.translations[getLanguage()]
                                      .description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <NumberFormat
                                  value={subcategory.price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}                                  
                                  prefix={process.env.REACT_APP_MONEY_SIGN}
                                  renderText={(value) => (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      value={subcategory.price}
                                      classes={{
                                        root: classes.customInputTotal,
                                      }}
                                      className={classes.fs14}
                                    >
                                      {value}
                                    </Box>
                                  )}
                                />
                              </Grid>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.boxCounterInput}
                              >
                                <CounterButtons
                                  updateTicket={updateTicket}
                                  tickets={contenedor.tickets}
                                  capacity={subcategory.capacity}
                                  stops={subcategory.stops}
                                  productVariantId={
                                    subcategory.productVariantId
                                  }
                                  price={subcategory.price}
                                  name={category.translations}
                                  subcategoryName={subcategory.translations}
                                  placeId={subcategory.placeId}
                                  placeAddress={subcategory.placeAddress}
                                  citySelected={citySelected}
                                  oldCitySelected={oldCitySelected}
                                  approaches={subcategory.approach}
                                  stock={subcategory.stock}
                                />
                              </Box>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Grid
                                item
                                xs={2}
                                md={2}
                                className={classes.itemFullCenter}
                              >
                                <PopoverDetail
                                  imagePath={
                                    subcategory.translations[getLanguage()]
                                      .imagePath
                                  }
                                  index={index}
                                  paragraph={`${
                                    subcategory.translations[getLanguage()].name
                                  } - ${t("maximum")} ${
                                    subcategory.capacity
                                  } ${t("passangers_and")} ${
                                    subcategory.stops
                                  } ${t("addresses").toLowerCase()}.`}
                                />
                              </Grid>
                              <Grid item xs={7} md={6}>
                                <Typography
                                  align="start"
                                  classes={{
                                    root: classes.categoryCollapseHeader,
                                  }}
                                  className={
                                    isMobile ? classes.fs13 : classes.fs14
                                  }
                                >
                                  {`${subcategory.translations[getLanguage()].name}`}
                                </Typography>
                                <Typography
                                  align="start"
                                  className={
                                    classes.categoryCollapseHeader +
                                    " " +
                                    classes.fs14
                                  }
                                >
                                  {
                                    subcategory.translations[getLanguage()]
                                      .description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={2} md={2}>
                                <NumberFormat
                                  value={subcategory.price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  prefix={process.env.REACT_APP_MONEY_SIGN}
                                  renderText={(value) => (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      value={subcategory.price}
                                      classes={{
                                        root: classes.customInputTotal,
                                      }}
                                      className={classes.fs14}
                                    >
                                      {value}
                                    </Box>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <CounterButtons
                                  updateTicket={updateTicket}
                                  tickets={contenedor.tickets}
                                  capacity={subcategory.capacity}
                                  stops={subcategory.stops}
                                  productVariantId={
                                    subcategory.productVariantId
                                  }
                                  price={subcategory.price}
                                  name={category.translations}
                                  subcategoryName={subcategory.translations}
                                  placeId={subcategory.placeId}
                                  placeAddress={subcategory.placeAddress}
                                  citySelected={citySelected}
                                  oldCitySelected={oldCitySelected}
                                  approaches={subcategory.approach}
                                  stock={subcategory.stock}
                                />
                              </Grid>
                            </Fragment>
                          )}
                        </Grid>
                      </ListItem>
                      {index < category.subCategories.length - 1 ? (
                        <Divider
                          classes={{
                            root: classes.customDividerCollapse,
                          }}
                        />
                      ) : null}
                    </Fragment>
                  );
                })
            : category.subCategories
                .filter((categoryFilter) => {
                  return categoryFilter.provinceId === citySelected.id;
                })
                .sort((prev, next) => {
                  if (prev.price > next.price) return 1;
                  if (prev.price < next.price) return -1;

                  if (prev.translations[getLanguage()].name > next.translations[getLanguage()].name) return 1;
                  if (prev.translations[getLanguage()].name < next.translations[getLanguage()].name) return -1;
                })
                .map((subcategory, index) => {
                  const updateTicket = (selectedTickets) => {
                    contenedor.tickets = selectedTickets;
                    calculateTotal(contenedor);
                  };
                  return (
                    <Fragment key={`${index} ${subcategory.provinceId}`}>
                      <ListItem
                        id={"index_" + category.id}
                        className={
                          isMobile
                            ? classes.customItemMobile
                            : classes.customItem
                        }
                      >
                        <Grid
                          container
                          display="flex"
                          justify="center"
                          alignItems="center"
                          className={classes.containerCategories}
                          spacing={spacingMobile}
                        >
                          {isMobile ? (
                            <Fragment>
                              <Grid
                                item
                                xs={1}
                                className={classes.itemFullCenter}
                              >
                                <PopoverDetail
                                  imagePath={
                                    subcategory.translations[getLanguage()]
                                      .imagePath
                                  }
                                  index={index}
                                  paragraph={`${
                                    subcategory.translations[getLanguage()].name
                                  } - ${t("maximum")} ${
                                    subcategory.capacity
                                  } ${t("passangers_and")} ${
                                    subcategory.stops
                                  } ${t("addresses").toLowerCase()}.`}
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  align="start"
                                  classes={{
                                    root: classes.categoryCollapseHeader,
                                  }}
                                  className={
                                    isMobile ? classes.fs13 : classes.fs14
                                  }
                                >
                                  {`${subcategory.translations[getLanguage()].name}`}
                                </Typography>
                                <Typography
                                  align="start"
                                  className={
                                    classes.categoryCollapseHeader +
                                    " " +
                                    classes.fs14
                                  }
                                >
                                  {
                                    subcategory.translations[getLanguage()]
                                      .description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <NumberFormat
                                  value={subcategory.price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  prefix={process.env.REACT_APP_MONEY_SIGN}
                                  renderText={(value) => (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      value={subcategory.price}
                                      classes={{
                                        root: classes.customInputTotal,
                                      }}
                                      className={classes.fs14}
                                    >
                                      {value}
                                    </Box>
                                  )}
                                />
                              </Grid>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.boxCounterInput}
                              >
                                <CounterButtons
                                  updateTicket={updateTicket}
                                  tickets={contenedor.tickets}
                                  capacity={subcategory.capacity}
                                  stops={subcategory.stops}
                                  productVariantId={
                                    subcategory.productVariantId
                                  }
                                  price={subcategory.price}
                                  name={category.translations}
                                  subcategoryName={subcategory.translations}
                                  placeId={subcategory.placeId}
                                  placeAddress={subcategory.placeAddress}
                                  citySelected={citySelected}
                                  oldCitySelected={oldCitySelected}
                                  approaches={subcategory.approach}
                                  stock={subcategory.stock}
                                />
                              </Box>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Grid
                                item
                                xs={2}
                                md={2}
                                className={classes.itemFullCenter}
                              >
                                <PopoverDetail
                                  imagePath={
                                    subcategory.translations[getLanguage()]
                                      .imagePath
                                  }
                                  index={index}
                                  paragraph={`${
                                    subcategory.translations[getLanguage()].name
                                  } - ${t("maximum")} ${
                                    subcategory.capacity
                                  } ${t("passangers_and")} ${
                                    subcategory.stops
                                  } ${t("addresses").toLowerCase()}.`}
                                />
                              </Grid>
                              <Grid item xs={7} md={6}>
                                <Typography
                                  align="start"
                                  classes={{
                                    root: classes.categoryCollapseHeader,
                                  }}
                                  className={
                                    isMobile ? classes.fs13 : classes.fs14
                                  }
                                >
                                  {`${subcategory.translations[getLanguage()].name}`}
                                </Typography>
                                <Typography
                                  align="start"
                                  className={
                                    classes.categoryCollapseHeader +
                                    " " +
                                    classes.fs14
                                  }
                                >
                                  {
                                    subcategory.translations[getLanguage()]
                                      .description
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={2} md={2}>
                                <NumberFormat
                                  value={subcategory.price}
                                  displayType={"text"}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  prefix={process.env.REACT_APP_MONEY_SIGN}
                                  renderText={(value) => (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      value={subcategory.price}
                                      classes={{
                                        root: classes.customInputTotal,
                                      }}
                                      className={classes.fs14}
                                    >
                                      {value}
                                    </Box>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={3} md={2}>
                                <CounterButtons
                                  updateTicket={updateTicket}
                                  tickets={contenedor.tickets}
                                  capacity={subcategory.capacity}
                                  stops={subcategory.stops}
                                  productVariantId={
                                    subcategory.productVariantId
                                  }
                                  price={subcategory.price}
                                  name={category.translations}
                                  subcategoryName={subcategory.translations}
                                  placeId={subcategory.placeId}
                                  placeAddress={subcategory.placeAddress}
                                  citySelected={citySelected}
                                  oldCitySelected={oldCitySelected}
                                  approaches={subcategory.approach}
                                  stock={subcategory.stock}
                                />
                              </Grid>
                            </Fragment>
                          )}
                        </Grid>
                      </ListItem>
                      {index < category.subCategories.length - 1 ? (
                        <Divider
                          classes={{
                            root: classes.customDividerCollapse,
                          }}
                        />
                      ) : null}
                    </Fragment>
                  );
                })}
        </Grid>
      </AccordionDetails>
    </>
  );
};

export default DetailsCategory;
