import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { useMediaQuery } from 'react-responsive'
import { Field, ErrorMessage } from 'formik'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'
import Context from 'context/Context'
import { Typography } from '@material-ui/core/'

const useStyles = makeStyles(styles)

const Input = (props) => {
  const { label, name } = props
  const classes = useStyles()
  const contextType = useContext(Context)
  const isMobile = useMediaQuery({ query: '(max-width: 959px)' })
  const { t } = contextType

  return (
    <div className={isMobile ? classes.customTextFieldMobile : classes.customTextFieldOutlined}>
      <Field
        autocomplete='off'
        as={TextField}
        name={name}
        helperText={<ErrorMessage name={name} render={(msg) => <Typography className={classes.error}>{t(msg)}</Typography>} />}
        label={label}
        variant='outlined'
      />
    </div>
  )
}

export default Input
