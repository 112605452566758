import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Carousel from 'react-slick'
import Context from '../../../context/Context'
import { Box, Typography, Grid } from '@material-ui/core'
import Card from 'components/Card/Card.js'
import styles from 'assets/jss/views/landingPageSections/userStyle.js'
import 'assets/scss/views/userStyle.scss'

import BanmedicaLogo from 'assets/img/vmove/partners_logos/banmedica.png'
import GoogleLogo from 'assets/img/vmove/partners_logos/google.png'
import McdonaldsLogo from 'assets/img/vmove/partners_logos/mcdonalds.png'
import MinisterioLogo from 'assets/img/vmove/partners_logos/ministerio_salud.png'
import MonticelloLogo from 'assets/img/vmove/partners_logos/monticello.png'
import PuntoTicketLogo from 'assets/img/vmove/partners_logos/punto_ticket.png'
import SantaCarolinaLogo from 'assets/img/vmove/partners_logos/santa_carolina.png'
import SantaMartinaLogo from 'assets/img/vmove/partners_logos/santa_martina.png'
import SantaRitaLogo from 'assets/img/vmove/partners_logos/santa_rita.png'
import SherwinWilliamsLogo from 'assets/img/vmove/partners_logos/sherwin_williams.png'
import SilfaLogo from 'assets/img/vmove/partners_logos/silfa.png'
import TravelSecurityLogo from 'assets/img/vmove/partners_logos/travel_security.png'
import ZaraLogo from 'assets/img/vmove/partners_logos/zara.png'
import ClinicaVespucioLogo from 'assets/img/vmove/partners_logos/clinica_vespucio.png'
import ITFLogo from 'assets/img/vmove/partners_logos/itf.png'

export class UserSection extends Component {
  static contextType = Context

  render() {
    const settings = {
      arrow: false,
      autoplay: true,
      dots: true,
      infinite: true,
      slidesToScroll: 4,
      slidesToShow: 4,
      speed: 500,
    }

    const { t } = this.context
    const { classes } = this.props
    return (
      <Box classes={{ root: classes.boxUsers }}>
        <Typography align='center' className={classes.usersTitle}>
          {t('book_our_services')}:
        </Typography>
        <Grid container justify='center' align='center'>
          <Grid item md={10}>
            <Card className={classes.customCardCarousel} id='users-carousel'>
              <Carousel {...settings}>
                <div className={classes.boxSlider}>
                  <img alt='First Partner' src={BanmedicaLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Second Partner' src={GoogleLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Fifth Partner' src={MonticelloLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Sixth Partner' src={PuntoTicketLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Seventh Partner' src={SantaCarolinaLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Eighth Partner' src={TravelSecurityLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Third Partner' src={McdonaldsLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Fourth Partner' src={MinisterioLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Nineth Partner' src={SantaMartinaLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Tenth Partner' src={SantaRitaLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Eleventh Partner' src={SherwinWilliamsLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Twelfth Partner' src={SilfaLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Thireenth Partner' src={ZaraLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Fourteenth Partner' src={ITFLogo} className={classes.userImageSlider} />
                </div>
                <div className={classes.boxSlider}>
                  <img alt='Fifteenth Partner' src={ClinicaVespucioLogo} className={classes.userImageSlider} />
                </div>
              </Carousel>
            </Card>
          </Grid>
        </Grid>
        <Box classes={{ root: classes.boxContentSlider }}></Box>
      </Box>
    )
  }
}

UserSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserSection)
