const landingPageStyle = {
  imageLanding: {
    cursor: 'pointer',
    opacity: '1',
    position: 'absolute',
    zIndex: '1000',
  },
  finder: {
    marginTop: '-120px',
    marginLeft: '1px',
  },
  chevronDown: {
    marginTop: '-50px',
  },
}

export default landingPageStyle
