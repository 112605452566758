import React, { useEffect } from 'react'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)

const ForcedSelect = (props) => {
  const { name, label, data, setFieldValue } = props
  const classes = useStyles()

  useEffect(() => {
    setFieldValue(name, data[0])
  }, [])

  return (
    <FormControl classes={{ root: classes.customTextFieldSelect }}>
      <Field disabled={true} autocomplete='off' as={TextField} label={label} name={`${name}`} variant='outlined' value={data[0]}>
        <MenuItem value={data[0]}>{data[0]}</MenuItem>
      </Field>
    </FormControl>
  )
}

export default ForcedSelect
