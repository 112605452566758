import React, { useContext, useState, useEffect } from "react";
import Context from "../../context/Context";
import styles from "assets/jss/views/massiveEventsPage.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import EventCards from "../../components/EventCards/EventCards";
import Star from "assets/img/vmove/icons/star.svg";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { getEventsByType } from "provider/publicProvider/provider";

const useStyles = makeStyles(styles);

export default function MassiveEventsPage(props) {
  const [eventList, setEventList] = useState([]);
  const contextType = useContext(Context);
  const { getCountry, getLanguage } = contextType;
  const { t } = contextType;
  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const customJustify = isMobile ? "center" : "initial";
  const [loading, setLoading] = useState(true);
  const [withOutData, setWithOutData] = useState(false);

  useEffect(() => {
    const getEventList = () => {
      getEventsByType(getCountry(), 3)
        .then((res) => {
          setEventList(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error("alliances_page", error);
          setLoading(false);
          setWithOutData(true);
        });
    };
    getEventList();
  }, [getCountry(), getLanguage()]);

  if (loading) {
    return (
      <Container>
        <Grid
          item
          xs={12}
          md={12}
          classes={{ root: classes.itemFullCenter }}
          className={classes.boxCircularProgress}
        >
          <CircularProgress classes={{ root: classes.customProgress }} />
        </Grid>
      </Container>
    );
  }

  if (withOutData) {
    return (
      <Grid
        item
        xs={12}
        md={12}
        classes={{ root: classes.itemFullCenter }}
        className={classes.boxCircularProgress}
      >
        <Typography
              classes={{ root: classes.titleEvent }}
              className={isMobile ? classes.fs16 : classes.fs20}
            >
              {"Sin eventos para mostrar"}
            </Typography>
      </Grid>
    );
  }

  return (
    <Box
      className={
        isMobile ? classes.contentWrapperMobile : classes.contentWrapper
      }
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={customJustify}
          classes={{ root: classes.boxHeaderEvents }}
        >
          <img
            alt="Star"
            src={Star}
            className={isMobile ? classes.iconMobile : null}
          />
          <Typography
            classes={{ root: classes.titleEvent }}
            className={isMobile ? classes.fs16 : classes.fs30}
          >
            {t("alliances")}
          </Typography>
        </Box>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          className={classes.gridContainerList}
          justify="center"
          spacing={2}
        >
          {eventList.length > 0 ? (
            eventList.map((event) => {
              return (
                <EventCards key={event.id} event={event} bgColor="#FD7F39" />
              );
            })
          ) : (
            <Grid
              item
              xs={12}
              md={12}
              classes={{ root: classes.itemFullCenter }}
              className={classes.boxCircularProgress}
            >
              <Typography
              classes={{ root: classes.titleEvent }}
              className={isMobile ? classes.fs16 : classes.fs20}
            >
              {"Sin eventos para mostrar"}
            </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12} className={classes.itemFullCenter}>
            <Link to="/">
              <Button
                classes={{ root: classes.buttonBack }}
                variant="contained"
                className={isMobile ? classes.fs16 : classes.fs18}
              >
                {t("back_to_main_screen")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
