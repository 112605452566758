import React, { useState } from 'react'
import { Accordion, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SummaryPayment from 'components/Accordion/Summary/SummaryPayment'
import DetailsPayment from 'components/Accordion/Details/DetailsPayment'
import styles from 'assets/jss/views/eventPageSections/generalEventsStyles.js'
import { isMobile } from 'react-device-detect'
import { DetailsPaymentMobile } from 'components/Accordion/Details/DetailsPayment'

const useStyles = makeStyles(styles)

const TicketDetails = (props) => {
  const { tickets } = props
  const [expanded, setExpanded] = useState('panel0')

  const classes = useStyles()

  const handleChange = (panel) => (event) => {
    event.preventDefault()
    setExpanded(expanded === panel ? false : panel)
  }

  const handleTicketsComponent = (ticket, index) => {
    return isMobile ? <DetailsPaymentMobile ticket={ticket} index={index} /> : <DetailsPayment ticket={ticket} index={index} />
  }

  return (
    <>
      {tickets.map((ticket, index) => {
        return (
          <div key={index}>
            <Box classes={{ root: classes.boxTickets }} id={index} data-ticket-id={index}>
              <Accordion
                classes={{
                  root: classes.accordion,
                  rounded: classes.boxAddressOptionBorder,
                }}
                expanded={expanded === `panel${index}`}
              >
                <SummaryPayment
                  handleChange={handleChange}
                  expanded={expanded === `panel${index}`}
                  index={index}
                  subcategoryName={ticket.subcategoryName}
                  placeAddress={ticket.placeAddress}
                />
                {handleTicketsComponent(ticket, index)}
              </Accordion>
            </Box>
          </div>
        )
      })}
    </>
  )
}

export default TicketDetails
