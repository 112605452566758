import React, { useContext, useRef, useEffect } from 'react'
import Context from '../../context/Context'
import styles from 'assets/jss/views/faqPage.js'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import CompaniesTab from './FaqTabs/CompaniesTab'
import { Box, Button, Container, Tab, Typography } from '@material-ui/core'

const useStyles = makeStyles(styles)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  return (
    <Tab
      classes={{ root: classes.customTab }}
      className={isMobile ? classes.fs14 : classes.fs20}
      component='a'
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

export default function FaqPage(props) {
  const [value, setValue] = React.useState(0)
  const divRef = useRef(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const contextType = useContext(Context)
  const { t } = contextType
  const classes = useStyles()
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const variantTabs = isMobile ? 'scrollable' : 'fullWidth'

  useEffect(() => {
    const heightToScroll = divRef.current.offsetHeight - 115
    window.scrollTo({
      top: heightToScroll,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Box className={isMobile ? classes.contentWrapperMobile : classes.contentWrapper}>
      <Box ref={divRef} className={classes.boxWrapper}>
        <Container classes={{ root: classes.containerDetailInfo }} maxWidth='lg'>
          <Box classes={{ root: classes.boxTitle }} display='flex' width='100%' justifyContent='center'>
            <Typography align='center' classes={{ root: classes.labelTitle }} className={isMobile ? classes.fs25 : classes.fs50}>
              {t('frequent_questions')}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box classes={{ root: classes.boxTabPanel }}>
          <CompaniesTab />
        </Box>
        <Box display='flex' justifyContent='center' alignItems='center' classes={{ root: classes.boxButton }}>
          <Link to='/'>
            <Button
              classes={{
                root: classes.buttonBack,
              }}
              className={isMobile ? classes.fs18 : classes.fs20}
            >
              {t('back_to_main_screen')}
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}
