const language = {
  about_us: 'About',
  about_us_left_1: 'We create technological solutions for shared transfers with a presence in Spain, Chile and Peru.',
  about_us_left_2: `Our commitment is not only to provide the best service for our users but also to be a contribution to today's world, helping to reduce vehicle decongestion and reducing CO2 emissions that are harmful to the environment.`,
  about_us_right_1:
    'We are the first ticket office for transfers between your event and your home. We have a web platform, where you will find Private Events, Mass Events and personalized transfers, offering you the best experience on each trip. We take care of every detail and we think of you as our priority, so that you feel comfortable and safe before your event and also after, once you have arrived home.',
  vmove_companies_right_1:
  "We are a professional platform for corporate transfers, which combines the best of personal attention service with our software and APP. Helping to optimize routes, generating savings of up to 35%, thus reducing congestion in our cities and CO2 emissions. thus the largest network of VANs in Chile, with a presence in Spain, Peru and Chile. ",  
  vmove_b2b: 'vmove b2b',
  account_id: 'Account number',
  account_type: 'Account type',
  active_events: 'Active',
  add_more: 'Add More',
  add_stop: 'Add Stop',
  add_frequent_friend: 'Add frequent friend',
  add_friend: 'Add Friend',
  add: 'Add',
  address_input: 'Address',
  address_is_required: 'Address is required',
  address_single: 'Address',
  address: 'Address: Santa Beatriz 100, Providencia.',
  addresses: 'Addresses',
  all_reservations_include: 'All reservations include a round trip transfer ticket',
  alliances: 'Alliances',
  already_a_user: 'You are already a user',
  assign_to_my_address: 'Asign to My Address',
  available_locations: 'Available locations',
  awaiting_payment: 'Pending',
  awaiting_transfer_payment: 'Pending',
  back_to_main_screen: 'Back to main screen',
  bank: 'Bank',
  benefits: 'Benefits',
  birthday: 'Birthday',
  book_our_services: 'JUNTE-SE À NOSSA COMUNIDADE, ALGUNS DOS CLIENTES QUE ESTÃO MUDANDO HOJE',
  boyfriends: 'Boyfriends',
  bride_and_groom_name: 'Name of the bride and groom',
  build_your_route: 'Build your Route',
  buy_your_transfer: 'Buy your transfer starting at',
  by: 'by',
  cancelled: 'Cancelled',
  category: 'Category',
  change_city: 'Change City',
  change_date: 'Change Date',
  choose_the_activity: 'Choose the activity',
  clicking_register_termins: 'By clicking register, you accept the',
  commune: 'Commune',
  confirm_your_purchase: 'Confirm your purchase',
  congratulations: 'Congratulations!',
  contact: 'Contact',
  continue: 'Continue',
  copy_right: 'Copy right by vmove.cl All right reserved',
  corporate_events: 'Corporate Events',
  countie_is_required: 'Commune is required',
  create_address: 'Create Address',
  create_event: 'Create Event',
  create_event_success: 'Request created Successfully',
  create_event_failed: 'Request creation failed',
  create_private_event_title: 'Create your private event',
  create_private_event_name: 'Event name',
  create_private_event_name_form: 'Maria\'s Birthday',
  create_private_event_date: 'Start and end date and time',
  create_private_event_date_start: 'Start date',
  create_private_event_date_start_hour: 'Start time',
  create_private_event_date_end_hour: 'End time',
  create_private_event_place: 'Event Location',
  create_private_event_place_name: 'Name of the event venue',
  create_private_event_place_name_form: 'Garden of ...',
  create_private_event_address: 'Venue address',
  create_private_event_add_stop_parade: 'Add stop',
  create_private_event_add_stop_parade_address: 'Address',
  create_private_event_add_stop_parade_county: 'Commune',
  create_private_event_add_stop_parade_hour: 'Time',
  create_private_event_image: 'Main image of the event',
  create_private_event_image_search: 'Search File',
  create_private_event_image_no_selected: 'No selected file',
  create_private_event_invitation: 'Event invitation text',
  create_private_event_invitation_text: 'Write an invitation message',
  create_private_event_invitation_text_form: 'I invite you to ...',
  create_private_event_guest_list: 'Guest list',  
  create_private_event_guest_list_description: 'Debes subir tu lista en el archivo xls disponible para descargar. Puedes hacerlo ahora o después.',
  create_private_event_guest_list_format_file: 'xlsx file',
  create_private_event_guest_list_download_file: 'Download File',
  create_private_event_guest_list_upload_file: 'Upload File',
  create_private_event_send_form: 'Submit',
  create_private_event_save_form: 'Save Information',
  create_private_event_cancel: 'Cancel',
  create_private_event_guest_list_error: 'It is necessary to upload a list of invitations.',
  create_private_event_image_error: 'You need to upload an image of the event',
  current_events: 'Current Events',
  date: 'Date',
  detail_of_your_reservation: 'Detail of your reservation',
  details: 'Details',
  dni: 'DNI',
  edit_tickets: 'Edit Tickets',
  edit: 'Edit',
  email_is_invalid: 'Email is invalid',
  email_is_required: 'Email is required',
  email: 'Email',
  enter_your_address: 'Enter your address',
  error_payment_body: 'The payment could not be processed. Please try again',
  error_payment_subitle: 'An error occurred in the payment',
  event_approved: 'Approved',
  event_invited: 'Guest',
  event_under_review: 'In review',
  event_saved: 'Saved',
  event_repeat: 'Repeat',
  event_finished: 'Finished',
  event_history: 'Event History',
  event_edit: 'Edit event',
  event_name: 'Event Name',
  events: 'Events',
  famous_routes: 'Famous Routes',
  finish: 'Finish',
  finished_events: 'Finished',
  forget_password: 'Did you forget your password',
  frequent_questions: 'Frequent Questions',
  frequent_friends: 'Frequent Friends',
  frequent_addresses: 'Frequent Addresses',
  friends: 'Friends',
  from: 'From',
  gender: 'Gender',  
  go_back: 'Back',
  here: 'here',
  home: 'Home',
  hours: 'Hours',
  how_to_book: 'How to Book',
  incorrectly: 'Rejected',
  in_love: 'In Love',
  incomplete_form: 'Incomplete form. Please check your ticket information to continue.',
  incomplete_tickets: 'At least one ticket must be added to continue',
  information_to_add_addresses: 'You can add from 1 address to a maximum of',
  information_to_add_passenger: 'You can add from 1 passenger to a maximum of',
  invited_events: 'Guest',
  join_our_community: 'Join our community',
  lastname_is_required: 'Lastname is required',
  lastname_must_contain_only_letters: 'Lastname must contain only letters',
  log_in: 'Log In',
  login_error: 'The email or password you have entered is incorrect.',
  login_success: 'Successful login.',
  marriages: 'Marriages',
  massive_and_private_events: 'Massive and Private Events',
  massive_events: 'Massive Events',
  maximum: 'Maximum',
  men_stag_party: 'Men Stag Party',
  menu: 'Menu',
  miscellaneous: 'Miscellaneous',
  my_address: 'My address',
  name_and_surname: 'Name and Lastname',
  name_is_required: 'Name is required',
  name_must_contain_only_letters: ' Name must containt only letters',
  name: 'Name',
  names: 'Names',
  need_help: 'Need help',
  no_avariable: 'Not available for this category',
  no_event_created: "You haven't created a private event yet",
  no_events_available: 'There are no events available for this Category',
  no_tickets_available: 'No tickets available',
  not_have_account: "Don't have a Vmove account",
  not_purchases: 'This user has no associated purchases.',
  number_of_people: 'Number of people',
  oops: 'Oops!',
  our_service_includes: 'Our Service Includes',
  our_team: 'Our Team',
  paid: 'Paid',
  passangers_and: 'passangers and',
  passenger_list: 'Passengers List',
  passenger: 'Passenger',
  passengers: 'Passengers',
  password_is_required: 'Password is required',
  password_must_be_characters: 'Password must be 5 or more characters',
  password: 'Password',
  pay: 'Pay',
  payment_info: 'Your payment was',
  payment_problem_1: 'We will send you a verification email',
  payment_problem_2: 'with details of possible failures caused',
  payment_success_1: 'We will send you a confirmation email',
  payment_success_2: 'with the QR code and Ticket details',
  payment_state: 'Payment state',
  phone_is_invalid: 'Phone is invalid',
  phone_is_required: 'Phone is required',
  phone_must_only_contain_numbers: 'Phone must only containt numbers',
  phone_number: 'Phone Number',
  phone: 'Mobile Phone',
  place: 'Place',
  please_select_method: 'Please select your payment method.',
  price: 'Price',
  private_events: 'Private Events',
  profile: 'Profile',
  purchase_date: 'Purchase date',
  purchase_history: 'Purchase History',
  purchase_order: 'Purchase order',
  recommended_transfer: 'Recommended Transfer',
  recommended_transfers: 'Recommended Transfers',
  register: 'Register',
  remove_step: 'Remove Step',
  remove: 'Delete',
  reserve_your_transfer_ticket: 'Reserve you transfer ticket',
  reserve_your_transfer: 'Reserve your transfer now',
  reserved_tickets: 'Reserved tickets',
  return_purchase_history: 'Return to purchase history',
  routes_vmove: 'Routes Vmove',
  routes: 'Routes',
  rut_run: 'RUT / RUN / Passport',
  save_changes: 'Save Changes',
  saved_addresses: 'Saved Addresses',
  search_results: 'Search results',
  search: 'Search',
  see_available_tickets: 'See available tickets',
  see_more: 'See More',
  see_partners: 'See partners',
  see_video_tutorial: 'See video tutorial',
  select_date: 'Select Date',
  select_province: 'Select Province',
  select_stop: 'Select Stop',
  select_your_address: 'Select your address',
  select_your_category: 'Select your category',
  select_your_payment_method: 'Select your payment method',
  share_event: 'Compartir evento',
  show_event: 'Show event',
  sign_up_here: 'Sign up here',
  sign_up: 'Sign Up',
  start_session: 'Log In',
  state: 'State',
  street_and_number: 'Street and number',
  street_number_dept: 'Stret, number, department',
  success_payment_email: 'We have sent a copy of the proof of payment to your email.',
  success_payment: 'Your payment has been successful',
  successfully: 'Successfully',
  surnames: 'Surnames',
  take_your_event: 'We take you to your event',
  tell_us_about_you_marriage: 'Tell us about your marriage',
  terms: 'Terms',
  they_male: 'They',
  they_women: 'They',
  through_starting_point: 'We go through you to the starting point',
  ticket_detail: 'Ticket Detail',
  ticket: 'Ticket',
  to: 'To',
  total: 'Total',
  transfer_from: 'Transfer from',
  transfer_to_mass_events: 'Transfer to mass Events',
  transfer_to_private_events: 'Transfer to private Events',
  unable_to_upload_your_application: 'Unable to upload your application',
  under_review_events: 'Under Review',
  vmove_companies: 'Vmove Companies',
  vmove_b2b: 'Vmove B2B',
  vmove_persons: 'Vmove Persons',
  vmove: 'Vmove',
  waiting_for_you: 'We were waiting for you',
  we_are: 'We Are',
  we_transfer_users: 'Today we transfer users and staff of',
  we: 'We',
  welcome: 'Welcome',
  when_done_take_you_back: "When we're done, we'll take you back",
  who_travels: '¿Who travels?',
  who_we_are: 'Who We Are?',
  wire_transfer_description1: 'You must make a bank transfer to the account below',
  wire_transfer_description2:
    'Once the transfer is made, our executives will validate the payment in less than 24 hours. You will receive an email confirming receipt of the payment.',
  wire_transfer: 'Wire transfer',
  women_stag_party: 'Women Stag Party',
  your_events: 'Your Events',
  your_home: 'Your Home',
  you_dont_have_frequent_friends: 'You don\'t have frequent friends registered yet',
  you_dont_have_frequent_addresses: 'You don\'t have registered frequent addresses yet',
}

export const EN_US = () => {
  return language
}
