import React from 'react'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Grid } from '@material-ui/core'
import PassengersByTicket from 'components/Forms/ReservationForms/Entity/PassengersByTicket'

const DetailsPassenger = (props) => {
  const { updatePassengers, ticket, addresses, index, validateForm } = props

  return (
    <AccordionDetails>
      <Grid xs={12}>
        <PassengersByTicket
          updatePassengers={updatePassengers}
          passengers={ticket.passengers}
          capacity={ticket.capacity}
          addresses={addresses}
          currentTicket={index}
          validateForm={validateForm}
        />
      </Grid>
    </AccordionDetails>
  )
}

export default DetailsPassenger
