import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { Button, Container, Drawer, Hidden, Toolbar } from '@material-ui/core'
import Menu from '@material-ui/icons/Menu'
import styles from 'assets/jss/components/contentBarStyle.js'

const useStyles = makeStyles(styles)

export default function ContentBar(props) {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props
    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color])
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color])
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color])
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color])
    }
  }
  const { rightLinks, leftLinks, brand } = props

  const brandComponent = brand ? (
    <Link to='/' className={classes.dropdownLink}>
      <Button className={classes.customToolbar}>
        <img src={brand} alt='VMove' className={classes.brandComponent} />
      </Button>
    </Link>
  ) : (
    'Brand'
  )

  return (
    <AppBar className={classes.contentBar}>
      <Container>
        <Toolbar className={classes.customToolbar}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>{brandComponent}</div>
          <Hidden smDown implementation='css'>
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton color='inherit' aria-label='open drawer' onClick={handleDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation='js'>
          <Drawer
            variant='temporary'
            anchor={'right'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </Container>
    </AppBar>
  )
}

ContentBar.propTypes = {
  // color: PropTypes.oneOf([
  //   "primary",
  //   "info",
  //   "success",
  //   "warning",
  //   "danger",
  //   "transparent",
  //   "white",
  //   "rose",
  //   "dark",
  //   "orange"
  // ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  // changeColorOnScroll: PropTypes.shape({
  //   height: PropTypes.number.isRequired,
  //   color: PropTypes.oneOf([
  //     "primary",
  //     "info",
  //     "success",
  //     "warning",
  //     "danger",
  //     "transparent",
  //     "white",
  //     "rose",
  //     "dark"
  //   ]).isRequired
  // })
}
