import { orangeColor, whiteColor } from "../style";
import { latoBold, latoRegular } from "assets/jss/tools.js";

const cardPrivateEventList = {
  root: {
    display: "flex",
  },
  divContainer: {
    paddingTop: "20px",
    width: "70%",
  },
  divStatusSucceded: {
    height: "25px",
    width: "100%",
    color: "white",
    fontFamily: latoBold,
    backgroundColor: "#248A0C",
  },
  divStatusReview: {
    height: "25px",
    width: "100%",
    color: "white",
    fontFamily: latoBold,
    backgroundColor: "#CED50D",
  },
  divStatusFinished: {
    height: "25px",
    width: "100%",
    color: "white",
    fontFamily: latoBold,
    backgroundColor: "#CDCDCD",
  },
  divStatusInvited: {
    height: "25px",
    width: "100%",
    color: "white",
    fontFamily: latoBold,
    backgroundColor: "#5C9EAD",
  },
  divOptions: {
    paddingTop: "5px",
    textAlign: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: "10%",
    color: '#7E7E7E',
  },
  dateContent: {
    textAlign: 'left',
    fontSize: '18px',
  },
  titleContent: {
    textAlign: 'left',
    fontFamily: latoBold,
    fontSize: '20px',
    paddingTop: '5%',
  },
  titleContentInvited: {
    color: '#7E7E7E',
    textAlign: 'left',
    fontFamily: latoBold,
    fontSize: '20px',
    paddingTop: '5%',
    "&:hover": {
      color: orangeColor,
      cursor: "pointer",
      opacity: "0.8",
      textDecoration: 'underline',
    },
  },
  addressContent: {
    textAlign: 'left',
    fontFamily: latoRegular,
    fontSize: '15px',
    paddingTop: '5%',
  },
  cover: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
  },
  icon: {
    textAlign: 'center',
    "&:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  boxCircularProgress: {
    display: "flex",
    justifyContent: "center",
    height: "600px",
  },
  msgError: {
    height: '500px',
    display: 'flex',
    alignItems: 'center',
    color: '#7E7E7E',
    fontFamily: latoBold,
  },
  repeatEventButton: {
    color: whiteColor,
    borderColor: whiteColor,
    fontFamily: latoBold,
    borderWidth: "1px",
    borderStyle: "solid",
    width: "100%",
    heigh: "100px",
    backgroundColor: orangeColor,
    borderRadius: 20,
    transition: "0.3s",
    textAlign: "center",
    opacity: "0.8",
    "&:hover": {
      color: orangeColor,
      backgroundColor: whiteColor,
      cursor: "pointer",
      opacity: "0.8",
    },
  },
};

export default cardPrivateEventList;
