import axios from 'axios'

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 15000,
})

export const response = (options) => {
  return new Promise(async (resolve, reject) => {
    try {
      options.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      const { data } = await client(options).catch((e) => reject())
      data.success ? resolve(data.data) : reject()
    } catch {
      reject()
    }
  })
}

export const responseImg = (options) => {
  return new Promise(async (resolve, reject) => {
    try {
      options.headers = {
        'Content-Type': 'image/*',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      const { data } = await client(options).catch((e) => reject())
      data.success ? resolve(data.data) : reject()
    } catch {
      reject()
    }
  })
}

export const responseXlsx = (options) => {
  return new Promise(async (resolve, reject) => {
    try {
      options.headers = {
        'Content-Type': 'application/vnd.ms-excel',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      const { data } = await client(options).catch((e) => reject())
      data.success ? resolve(data.data) : reject()
    } catch {
      reject()
    }
  })
}

export default client
