import React, { useEffect, useState } from "react";
import styles from "assets/jss/views/successScanner";
import {
  Box,
  Container,
  Grid,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles(styles);

const SuccessScanner = (props) => {
  const classes = useStyles();
  const { data, handleReturn } = props;
  const [dataResp, setDataResp] = useState();
  const [verifyData, setVerifyData] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    handleVeriication();
  }, []);

  const handleVeriication = () => {
    if (data === undefined) {
      setVerifyData(false);
      setDataResp({
        customer: {
          email: "Sin Mail",
          name: "Sin Nombre",
        },
        date: "01-01-2021 00:00",
        message: "",
        event: {
          name: "Sin Nombre",
          placeName: "Sin Nombre",
        },
        ticketQuantity: 0,
      });
    }
    setLoader(false);
  };

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: {
        main: orange["700"],
        dark: orange["800"],
        contrastText: "#FFFFFF",
      },
    },
  });

  if (loader) {
    return (
      <Container>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Grid
            item
            xs={12}
            md={12}
            classes={classes.boxCircularProgress}
            style={{
              height: "500px",
              alignItems: "center",
              paddingTop: "40%",
              paddingLeft: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </ThemeProvider>
      </Container>
    );
  }

  return (
    <Box>
      <Grid justify="center" alignItems="center" container>
        <Grid className={classes.container}>
          <div className={classes.head}>
            {/* {verifyData ? data.event.message : dataResp.event.message} */}

            {verifyData ? <div>
              {data.Performed === true ? <div className={classes.yarealizado}>{data.message}</div> : <div className={classes.validado}>{data.message}</div>}
            </div> : dataResp.message}
          </div>
          <div className={classes.body}>
            <Grid xs={12} md={12}>
              <Grid container xs={12} md={12} spacing={2}>
                <Grid item xs={6} md={6} className={classes.textTitle}>
                  Nombre del Evento :
                </Grid>
                <Grid item xs={6} md={6} className={classes.textData}>
                  {verifyData ? data.event.name : dataResp.event.name}
                </Grid>
              </Grid>
              <hr />
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={6} className={classes.textTitle}>
                  Lugar :
                </Grid>
                <Grid item xs={6} md={6} className={classes.textData}>
                  {verifyData ? data.event.placeName : dataResp.event.placeName}
                </Grid>
              </Grid>
              <hr />
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={6} className={classes.textTitle}>
                  Fecha del Evento :
                </Grid>
                <Grid item xs={6} md={6} className={classes.textData}>
                  {verifyData ? data.date : dataResp.date}
                </Grid>
              </Grid>
              <hr />
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={6} className={classes.textTitle}>
                  Nombre del Pasajero :
                </Grid>
                <Grid item xs={6} md={6} className={classes.textData}>
                  {verifyData ? data.customer.name : dataResp.customer.name}
                </Grid>
              </Grid>
              <hr />
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={6} className={classes.textTitle}>
                  Tickets :
                </Grid>
                <Grid item xs={6} md={6} className={classes.textData}>
                  {verifyData ? data.ticketQuantity : dataResp.ticketQuantity}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div onClick={() => handleReturn()} className={classes.return}>
            Escanear otro código
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessScanner;
