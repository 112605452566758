import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/views/accountPage.js";
import Context from "../../../context/Context";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  InputBase,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { putUserInfo, getUserInfo } from "provider/privateProvider/provider";

const useStyles = makeStyles(styles);

export default function PerfilTab(props) {
  const {
    email,
    firstName,
    gender,
    rut,
    genderOptions,
    lastName,
    phoneNumber,
  } = props.personData;
  const newGender = Object.entries(genderOptions);
  const [userName, setUserName] = useState("name");
  const [userLastName, setUserLastName] = useState("lastname");
  const [userRut, setUserRut] = useState("");
  const [userGender, setUserGender] = useState(gender);
  const [userPhone, setUserPhone] = useState("phone");
  const [userEmail, setUserEmail] = useState("mail");
  const contextType = useContext(Context);
  const { t } = contextType;
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const customJustify = isMobile ? "center" : "flex-end";
  const classes = useStyles();
  const [genderValue, setGenderValue] = useState("");
  const enabled =
    userName && userLastName && genderValue && userPhone && userEmail
      ? true
      : false;

  const getGenderValue = () => {
    newGender.filter((item) => {
      if (item[1] === gender) {
        setGenderValue(item[0])
      }
    });
  };

  const handleUpdate = () => (event) => {
    event.preventDefault();
    let { setUserData } = contextType;
    let data = {
      email: userEmail,
      firstName: userName,
      lastName: userLastName,
      phoneNumber: userPhone,
      rut: userRut,
      gender: genderValue,
    };
    putUserInfo(data).then((res) => {
      setUserData(res);
      if (res) {
        getUserInfo().then((res) => {
          setUserData(res);
        });
      }
    });
  };

  useEffect(() => {
    const setDefaultData = () => {
      setUserName(firstName);
      setUserLastName(lastName);
      setUserPhone(phoneNumber);
      //setUserGender(gender);
      getGenderValue();
      setUserEmail(email);
      setUserRut(rut);
    };
    setDefaultData();
  }, [props.personData]);

  const genderFormatter = (value) => {
    switch (value) {
      case value === "u":
        return "Indefinido";
      case value === "m":
        return "Masculino";
      case value === "f":
        return "Femenino";
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Typography
        classes={{
          root: isMobile
            ? classes.labelTitlePabelMobile
            : classes.labelTitlePabel,
        }}
        className={isMobile ? classes.fs18 : classes.fs20}
      >
        {t("profile")}
      </Typography>
      <Box className={classes.boxContainerPerfil}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("names")}
                </FormLabel>
                <InputBase
                  type="text"
                  onChange={(event) => setUserName(event.target.value)}
                  classes={{ root: classes.customTextField }}
                  placeholder={t("names")}
                  value={userName}
                />
              </FormControl>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("rut_run")}
                </FormLabel>
                <InputBase
                  type="text"
                  onChange={(event) => setUserRut(event.target.value)}
                  value={userRut}
                  placeholder={t("11.111.111-1")}
                  classes={{ root: classes.customTextField }}
                />
              </FormControl>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("phone_number")}
                </FormLabel>
                <InputBase
                  type="text"
                  pattern="[0-9]*"
                  onChange={(event) => setUserPhone(event.target.value)}
                  value={userPhone}
                  placeholder={t("+569")}
                  classes={{ root: classes.customTextField }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("surnames")}
                </FormLabel>
                <InputBase
                  type="text"
                  onChange={(event) => setUserLastName(event.target.value)}
                  value={userLastName}
                  placeholder={t("surnames")}
                  classes={{ root: classes.customTextField }}
                />
              </FormControl>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("gender")}
                </FormLabel>
                <TextField
                  select
                  id="outlined-select-currency"
                  classes={{ root: classes.customTextFieldSelect }}
                  value={genderValue}
                  // onChange={handleGenderChange}
                  onChange={(event) => {
                    setUserGender(event.target.value);
                    setGenderValue(event.target.value);
                  }}
                  variant="outlined"
                >
                  {newGender.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item[0]}>
                        {item[1]}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value={genderOptions.u}>
                    {'Indefinido'}
                  </MenuItem>
                  <MenuItem value={genderOptions.f}>
                    {'Femenino'}
                  </MenuItem>
                  <MenuItem value={genderOptions.m}>
                    {'Masculino'}
                  </MenuItem> */}
                </TextField>
              </FormControl>
              <FormControl
                fullWidth
                classes={{
                  root: classes.customFormControlAccount,
                }}
              >
                <FormLabel
                  classes={{
                    root: classes.customLabel,
                    focused: classes.customFocused,
                  }}
                >
                  {t("email")}
                </FormLabel>
                <InputBase
                  type="email"
                  onChange={(event) => setUserEmail(event.target.value)}
                  value={userEmail}
                  placeholder={t("email")}
                  classes={{ root: classes.customTextField }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box
            alignItems="center"
            classes={{ root: classes.boxSave }}
            display="flex"
            justifyContent={customJustify}
          >
            <Button
              classes={{ root: classes.buttonSave }}
              onClick={handleUpdate()}
              type="submit"
              variant="contained"
              disabled={!enabled}
            >
              {t("save_changes")}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}

PerfilTab.propTypes = {
  personData: PropTypes.object,
};
